import React, { Component } from 'react';
import InputAce from '../Input/InputAce';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import ModelCtrl from '../../models/ModelCtrl';
import { Toast } from 'primereact/toast';
import moment from 'moment';
class SettingView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            BUSD: '',
            BUSDFlow: 0,
            BUSDace: '',
            struct: {
                'busd_mapping': {
                    id: 'busd_mapping',
                    name: 'BUSD Mapping',
                    desc: 'Ánh xạ chỉ số 1,2,3,4... với các Strategy ID',
                    type: 'ace',
                    value: '',
                    onSave: (input) => { this.OnSave('busd_mapping', input.getValue()) }

                },

                'busd_score': {
                    id: 'busd_score',
                    name: 'BUSD Score',
                    desc: 'Công thức tính winrate',
                    type: 'ace',
                    value: '',
                    onSave: (input) => { this.OnSave('busd_score', input.getValue()) }

                },
                'testnet_backtest_mapping': {
                    id: 'testnet_backtest_mapping',
                    name: 'Testnet Backtest mapping',
                    desc: 'Ánh xạ Account Testnet ID với Name Strategy BeackTest',
                    type: 'ace',
                    value: '',
                    onSave: (input) => { this.OnSave('testnet_backtest_mapping', input.getValue()) }

                },
                'filter_busd_longterm': {
                    id: 'filter_busd_longterm',
                    name: 'Filter BUSD LongTerm ',
                    desc: 'Danh sách các mã không được tính vào trong BUSD LongTerm',
                    type: 'ace',
                    value: '',
                    onSave: (input) => { this.OnSave('filter_busd_longterm', input.getValue()) }

                },
                'filter_busd_longterm_history': {
                    id: 'filter_busd_longterm_history',
                    name: 'Filter BUSD LongTerm History',
                    desc: 'Danh sách các mã không được tính vào trong BUSD LongTerm History',
                    type: 'ace',
                    value: '',
                    onSave: (input) => { this.OnSave('filter_busd_longterm_history', input.getValue()) }

                }
            }
        }

        this.model = new ModelCtrl()
    }

    OnSave(key, value) {

        let data = {
            key, value
        }
        if (key == 'filter_busd_longterm_history') {
            let dataTime = {
                'key': "filter_busd_longterm_history_time",
                'value': moment().format('X')
            }
            this.model.setCtrl(data).then(res => {

                this.model.setCtrl(dataTime).then(res => {
                    this.toast.show({ severity: 'success', summary: 'Success', detail: 'Update success', life: 3000 });

                }).catch(er => {
                    this.toast.show({ severity: 'error', summary: 'Error ', detail: 'Error', life: 3000 });
                })
            }).catch(er => {
                this.toast.show({ severity: 'error', summary: 'Error ', detail: 'Error', life: 3000 });
            })

        } else {
            this.model.setCtrl(data).then(res => {
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Update success', life: 3000 });
            }).catch(er => {
                this.toast.show({ severity: 'error', summary: 'Error ', detail: 'Error', life: 3000 });
            })
        }


    }

    componentDidMount() {
  
        if (global.User2.group.includes("research_admin")) {
            this.model.getCtrl().then(res => {
                let struct = this.state.struct
                res.map(item => {
                    // debugger

                    if (struct[item['key']]) {
                        struct[item['key']]['value'] = item['value']
                    }
                    return true
                })
                this.setState({
                    struct
                });
            })
        } else {
            this.toast.show({ severity: 'error', summary: 'Error ', detail: 'Unauthorized', life: 3000 });

            this.setState({
                struct: {}
            });
        }

    }

    renderInput(config, ref) {
        if (config['type'] == 'text') {
            return (
                <InputText ref={c => ref[0] = c} style={{ width: '100%' }} value={this.state[config['id']]} onChange={(e) => this.setState({ [config['id']]: e.target.value })} />
            )
        }
        if (config['type'] == 'number') {
            return (
                <InputNumber ref={c => ref[0] = c} style={{ width: '100%' }} value={this.state[config['id']]} onChange={(e) => this.setState({ [config['id']]: e.value })} />
            )
        }
        if (config['type'] == 'ace') {
            return (
                <InputAce ref={c => ref[0] = c} key={config['value']} value={config['value']}></InputAce>
            )
        }
    }

    render() {
        return (
            <div>
                <Toast ref={(el) => this.toast = el} style={{ zIndex: 1000 }} />

                {
                    Object.values(this.state.struct).map(config => {
                        let input = [null]
                        return (
                            <div key={config['id']} className='card grid'>
                                <div className="col-4">
                                    <div style={{ fontSize: '20px' }}>
                                        <b>{config['name']}</b>
                                    </div>
                                    <div style={{ color: ' cornflowerblue', fontSize: '16px', fontStyle: 'italic' }}>{config['desc']}</div>
                                </div>
                                <div className="col-8">

                                    {
                                        this.renderInput(config, input)
                                    }
                                </div>
                                <div className='col-12' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {
                                        config['onSave'] && <Button label="Save" onClick={() => config['onSave'](input[0])} />
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

export default SettingView;