import React, { Component } from 'react';
import LongShortChart from './ChartMain/LongShortChart';

class IntraDayView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time: '9:00'
        }
    }
    componentDidMount() {
        this.LongShortChart.setNum(75)
    }

    render() {
        return (
            <div className='card' style={{ position: 'relative', marginBottom: 0 }}>
                <div >
                    <div className='text-center'>
                        <b>INTRADAY</b>
                    </div>
                    <div style={{ position: 'absolute', top: '15px', right: '15px' }}>
                        <div >
                            <i className="pi pi-clock"></i>
                            <b className='ml-2' >{this.state.time}</b>
                        </div>
                    </div>

                    <div>
                        <LongShortChart ref={c => this.LongShortChart = c} isExtra={true}></LongShortChart>

                    </div>
                </div>

            </div>
        );
    }
}

export default IntraDayView;