import React, { Component } from "react";
import { Calendar } from 'primereact/calendar';
import moment from "moment";
import BidAsKView from "../components/Dashboard_v2/BidAsKView";
import BusdView from "../components/Dashboard_v2/BusdView";
import ChartTotalScoreView from "../components/Dashboard_v2/ChartTotalScoreView";
import FaScoreView from "../components/Dashboard_v2/FaScoreView";
import ForeginView from "../components/Dashboard_v2/ForeginView";
import TotalScoreView from "../components/Dashboard_v2/TotalScoreView";
import { Dropdown } from 'primereact/dropdown';
import ModelWeight from "../models/ModelWeight";
import { makeId , showLog } from "../helpers/default";
import { Button } from 'primereact/button';
import EditWeight from "../components/Dashboard_v2/EditWeight";
import ModelDashboardV2 from "../models/ModelDashboardV2";
import ForeginCSView from "../components/Dashboard_v2/ForeginCSView";
import Score_v2View from "../components/Dashboard_v2/Score_v2/Score_v2View";
import { InputText } from 'primereact/inputtext';

class Dashboard_v2View extends Component {
    constructor(props) {
        super(props);
        this.state = {
            day: '',
            dayTrans: '',

            needUpdate: 0,

            selWeight: {},
            optWeight: [],
            weight: {},
            note: {},

            rolling_second: localStorage.getItem('rolling_second') ? Number(localStorage.getItem('rolling_second')) : 5,
            ema_config: localStorage.getItem('ema_config') ? Number(localStorage.getItem('ema_config')) : 500,
            sma_config: localStorage.getItem('sma_config') ? Number(localStorage.getItem('sma_config')) : 1000,
            selSmooth: { 'name': 'Nguyên Bản', 'code': 'Nguyên Bản' },
            optSmooth: [
                { 'name': 'Nguyên Bản', 'code': 'Nguyên Bản' },
                { 'name': 'Làm tròn', 'code': 'Làm tròn' }
            ],
            indicator_correction : {}

        }

        this.modelWeight = new ModelWeight()
        this.modelDashboard = new ModelDashboardV2()
    }
    componentDidMount() {
        let currentDate = moment()
        if (currentDate.day() === 0 || currentDate.day() === 6) {
            currentDate.add(-2, 'day')

        }
        this.setState({
            day: currentDate._d,
            dayTrans: moment(currentDate._d).format('YYYY_MM_DD'),
        }, () => this.getWeight());

        this.getNote()



    }

    getWeight() {
        let params = {
            'day': this.state.dayTrans,
            'account': global.User
        }
        
        this.modelWeight.read(params).then(res => {
            if (res['result']) {

                let optWeight = []
                let weight = {}
                let selWeight = { name: "Default", code: "Default" }
                res['data'].map(item => {
                    if (item['account'] == global.User) {
                        selWeight = { name: item['account'], code: item['account'] }
                    }
                    optWeight.push(
                        { name: item['account'], code: item['account'] }
                    )
                    weight[item['account']] = item
                })
                this.setState({
                    optWeight,
                    weight,
                    selWeight,
                    needUpdate: this.state.needUpdate + 1,
                    indicator_correction : {
                        'bid/ask' : this.BidAsKView.state.selCorrection['code'],
                        'foregin_cs' : this.ForeginCSView.state.selCorrection['code'],
                        'foregin' : this.ForeginView.state.selCorrection['code'],
                        'busd' : this.BusdView.state.selCorrection['code'],
                    }
                });

            }
        })
    }

    getNote() {
        this.modelDashboard.get_data_note().then(res => {
            let note = {}
            res.map(item => {
                note[item['name']] = item['info']
            })
            this.setState({
                note
            });
        })
    }

    onApply() {
        // let weight = this.state.weight[this.state.selWeight['code']]['weight']
        // // this.ChartTotalScoreView.applyWeight(weight)
        const num = parseFloat(this.state.rolling_second);
        if (isNaN(num)) {
            showLog('Input Rolling must be number', 'error')
            return 
        }

        let indicator_correction = this.state.indicator_correction
        if(this.BidAsKView != undefined ){
            indicator_correction['bid/ask'] = this.BidAsKView.state.selCorrection['code']
        }
        if(this.ForeginCSView != undefined ){
            indicator_correction['foregin_cs'] = this.ForeginCSView.state.selCorrection['code']
        }
        if(this.ForeginView != undefined ){
            indicator_correction['foregin'] = this.ForeginView.state.selCorrection['code']
        }
        if(this.BusdView != undefined ){
            indicator_correction['busd'] = this.BusdView.state.selCorrection['code']
        }

     
        this.setState({
            needUpdate: this.state.needUpdate + 1,
            indicator_correction
        });
    }

    changeInputText(name , value){
        this.setState({
            [name] : Number(value)
        });

        localStorage.setItem(name , value)

        this.delayedSearch(() => {
            this.onApply();
          }, 1000);
    }

    delayedSearch = (function () {
        let timer = null;
        return function (callback, delay) {
          clearTimeout(timer);
          timer = setTimeout(callback, delay);
        };
      })(); 


    render() {
        return (
            <div>
                <div
                    style={{
                        textAlign: "center",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        marginBottom: 20,
                    }}
                >
                    <div style={{ marginBottom: 15 }}>
                        Total Scoring
                    </div>


                    <Calendar disabledDays={[0, 6]} className="calender_cus" dateFormat="dd-mm-yy" value={this.state.day} onChange={(e) => {
                        this.setState({
                            day: e.value,
                            dayTrans: moment(e.value).format('YYYY_MM_DD'),
                        });
                        this.onApply()
                    }} />


                    <span style={{marginLeft : 10}}>Rolling :
                        <InputText
                            style={{ width: '5%' ,marginLeft : 10 }}
                            value={this.state.rolling_second}
                            onChange={(e) => this.changeInputText('rolling_second', e.target.value) } /> </span>
                    <span style={{marginLeft : 10}}>EMA :
                        <InputText
                            style={{ width: '5%' ,marginLeft : 10 }}
                            value={this.state.ema_config}
                            onChange={(e) => this.changeInputText('ema_config',e.target.value) } /> </span>
                    <span style={{marginLeft : 10}}>SMA :
                        <InputText
                            style={{ width: '5%' ,marginLeft : 10 }}
                            value={this.state.sma_config}
                            onChange={(e) => this.changeInputText('sma_config',e.target.value) } /> </span>
                    <span>
                        <Dropdown
                            value={this.state.selSmooth}
                            options={this.state.optSmooth}
                            onChange={(e) => { this.setState({ selSmooth: e.value }); this.onApply() }}
                            optionLabel="name"
                            style={{ margin: "0px 10px" }}

                        />

                    </span>


                    <div style={{ marginTop: 15 }}>
                        <span >Account :</span>
                        <Dropdown
                            value={this.state.selWeight}
                            options={this.state.optWeight}
                            onChange={(e) => { this.setState({ selWeight: e.value }); this.onApply() }}
                            optionLabel="name"
                            style={{ margin: "0px 10px" }}

                        />

                        <span style={{ cursor: 'pointer' }} onClick={() => this.EditWeight.openDialogEdit(this.state.weight[this.state.selWeight['code']])}>
                            {
                                Object.keys(this.state.weight).length > 0 && Object.keys(JSON.parse(this.state.weight[this.state.selWeight['code']]['weight'])).map(k => {
                                    return (
                                        <span key={makeId()}>{k} : {JSON.parse(this.state.weight[this.state.selWeight['code']]['weight'])[k]}  </span>
                                    )
                                })
                            }
                        </span>
                    </div>

                </div>



                <div key={this.state.needUpdate}>
                    <div className="grid">
                        <div className="col-12 md:col-4">
                            <TotalScoreView ref={c => this.TotalScoreView = c}></TotalScoreView>
                        </div>
                        <div className="col-12 md:col-8">
                            <ChartTotalScoreView
                                TotalScoreView={() => { return this.TotalScoreView }}
                                day={this.state.dayTrans}
                                weight={this.state.weight[this.state.selWeight['code']]}
                                rolling_second={this.state.rolling_second}
                                ema={this.state.ema_config}
                                sma={this.state.sma_config}
                                smooth={this.state.selSmooth['code']}
                                indicator_correction={this.state.indicator_correction}
                                ref={c => this.ChartTotalScoreView = c}
                            ></ChartTotalScoreView>
                        </div>
                    </div>

                    <div>
                        <Score_v2View   ema={this.state.ema_config}   sma={this.state.sma_config}  ref={c => this.Score_v2View = c} day={this.state.dayTrans} note={this.state.note} ></Score_v2View>
                    </div>


                    <div>
                        <BidAsKView ChartTotalScoreView={() => { return this.ChartTotalScoreView }} ref={c => this.BidAsKView = c} day={this.state.dayTrans} note={this.state.note} ></BidAsKView>
                    </div>
                    <div>
                        <ForeginCSView ChartTotalScoreView={() => { return this.ChartTotalScoreView }} ref={c => this.ForeginCSView = c} day={this.state.dayTrans} note={this.state.note}></ForeginCSView>
                    </div>
                    <div>
                        <ForeginView ChartTotalScoreView={() => { return this.ChartTotalScoreView }} ref={c => this.ForeginView = c} day={this.state.dayTrans} note={this.state.note}></ForeginView>
                    </div>
                    <div>
                        <BusdView ChartTotalScoreView={() => { return this.ChartTotalScoreView }} ref={c => this.BusdView = c} day={this.state.dayTrans} note={this.state.note}></BusdView>
                    </div>

                    <div>
                        <FaScoreView day={this.state.dayTrans}></FaScoreView>
                    </div>
                </div>

                <EditWeight day={this.state.dayTrans} weight={this.state.weight} getWeight={() => this.getWeight()} onApply={(weight) => this.onApply(weight)} ref={c => this.EditWeight = c}></EditWeight>

            </div>
        );
    }
}

export default Dashboard_v2View;
