import axios from "axios";
import { checkLogin } from "../helpers/default";
class Model3T {

    constructor() {
        this.url = 'https://apigw.f5traders.com/cmc'

        this.data3T = null
    }

    getData() {
        if(this.data3T){
            return Promise.resolve(this.data3T)
        }else{
            return axios.request({
                url: `${this.url}/t3/read/`,
                method: "GET",
                withCredentials: true,
            })
    
                .then(response => {
                    response = response['data'];
                    this.data3T = response
                    return response
    
                })
    
                .catch((error) => {
                    checkLogin(error)

                    return false;
                })
        }
      

       
     
    }






}

export default Model3T;