import React, { Component } from 'react';
// import ModelDashboard from '../../models/ModelDashboard';

import LongShortChart from './LongShortChart';
import moment from 'moment';
import { checkWorkingTime } from '../../helpers/default';
class TotalScoreView extends Component {

    constructor(props) {
        super(props);

        // this.model = new ModelDashboard()

        this.state = {
            time: '',
            colorTime: ''
        }
    }

    // getData(loading){
    //     this.model.get_data_bid_ask(loading).then(res => {
    //         let data = JSON.parse(res)
    //         if (res == null) {
    //             this.LongShortChart.setNum(0, 200)
    //             let messShort = `Short không có tín hiệu `
    //             this.chartShort.setContent(0, 'short', messShort)
    //             let messLong = `Long không có tín hiệu `
    //             this.chartLong.setContent(0, 'long', messLong)
    //         } else {
       
    //             this.LongShortChart.setNum(Number(data['score'].toFixed(1)), 200)

               


    //             if (data['side'] == 'long') {
    //                 let messLong = `Tín hiệu ${data['side'].toUpperCase()}, Winrate : ${data['win_rate'].toFixed(2)} % `
    //                 this.chartLong.setContent(Number(data['win_rate'].toFixed(2)), 'long', messLong)
    //                 let messShort = `Short không có tín hiệu `
    //                 this.chartShort.setContent(0, 'short', messShort)

    //             } else if (data['side'] == 'short') {
    //                 let messShort = `Tín hiệu ${data['side'].toUpperCase()}, Winrate : ${data['win_rate'].toFixed(2)} % `
    //                 this.chartShort.setContent(Number(data['win_rate'].toFixed(2)), 'short', messShort)

    //                 let messLong = `Long không có tín hiệu `
    //                 this.chartLong.setContent(0, 'long', messLong)
    //             }
    //             else {
    //                 let messShort = `Short không có tín hiệu `
    //                 this.chartShort.setContent(0, 'short', messShort)
    //                 let messLong = `Long không có tín hiệu `
    //                 this.chartLong.setContent(0, 'long', messLong)
    //             }

    //             let time = `${data['day']} ${data['ctime']}`

    //             let color
    //             if (Number(moment().format('X')) - Number(moment(time.replaceAll('_', '/')).format('X')) <= 120) {
    //                 color = 'limegreen'
    //             } else {
    //                 color = 'red'
    //             }
    //             this.setState({
    //                 time,
    //                 colorTime : color
    //             });
    //         }

    //     })
    // }
    componentDidMount() {

    }

    setData(data){
        data =  Math.round(data*100)/100
        this.LongShortChart.setNum(data)  
    }


    render() {
        return (
            <div className='card' style={{ marginBottom: '0px', height: '100%' }} >

                <div>
                    <LongShortChart isExtra2={true} ref={c => this.LongShortChart = c}></LongShortChart>

                </div>


            </div>
        );
    }
}

export default TotalScoreView;