

import React, { Component } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import './index.scss'
class BUSDChartLine extends Component {
    constructor(props) {
        super(props);
        this.state = {

            type: 'long',

            left: 0,
            text: '',

        }
    }

    setContent(value) {
        let p = (value - (-5)) / (5 - (-5))
        let left = p * 98
        let type = value == 0 ? 'neutral' : value > 0 ? 'long' : 'short'
        this.setState({
            text: value,
            type: type,
            left,

        });
    }

    componentDidMount() {

        if (Object.keys(this.props.config).length > 0) {
            this.setContent(this.props.config.score)
        }
    }

    renderClass(text, value) {
        value = Number(value)
        if (text) {


            if (this.state.type == 'long') {
                if (!Number.isInteger(value)) {
                    return "text-color-green percenter"
                }
                return "text-color-green"
            } else if (this.state.type == 'short') {
                if (!Number.isInteger(value)) {
                    return "text-color-red percenter"
                }
                return "text-color-red"
            }

            return "text-color-yellow"

        } else {

            if (this.state.type == 'long') {
                return "arrow-down-green"
            } else if (this.state.type == 'short') {
                return "arrow-down-red"
            }

            return "arrow-down-yellow"

        }
    }




    render() {
        return (
            <div className='grid' style={{ paddingTop: '10px', height: '100%', display: 'flex', alignItems: 'center' }}>

                <div className='col-12'>
                    <div className={this.renderClass(false)} style={{ position: 'relative', left  : `${this.state.left}%` , bottom : '-10px' }}>

                        <div className={this.renderClass(true, this.state.text)}>{this.state.text}</div>
                    </div>
                 
                    <div ref={c => this.chart = c} style={{ display: 'flex' }}>

                        <svg id="Group_64" data-name="Group 64" xmlns="http://www.w3.org/2000/svg" width="20" height="35" viewBox="0 0 100.932 102.154" style={{ zIndex: 2 }}>
                            <g id="Group_44" data-name="Group 44">
                                <path id="Path_63" data-name="Path 63" d="M123.509,1099.245s38.02,5.071,41.634,22.037c2.4,11.287,20.167,11.222,30.25,16.833,0,0-1.952,20.979-5.123,23.175,0,0-30.18,12.861-39.836,12.619a73.765,73.765,0,0,1-15.862-2.14l-8.461,10.988H102.448l-9.514-17.564v-63.671s21.228-21.793,27.079-22.442c5.361-.6,5.891,13.4,3.42,25.766" transform="translate(-90.703 -1076.834)" fill="red" />
                                <path id="Path_64" data-name="Path 64" d="M119.686,1177.48h-26.1l-10.412-19.8V1093.1l.634-.651c5.11-5.244,22.261-22.418,28.429-23.1a5.073,5.073,0,0,1,4.29,1.668c3.2,3.494,3.407,11.755,2.709,18.977,9.314,1.626,37.254,7.767,40.563,23.312,1.326,6.221,8.9,8.448,16.906,10.8,4.261,1.256,8.663,2.552,12.244,4.54l1.268.709-.134,1.448c-.762,8.227-2.564,22.384-6.07,24.813-1.634.744-30.551,13.011-40.859,13.017-.1,0-.2-.006-.308-.006a75.932,75.932,0,0,1-14.912-1.865Zm-23.435-4.465h21.238l8.68-11.274,1.413.35a71.332,71.332,0,0,0,15.377,2.075c.081,0,.157.007.238.007,7.761,0,30.487-8.953,38.667-12.442.959-1.169,2.721-10.976,3.628-19.837a69.9,69.9,0,0,0-10.046-3.5c-8.913-2.622-18.133-5.332-20.017-14.156-2.459-11.551-25.11-17.8-36.743-19.82-.186,1.21-.389,2.343-.587,3.354l-4.384-.871c2.267-11.331,1.518-20.69-.477-22.866-.256-.273-.4-.273-.506-.245-3.058.337-15.255,11.1-25.092,21.133v62.2Z" transform="translate(-83.176 -1069.324)" fill="#fff" />
                            </g>
                            <rect id="Rectangle_42" data-name="Rectangle 42" width="4.461" height="13.847" transform="matrix(0.373, -0.928, 0.928, 0.373, 48.085, 44.838)" fill="#fff" />
                        </svg>



                        <div style={{ flex: 1, alignSelf: 'center', marginLeft: '-15px', marginRight: '-15px', marginTop: '5px', height: '10px', background: 'linear-gradient(90deg, rgba(204,46,45,1) 0%, rgba(200,49,46,1) 30%, rgba(62,156,68,1) 71%, rgba(56,161,69,1) 100%)' }}>

                        </div>


                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="35" viewBox="0 0 100.932 102.154" style={{ zIndex: 2 }}>

                            <g id="Group_67" data-name="Group 67" transform="translate(-709.792 -1576.331)">
                                <g id="Group_66" data-name="Group 66" transform="translate(709.792 1576.331)">
                                    <g id="Group_45" data-name="Group 45" transform="translate(0 0)">
                                        <path id="Path_65" data-name="Path 65" d="M3335.395,1135.872c2.01.641,3.7,1.082,4.855,1.362q.085,36.509.164,73.022-5.288-3.822-10.572-7.644c-17.766,6.545-64,6.75-64,6.75-11.871-5.2-18.785-22.851-18.785-22.851l26.512-27.16c-1.059-18.114,17.3-18.908,15.225-25.066-2.379-5.1-40.8-7.624-43.848-15.857,27.748.427,28.248-4.484,44.111-3.614,3.662.2,15.49,1.277,26.318,12.631" transform="translate(-3241.726 -1112.479)" fill="#00a364" />
                                        <path id="Path_66" data-name="Path 66" d="M3331.782,1207.1l-13.174-9.528c-18.621,6.214-61.764,6.481-63.625,6.487l-.906-.186c-12.576-5.511-19.674-23.323-19.973-24.08l-.521-1.343,26.848-27.505c-.273-11.6,6.92-16.61,11.727-19.946,2.365-1.652,3.9-2.786,3.65-3.53-1.053-1.127-9.8-3.046-16.2-4.447-15.395-3.366-25.635-5.976-27.623-11.354l-1.133-3.058,3.262.053c15.086.2,21.7-1.076,27.627-2.239a58.275,58.275,0,0,1,16.568-1.366,41.679,41.679,0,0,1,27.814,13.314l-3.232,3.081a37.284,37.284,0,0,0-24.826-11.941,54.184,54.184,0,0,0-15.463,1.3,111.019,111.019,0,0,1-22.262,2.326c5.232,2.25,14.158,4.2,20.221,5.529,11.748,2.57,17.947,4.058,19.389,7.15,1.436,4.209-1.957,6.569-5.242,8.849-4.611,3.21-10.352,7.2-9.781,17.029l.059.982-26.156,26.8c1.791,3.983,7.775,15.954,16.621,20.116,4.965-.046,46.514-.621,62.75-6.6l1.113-.412,7.984,5.773-.146-66.911c-.953-.256-2.068-.576-3.3-.965l1.35-4.256c1.953.623,3.594,1.047,4.7,1.32l1.7.412Z" transform="translate(-3230.851 -1104.95)" fill="#fff" />
                                    </g>
                                    <g id="Group_46" data-name="Group 46" transform="translate(66.126 0.839)">
                                        <path id="Path_67" data-name="Path 67" d="M3529.781,1145.57c0-17.564,18.848-27.745,23.205-27.2,5.5.689,6.07,24.6-6.375,32" transform="translate(-3527.548 -1116.125)" fill="#00a364" />
                                        <path id="Path_68" data-name="Path 68" d="M3540.217,1144.779l-2.279-3.836c6.559-3.9,9.256-13.646,8.99-21.052-.17-4.6-1.389-6.61-1.816-6.826-.947-.157-6.092,1.582-11.322,6.2-4.248,3.75-9.309,10.053-9.309,18.8h-4.465c0-8.128,3.842-15.988,10.818-22.144,5.17-4.564,11.506-7.657,14.9-7.267,1.43.18,4.01,1.291,5.15,6.744C3552.684,1123.99,3549.979,1138.977,3540.217,1144.779Z" transform="translate(-3520.016 -1108.62)" fill="#fff" />
                                    </g>
                                </g>
                                <rect id="Rectangle_48" data-name="Rectangle 48" width="11.784" height="4.469" transform="translate(753.01 1619.475) rotate(-39.405)" fill="#fff" />
                            </g>
                        </svg>






                    </div>

                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '23%' }}>
                            -5
                        </div>
                        <div style={{ width: '17%' }}>
                            -2.5
                        </div>
                        <div style={{ width: '20%', textAlign: 'center' }}>
                            0
                        </div>
                        <div style={{ width: '16%', textAlign: 'right' }}>
                            2.5
                        </div>
                        <div style={{ width: '24%', textAlign: 'right' }}>
                            5
                        </div>
                    </div>


                </div>
            </div>
        );
    }
}

export default BUSDChartLine;