import moment from 'moment';
import { Button } from 'primereact/button';
import React, { Component } from 'react'

import ChartFlexItem from '../components/ChartFlex/ChartFlexItem';
import ChartSync from '../components/ChartFlex/ChartSync';
import Input from '../components/Input_v2/Input';
import { parsed } from '../helpers/params';
import ChartFelxModel from '../models/ChartFelxModel';

class ChartFlex extends Component {

	constructor(props) {
		super(props);
        this.mode = 'test_net'
        this.id = 'ChartFlex' + this.mode
        this.storageAccountKey = `${this.id}_account`
        this.storageStartTime = `${this.id}_startTime`
        this.storageTimeLength = `${this.id}_timeLength`
        this.chartSycn = new ChartSync()
        this.params = parsed()
        this.state = {
            account: this.params.account ?  Number(this.params.account) : localStorage.getItem(this.storageAccountKey) ? localStorage.getItem(this.storageAccountKey) : ''  ,
            symbol: this.params.symbol ? Number(this.params.symbol): localStorage.getItem(this.storageSymbolKey) ? localStorage.getItem(this.storageSymbolKey) : '',
            timeLeng: localStorage.getItem(this.storageTimeLength) ? localStorage.getItem(this.storageTimeLength) : 3,
            startTime: moment().startOf('day').subtract(2, 'day').toDate(),
            account_options : [],
        }

        this.model = new ChartFelxModel()
    }

    componentDidMount(){
        
        this.model.getAccounts().then(res => {
            if(res){
                this.setState({account_options: res})
            }
        })
    }

    getTimeRange(){
        let startTime = Number(moment(this.state.startTime).format('X'))
        let stopTime = startTime  + Number(this.state.timeLeng) * 86400
        return {startTime, stopTime}
    }

    getAccount(){
        return this.state.account;
    }

    render(){
        
        return <div>
            <div className='flex'>
            {/* DateFormat = {'dd/mm/yyyy'} */}
                <Input dateFormat = {'yy/mm/dd'} type='date' Direct={true} value={this.state.startTime} placeholder="Start Time" OnChange={(val) => {
                    val = Number(val)
                    localStorage.setItem(this.storageStartTime, val);
                    this.setState({startTime: val})
                }}></Input>
                &nbsp;
                <Input className='input' style={{width:50, padding:6}}  type='number' Direct={true} value={this.state.timeLeng} placeholder="Days" OnChange={(val) =>{
                    val = Number(val)
                    localStorage.setItem(this.storageTimeLength, val);
                    this.setState({timeLeng: val})
                }}></Input>
                &nbsp;
                <Input type="select" Direct={true} value={this.state.account == '' ? this.state.account : Number(this.state.account)} OnChange={(val)=> {localStorage.setItem(this.storageAccountKey, val); this.setState({account: val})}} Options={this.state.account_options}></Input>
                &nbsp;
                
                <Button style={{padding:'0px 5px'}} onClick={()=>{
                    this.chartPrice.updateAllData()
                    this.chartFlex1.updateAllData()
                    this.chartFlex2.updateAllData()
                }}>Apply</Button>
            </div>
            <div key={this.state.account}>
                <ChartFlexItem model={this.model} ref={c => this.chartPrice = c} id="flex_00" sync={this.chartSycn} parent = {this} isPosition={true}></ChartFlexItem>
                <ChartFlexItem model={this.model} ref={c => this.chartFlex1 = c} id="flex_01" sync={this.chartSycn} parent = {this}></ChartFlexItem>
                <ChartFlexItem model={this.model} ref={c => this.chartFlex2 = c} id="flex_02" sync={this.chartSycn} parent = {this}></ChartFlexItem>
            </div>
        </div>
    }
}

export default ChartFlex