import React, { Component } from "react";
import BUSDChartLine from "./BUSDChartLine";
import ModelDashboard from "../../models/ModelDashboard";
class FaScoreView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            display: true
        }
        this.model = new ModelDashboard();
    }
    getData() {
        this.model.get_data_fa_score().then((res) => {
            if (res["result"]) {
                this.setState({
                    data: res["data"],
                });
            }
        });
    }

    componentDidMount() {
        if (this.props.day != '') {
            this.getData();
        }

    }
    render() {
        return (

            <div style={this.state.display ? { marginTop: 20 } : { marginTop: 10 }}>
                <div style={{ marginBottom: 20 , cursor : 'pointer' , display : 'flex' }} onClick={() => this.setState({display : !this.state.display })}> 
                    <span>FA scoring</span>

                    <i style={{marginLeft : 10 , marginTop : 3}} className={this.state.display ? "pi pi-angle-up" : "pi pi-angle-down"}></i>
                </div>
                <div
                    style={{
                
                        maxHeight: this.state.display ? "1000px" : '0',
                        transition: 'all .5s',
                        overflow : 'hidden'
                    }}>
                    {this.state.data.map((item, index) => {
                        return (

                            <div key={index} className="grid" style={{ background: 'white', marginBottom: 20, borderRadius: 8, marginLeft: 1 }}>
                                <div className="col-12 md:col-4">
                                    <BUSDChartLine config={item}></BUSDChartLine>
                                </div>
                                <div className="col-12 md:col-8" style={{ display: 'flex', alignItems: 'center', paddingLeft: 45 }}>
                                    {
                                        item['note']
                                    }
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default FaScoreView;
