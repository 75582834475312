import React, { Component } from 'react';
import ModelDashboard from '../../models/ModelDashboard';
import BUSDChart from './ChartMain/BUSDChart';
import BUSDChartLine from './ChartMain/BUSDChartLine';
import LongShortChart from './ChartMain/LongShortChart';
class FA_indicatorView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            total: 0
        }


        this.model = new ModelDashboard()
    }

    getData() {

        this.model.get_data_fa_score().then(res => {
            if (res['result']) {


                let totalWeight = 0
                let total = 0
                res['data'].map(item => {

                    let score = Number(item['score'])
                    let weight = Number(item['weight'])

                    totalWeight += weight

                    total += score * weight
                })


                this.setState({
                    data: res['data'],
                    total: Number((total / totalWeight).toFixed(2))
                });

            }
        })
    }

    componentDidMount() {
        this.getData()
    }
    render() {
        return (
            <div className='card' style={{ marginBottom: '0px', height: '100%' }}>

                <div >
               
                    <b style={{cursor : 'pointer'}} onClick={() => window.open(`${window.location.href}fa_score`)} >FA indicator</b>
                </div>



                <div className=' mt-2'>



                    <div style={{ marginBottom: '7px', textAlign: 'center' }}>


                        <b style={this.state.total > 0 ? { color: 'limegreen' } : { color: 'red' }}>
                            {this.state.total > 0 ? "LONG" : "SHORT"}
                            : {this.state.total}

                        </b>


                    </div>




                    {
                        this.state.data.map((item, index) => {

                           
                            return (
                                <div key={index} className='col-12 md:col-12'>
                                    <BUSDChartLine config={item}></BUSDChartLine>
                                </div>
                            )
                        }


                        )
                    }



                </div>

            </div>
        );
    }
}

export default FA_indicatorView;