import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import InputAce from '../Input/InputAce';
import moment from 'moment'
import { showLog } from "../../helpers/default";
import './index.scss'
import ModelWeight from '../../models/ModelWeight';
class EditWeight extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editDialog: false,
            data: {},
            title: ''
        }
        this.model = new ModelWeight()
    }
    //set data when open dialog edit
    openDialogEdit(data) {

        if (data == null) {
            this.setState({
                editDialog: !this.state.editDialog,
                data: {
                    "name": '',
                    "date": new Date(),
                    "weight": '',
                    "note": "",
                },
                title: 'Add'
            });
        } else {

            // data['date'] = moment(data['date'], 'YYYY_MM_DD')._d

            this.setState({
                editDialog: !this.state.editDialog,
                data: { ...data },
                title: 'Edit'
            });
        }





    }

    onApply() {

        let weight = this.inputAce.getValue()
        this.props.onApply(weight)
        this.setState({
            editDialog: !this.state.editDialog,
        });
    }


    //render button Cancle and Save
    footerEditDialog() {
        if (this.state.data.account == 'Default') {
            return (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {/* <Button label="Apply" icon="pi pi-check" onClick={() => this.onApply()}  /> */}
                    {
                        global.User in this.props.weight ? '' : <Button label="Apply & Save" icon="pi pi-save" className="p-button-warning" onClick={() => this.onSave('ADD')} />
                    }

                    <Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={() => { this.setState({ editDialog: false }); }} />

                </div>
            )
        } else {
            if (global.User == 'research_admin' || global.User == this.state.data.account) {
                return (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {/* <Button label="Apply" icon="pi pi-check"  /> */}
                        <Button label="Apply & Save" icon="pi pi-save" className="p-button-warning" onClick={() => this.onSave('EDIT')} />
                        <Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={() => { this.setState({ editDialog: false }); }} />

                    </div>
                )
            }
            return (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={() => { this.setState({ editDialog: false }); }} />

                </div>
            )
        }







    }

    //setstate data when input change
    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let data = { ...this.state.data };
        data[`${name}`] = val;

        this.setState({ data });
    }

    //call api save
    onSave(title) {

        let params = { ...this.state.data }




        params['weight'] = this.inputAce.getValue()

        try {
            let a = JSON.parse(params['weight'])

            if (title == 'ADD') {

                params['account'] = global.User
                params['note'] = ""
                delete params['_id']


                this.model.add({ 'data': params }).then(res => {

                    if (res['result']) {
                        this.props.getWeight()
                    }
                }
                )


            }
            else {

                this.model.edit({ 'data': params }).then(res => {

                    if (res['result']) {
                        this.props.getWeight()
                    }
                }
                )
            }
            this.setState({
                editDialog: !this.state.editDialog,
            });
        } catch (e) {
            showLog(e.message, 'error')
     
        }





       

    }
    render() {
        return (
            <Dialog visible={this.state.editDialog} onHide={() => { this.setState({ editDialog: false }); }} header={this.state.title} style={{ width: '50vw' }} footer={() => this.footerEditDialog()}>

                <div className='grid'>

                    <div className='col-12'>
                        <div className='field_cus' >
                            <label >Weight</label>
                            <InputAce ref={c => this.inputAce = c} value={this.state.data.weight} fontSize ={'12px'}></InputAce>

                        </div>

                    </div>

                </div>


            </Dialog>
        );
    }
}

export default EditWeight;