import Axios from "axios";
import { authen_url } from "../config/config";

export var logout = () => {
	Axios.request({
		url: authen_url + `/authen/login/logout`,
		method: "POST",
		withCredentials : true
	})

		.then(response => {
			window.location.href = authen_url + `/#/login?nexLink=${encodeURIComponent('https://research.f5traders.com/#/')}`
			// return response;
		})

		.catch((error) => {
			console.log(error);
			return false;
		})
}