import React, { Component } from 'react';

import Model3T from '../../models/Model3T';
import { MultiSelect } from 'primereact/multiselect';

class Chart3T extends Component {
    constructor(props) {
        super(props);
        this.state ={
            selT :  [{ name: 'T + 3', code: '3' }]
        }

        this.optionT = [
            { name: 'T + 5', code: '5' },
            { name: 'T + 4', code: '4' },
            { name: 'T + 3', code: '3' },
            { name: 'T + 2', code: '2' },
            { name: 'T + 1', code: '1' },
        ]

        this.model = new Model3T()
    }

    componentDidMount() {
        this.getData()
    }
    getData() {
        this.model.getData().then(res => {
            console.log(res)

            let chart = {
                x: [],
                y: [],
            }
            let count5T = {
                x: [],
                y: [],
            }
            let count4T = {
                x: [],
                y: [],
            }
            let count3T = {
                x: [],
                y: [],
            }
            let count2T = {
                x: [],
                y: [],
            }
            let count1T = {
                x: [],
                y: [],
            }

            let tickText = []
          
            res.map((item, index) => {
                chart.x.push(item['day'])
                // chart.x.push(index)
                chart.y.push(item['VN30'])

                count5T.x.push(item['day'])
                count5T.y.push(item['returnT5>0'])
                count4T.x.push(item['day'])
                count4T.y.push(item['returnT4>0'])
                count3T.x.push(item['day'])
                count3T.y.push(item['returnT3>0'])
                count2T.x.push(item['day'])
                count2T.y.push(item['returnT2>0'])
                count1T.x.push(item['day'])
                count1T.y.push(item['returnT1>0'])

                if (index % 50 == 0) {
                    tickText.push(item['day'])
                }

            })

            this.setState({
                data : chart,
                count5T,
                count4T,
                count3T,
                count2T,
                count1T
            },() => this.renderChart(tickText));


            
        })
    }
    renderChart(tickText) {
        var count5TData = {
            x: this.state.count5T.x,
            y: this.state.count4T.y,
            type: 'bar',
            name: 'T + 5'
        };
        var count4TData = {
            x: this.state.count4T.x,
            y: this.state.count4T.y,
            type: 'bar',
            name: 'T + 4'
        };
        var count3TData = {
            x: this.state.count3T.x,
            y: this.state.count3T.y,
            type: 'bar',
            name: 'T + 3'
        };
        var count2TData = {
            x: this.state.count2T.x,
            y: this.state.count2T.y,
            type: 'bar',
            name: 'T + 2'
        };
        var count1TData = {
            x: this.state.count1T.x,
            y: this.state.count1T.y,
            type: 'bar',
            name: 'T + 1'
        };
        var vn30 = {
            x: this.state.data.x,
            y: this.state.data.y,
            type: 'scatter',
            name: 'vn30',
            xaxis: 'x',
            yaxis: 'y2',
        };



        var dataChart = [ vn30];
        this.state.selT.map(item=>{
            if(item['code'] == '5'){
                dataChart.unshift(count5TData)
            }
            if(item['code'] == '4'){
                dataChart.unshift(count4TData)
            }
            if(item['code'] == '3'){
                dataChart.unshift(count3TData)
            }
            if(item['code'] == '2'){
                dataChart.unshift(count2TData)
            }
            if(item['code'] == '1'){
                dataChart.unshift(count1TData)
            }
        })
        var layout = {

            height: 800,
            showlegend: true,
            margin: { b: 100, t: 40 },
            autosize: true,
            hovermode: "x unified",
            hoverdistance: 10,
            barmode: 'group',
            font: {
                size: 12,
            },
            xaxis: {
                autorange: true,
                type: "category",
                categoryorder: 'category ascending',
                rangeslider: { 'visible': false },
                showspikes: true,
                spikemode: 'across',
                spikesnap: 'cursor',
                spikedash: 'dot',
                // spikecolor: 'black',
                spikethickness: 2,
                  tickmode: "array",
                  tickvals: tickText,
                  ticktext: tickText,

                // showgrid: false,
                //   showticklabels: true,
                tickangle: 'auto',
                showexponent: 'all'
                // gridcolor: 'white',
            },
            yaxis: {
                autorange: true,
                showspikes: true,
                spikemode: 'across',
                spikesnap: 'cursor',
                spikedash: 'dot',
                // spikecolor: 'black',
                spikethickness: 2,
                domain: [0.42, 1]
            },

            yaxis2: { domain: [0, 0.4] },

            shapes: [
                {
                    type: 'line',
                    xref: 'paper',
                    x0: 0,
                    y0: 3,
                    x1: 1,
                    y1: 3,
                    line: {
                        color: 'black',
                        width: 1,
                        dash: 'dot'
                    }
                },
                {
                    type: 'line',
                    xref: 'paper',
                    x0: 0,
                    y0: 27,
                    x1: 1,
                    y1: 27,
                    line: {
                        color: 'black',
                        width: 1,
                        dash: 'dot'
                    }
                }
              
            ],


            grid: {
                rows: 2,
                columns: 1,
                subplots: [['xy'], ['xy2']],
                roworder: 'top to bottom',
                pattern: 'independent'
            }




        };

        global.Plotly.newPlot(this.chart, dataChart, layout);
    }
    render() {
        return (
            <div>
                <div>
                <MultiSelect value={this.state.selT} options={this.optionT} onChange={(e) => this.setState({ selT: e.value }, () => this.renderChart() )} optionLabel="name" placeholder="Select options"  />
                </div>
                <div ref={c => this.chart = c}>

                </div>
            </div>
        );
    }
}

export default Chart3T;