import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import EditFunc from './EditFunc';
import DelFunc from './DelFunc';

import moment from 'moment';
import ModelWeight from '../../models/ModelWeight';

class TableWeight extends Component {
    constructor(props) {
        super(props);
        this.state = {


            data: [],
        }

        this.model = new ModelWeight()
    }

    getData(){
        this.model.filter().then(res => {
            if(res['result']){
                this.setState({
                    data : res['data']
                });
            }
        })
    }

    componentDidMount(){
       this.getData()
    }

    representativeTemplate = (data, props) => {



      

        if (props.field == "index") {

            return (
                <span>{props.rowIndex + 1}</span>
            )
        }
        if (props.field == "setting") {

            return (
                <div style={{ display: 'flex' }} >
                    <Button icon="pi pi-pencil" className="p-button-rounded  p-button-outlined p-button-success mr-2" onClick={() => this.EditFunc.openDialogEdit(data)} />
                    <Button icon="pi pi-trash" className="p-button-rounded  p-button-outlined  p-button-danger " onClick={() => this.DelFunc.openDialogDel(data)} />
                </div>
            )
        }
        if (props.field == "weight") {
            if(data[props.field] == '') return
            let dataWeight = JSON.parse(data[props.field])
            
            let a = []

            Object.keys(dataWeight).map(k => {
                a.push(`${k} : ${dataWeight[k]}`)
               
            })

            return a.toString()
        }


    }
    render() {
        return (
            <div className='mt-2'>
                <div className='mb-2' style={{display : 'flex' , justifyContent : 'flex-end'}}>
                    <Button label="New" icon="pi pi-plus" className="p-button-success" onClick={() => this.EditFunc.openDialogEdit(null)} />
                </div>
                <div className="card">

                    <DataTable
                        className="p-datatable-customers"
                        value={this.state.data}
                        stripedRows
                        paginator responsiveLayout="scroll"
                        paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rows={25} rowsPerPageOptions={[25, 50]}
                    >
                        <Column field="index" header="Index" body={(data, props) => this.representativeTemplate(data, props)} style={{ width: '4rem' }}  ></Column>
                        <Column field="setting" header="Action" body={(data, props) => this.representativeTemplate(data, props)} style={{ width: '8rem' }} ></Column>
                        <Column field="account" header="Account"  ></Column>
                        {/* <Column field="date" header="Date" ></Column> */}
                        <Column field="weight" header="Weight" body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                        <Column field="note" header="Note"  style={{maxWidth : '350px'}} ></Column>
                 

                    </DataTable>
                </div>

                <EditFunc table={this} ref={c => this.EditFunc = c}></EditFunc>
                <DelFunc table={this} ref={c => this.DelFunc = c} ></DelFunc>

        

            </div>
        );
    }
}

export default TableWeight;