import React, { Component } from 'react';
import { Checkbox } from 'primereact/checkbox';
import Input from '../Input_v2/Input';
import { Button } from 'primereact/button';
import ModelPressure from '../../models/ModelPressure';
import ModelVn30 from '../../models/ModelVn30';
import moment from 'moment';
import { loading } from '../../helpers/default';
import ModelBusdVn30 from '../../models/ModelBusdVn30';
class PressureVn30View extends Component {
    constructor(props) {
        super(props);
        this.id = 'pressureVn30'
        this.storageStartTime = `${this.id}_startTime`
        this.storageTimeLength = `${this.id}_timeLength`
        this.state = {
            stock: [],
            stockSelect: [],
            checkAll: true,
            timeLeng: localStorage.getItem(this.storageTimeLength) ? localStorage.getItem(this.storageTimeLength) : 1,
            startTime: localStorage.getItem(this.storageStartTime) ? moment(localStorage.getItem(this.storageStartTime), 'x').toDate() : new Date('2022/09/05'),
            data: []
        }

        this.model = new ModelPressure()
        this.modelVn30 = new ModelVn30()
        this.modelBusdVn30 = new ModelBusdVn30()



    }


    componentDidMount() {
        this.getData()
    }
    // handleDataVn30(res) {
    //     let cs = {
    //         x: [],
    //         y: [],
    //     }
    //     let ps = {
    //         x: [],
    //         y: [],
    //     }

    //     res.map(item => {
    //         let day = moment(item['timestamp'], 'X').format('YYYY/MM/DD HH:mm:ss')
    //         cs['x'].push(day)
    //         cs['y'].push(item['price'])
    //         ps['x'].push(day)
    //         ps['y'].push(item['ps'])
    //     })

    //     return {
    //         cs, ps
    //     }
    // }

    handleDataVn30(dataCs, dataPs) {
        if (dataCs.length == 0 || dataPs.length == 0) {
            return {
                cs: {
                    x: [],
                    y: [],
                },
                ps : {
                    x: [],
                    y: [],
                }
            }
        }
        let cs = {
            x: [],
            y: [],
        }
        let ps = {
            x: [],
            y: [],
        }

        let lastTime = null

        dataCs.map((item, index) => {
            let day = moment(item['timestamp'], 'X').format('YYYY/MM/DD HH:mm:ss')
            let dataPs1 = dataPs[index] ? dataPs[index]['psPrice'] : dataPs[index-1]['psPrice']
            cs['x'].push(day)
            cs['y'].push(item['csPrice'])
            ps['x'].push(day)
            // ps['y'].push(dataPs[index]['psPrice'])
            ps['y'].push(dataPs1)
        })



        return {
            cs, ps
        }
    }

    renderChart(chart, vn30, isFilterLocal, dataBusdVn30Tran) {
        let tickText = []
        chart['total']['day'].map((item, index) => {
            if (index % 100 == 0) {
                tickText.push(item)
            }
        })

        var data = []
        Object.keys(chart).map(stock => {
            if (stock == 'total') {
                data.push({
                    x: chart[stock]['day'],
                    y: chart[stock]['buyPressure'],
                    name: 'Total BuyPressure'
                })
                data.push({
                    x: chart[stock]['day'],
                    y: chart[stock]['sellPressure'],
                    name: 'Total SellPressure'
                })
            }
            if (isFilterLocal && this.state.stockSelect.includes(stock) && this.state.stockSelect.length == 1) {
                data.push({
                    x: chart[stock]['day'],
                    y: chart[stock]['close'],
                    type: 'scatter',
                    xaxis: 'x',
                    yaxis: 'y4',
                    name: `Price ${stock}`
                })
            }


        })

        Object.keys(dataBusdVn30Tran).map(stock => {
            if (stock == 'total') {
                data.push({
                    x: dataBusdVn30Tran[stock]['day'],
                    y: dataBusdVn30Tran[stock]['BU'],
                    xaxis: 'x',
                    yaxis: 'y2',
                    name: 'Total BU'
                })
                data.push({
                    x: dataBusdVn30Tran[stock]['day'],
                    y: dataBusdVn30Tran[stock]['SD'],
                    xaxis: 'x',
                    yaxis: 'y2',
                    name: 'Total SD'
                })
            }
            // if(isFilterLocal && this.state.stockSelect.includes(stock) && this.state.stockSelect.length == 1){
            //     data.push({
            //         x: chart[stock]['day'],
            //         y: chart[stock]['close'],
            //         type: 'scatter',
            //         xaxis: 'x',
            //         yaxis: 'y3',
            //         name: `Price ${stock}`
            //     })
            // }


        })


        var cs = {
            x: vn30['cs']['x'],
            y: vn30['cs']['y'],
            type: 'scatter',
            xaxis: 'x',
            yaxis: 'y3',
            name: 'VnIndex Cs'
        };
        var ps = {
            x: vn30['ps']['x'],
            y: vn30['ps']['y'],
            type: 'scatter',
            xaxis: 'x',
            yaxis: 'y3',
            name: 'VnIndex Ps'
        };





        data.push(cs, ps)

        var layout = {
            title: 'Pressure Vn30',
            height: 800,
            showlegend: true,
            margin: { b: 100, t: 40 },
            autosize: true,
            hovermode: "x unified",
            hoverdistance: 10,
            barmode: 'group',
            font: {
                size: 12,
            },
            xaxis: {
                autorange: true,
                type: "category",
                categoryorder: 'category ascending',
                rangeslider: { 'visible': false },
                showspikes: true,
                spikemode: 'across',
                spikesnap: 'cursor',
                spikedash: 'dot',
                spikethickness: 2,
                tickmode: "array",
                tickvals: tickText,
                ticktext: tickText,
                tickangle: 'auto',
                showexponent: 'all'
            },
            yaxis: {
                autorange: true,
                showspikes: true,
                spikemode: 'across',
                spikesnap: 'cursor',
                spikedash: 'dot',
                spikethickness: 2,
                domain: [0.62, 1]
            },

            yaxis2: { domain: [0.32, 0.6] },
            yaxis3: { domain: [0, 0.3] },

            grid: {
                rows: 3,
                columns: 1,
                subplots: [['xy'], ['xy2'], ['xy3']],
                roworder: 'top to bottom',
                pattern: 'independent'
            }




        };

        if (isFilterLocal && this.state.stockSelect.length == 1) {
            layout['yaxis']['domain'] = [0.7, 1]
            layout['yaxis2']['domain'] = [0.42, 0.68]
            layout['yaxis3']['domain'] = [0.22, 0.4]
            layout['yaxis4'] = { domain: [0, 0.2] }
            layout['grid']['rows'] = 4
            layout['grid']['subplots'] = [['xy'], ['xy2'], ['xy3'], ['xy4']]
        }



        return global.Plotly.newPlot(this.chart, data, layout);

    }
    changeStock(e) {

        let selectedStock = [...this.state.stockSelect];

        if (e.checked)
            selectedStock.push(e.value);
        else
            selectedStock.splice(selectedStock.indexOf(e.value), 1);

        this.setState({ stockSelect: selectedStock }, () => {
            this.filterData()
        });




    }
    changeAll(e) {

        if (e.checked) {
            this.setState({
                checkAll: e.checked,
                stockSelect: this.state.stock
            }, () => this.filterData());

        } else {
            this.setState({
                checkAll: e.checked,
                stockSelect: []
            }, () => this.filterData());

        }

    }
    async getData() {
        let startTime = moment(this.state.startTime).format('X')
        let stopTime = moment(this.state.startTime).add(this.state.timeLeng, 'd').format('X')


        let dataBusdVn30 = await this.modelBusdVn30.getBusdVn30(startTime, stopTime)
        let dataBusdVn30Tran

        if (dataBusdVn30['result']) {
            dataBusdVn30Tran = this.handleDataBusdVn30(dataBusdVn30['data'], false)

        }

        let data = await this.model.filterVn30(startTime, stopTime)

        let chart = this.handleData(data, false)

        // let dataVn30 = await this.modelVn30.filter(startTime, stopTime)

        let dataVn30Cs = await this.modelVn30.vn30_cs(startTime, stopTime, true)
        let dataVn30Ps = await this.modelVn30.vn30_ps(startTime, stopTime, true)
        // debugger
        // let vn30 = this.handleDataVn30(dataVn30)
        let vn30 = this.handleDataVn30(dataVn30Cs, dataVn30Ps)
        this.setState({
            data, vn30, dataBusdVn30: dataBusdVn30['data']
        });
        this.renderChart(chart, vn30, false, dataBusdVn30Tran)

    }

    filterData() {
        loading(true, 'Process data...')
        setTimeout(() => {
            let chart = this.handleData(this.state.data, true)

            let chart1 = this.handleDataBusdVn30(this.state.dataBusdVn30, true)
            return this.renderChart(chart, this.state.vn30, true, chart1).then(() => {
                loading(false)
            })
        }, 100)



    }

    handleData(data, isFilterLocal) {


        let chart = {
            'total': {
                'buyPressure': [],
                'sellPressure': [],
                'day': []
            }
        }

        let totalStock = []

        for (let i = 0; i < data.length; i++) {

            const item = data[i];
            let stock = item['stock']
            if (!totalStock.includes(stock)) totalStock.push(stock)

            var isCheck = true

            if (!this.state.stockSelect.includes(stock)) {
                isCheck = false
            }

            if (!isFilterLocal && this.state.stockSelect.length == 0) {
                isCheck = true
            }

            if (isCheck) {

                let day = moment(item['timestamp'], 'X').format('YYYY/MM/DD HH:mm:ss')

                let buyPressure = Number(item['buyPressure'])
                let sellPressure = Number(item['sellPressure'])
                let close = Number(item['last'])
                if (!chart[stock]) {
                    chart[stock] = {
                        'buyPressure': [],
                        'sellPressure': [],
                        'close': [],
                        'day': []
                    }
                }
                chart[stock]['day'].push(day)
                chart[stock]['buyPressure'].push(buyPressure)
                chart[stock]['sellPressure'].push(sellPressure)
                chart[stock]['close'].push(close)
                let index = chart['total']['day'].indexOf(day)
                if (index > -1) {
                    chart['total']['buyPressure'][index] += buyPressure
                    chart['total']['sellPressure'][index] += sellPressure
                } else {

                    chart['total']['day'].push(day)
                    chart['total']['buyPressure'].push(buyPressure)
                    chart['total']['sellPressure'].push(sellPressure)
                }
            } else {
                continue;
            }



        }

        if (!isFilterLocal) {
            let state = {
                stock: totalStock.sort(),
                checkAll: true

            }
            if (this.state.stockSelect.length == 0) {
                state['stockSelect'] = totalStock.sort()
            }

            this.setState(state);
        }
        return chart
    }

    handleDataBusdVn30(data, isFilterLocal) {


        let chart = {
            'total': {
                'BU': [],
                'SD': [],
                'day': []
            }
        }



        for (let i = 0; i < data.length; i++) {

            const item = data[i];
            let stock = item['stock']


            var isCheck = true

            if (!this.state.stockSelect.includes(stock)) {
                isCheck = false
            }

            if (!isFilterLocal && this.state.stockSelect.length == 0) {
                isCheck = true
            }

            if (isCheck) {

                let timestamp = Number(item['timestamp']) - 3600 * 7
                let day = moment(timestamp, 'X').format('YYYY/MM/DD HH:mm:ss')

                let BU = Number(item['BU'])
                let SD = Number(item['SD'])

                if (!chart[stock]) {
                    chart[stock] = {
                        'BU': [],
                        'SD': [],
                        'day': []
                    }
                }
                chart[stock]['day'].push(day)
                chart[stock]['BU'].push(BU)
                chart[stock]['SD'].push(SD)

                let index = chart['total']['day'].indexOf(day)
                if (index > -1) {
                    chart['total']['BU'][index] += BU
                    chart['total']['SD'][index] += SD
                } else {

                    chart['total']['day'].push(day)
                    chart['total']['BU'].push(BU)
                    chart['total']['SD'].push(SD)
                }
            } else {
                continue;
            }



        }
        return chart
    }
    render() {
        return (
            <>
                <div className='flex' style={{ marginBottom: '10px' }}>

                    <Input DateFormat={'dd/mm/yyyy'} type='date' Direct={true} value={this.state.startTime} placeholder="Start Time" OnChange={(val) => {
                        val = Number(val)
                        localStorage.setItem(this.storageStartTime, val);
                        this.setState({ startTime: val })
                    }}></Input>
                    &nbsp;
                    <Input className='input' style={{ width: 50, padding: 6 }} type='number' Direct={true} value={this.state.timeLeng} placeholder="Days" OnChange={(val) => {
                        val = Number(val)
                        localStorage.setItem(this.storageTimeLength, val);
                        this.setState({ timeLeng: val })
                    }}></Input>
                    &nbsp;

                    <Button style={{ padding: '0px 5px' }} onClick={() => {
                        this.getData()
                    }}>Apply</Button>
                </div>
                <div className='card' style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div className="field-checkbox" style={{ marginRight: '20px', width: '50px' }}>
                        <Checkbox inputId="All" value="All" onChange={(e) => this.changeAll(e)} checked={this.state.checkAll} />
                        <label htmlFor="All">ALL</label>
                    </div>
                    {
                        this.state.stock.map(item => {
                            return (
                                <div key={item} className="field-checkbox" style={{ marginRight: '20px', width: '50px' }}>
                                    {/* checked={this.state.cities.indexOf('Chicago') !== -1} */}
                                    <Checkbox inputId={item} value={item} onChange={(e) => this.changeStock(e)} checked={this.state.stockSelect.indexOf(item) !== -1} />
                                    <label htmlFor={item}>{item}</label>
                                </div>
                            )
                        })
                    }
                </div>

                <div ref={c => this.chart = c}>
                </div>
            </>

        );
    }
}

export default PressureVn30View;