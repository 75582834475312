import React, { Component } from 'react';
import moment from "moment";
class BidAskScore extends Component {
   
    findClosestTime(inputTime, data) {
        const inputMoment = moment(inputTime, "HH:mm:ss");
        if(data.length ==0) {
            return inputTime 
        }
        let closestTime = null;
        for (let i = 0; i < data.length; i++) {
          const currTime = moment(data[i], "HH:mm:ss");
          if (currTime.isAfter(inputMoment)) {
            closestTime = data[i];
            break;
          }
        }
        if (!closestTime) {
          const lastTime = data[data.length - 1];
          const lastMoment = moment(lastTime, "HH:mm:ss");
          if (inputMoment.isAfter(lastMoment)) {
            closestTime = inputTime;
          } else {
            closestTime = data.reduce((prev, curr) => {
              const currMoment = moment(curr, "HH:mm:ss");
              const prevMoment = moment(prev, "HH:mm:ss");
              const currDiff = Math.abs(currMoment.diff(inputMoment));
              const prevDiff = Math.abs(prevMoment.diff(inputMoment));
              return currDiff < prevDiff ? curr : prev;
            });
          }
        }
        return closestTime;
      }
    renderChart(BA_Point) {
    
        let timeArrayTran = ['09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '13:30', '14:00', '14:30', '15:00']
        let timeArray = []
        timeArrayTran.map((item, idx) => {
            let tran = item + ':00'
            if (BA_Point['x'].indexOf(tran) > -1) {
                timeArray[idx] =tran
            } else {
                let a = this.findClosestTime(tran, BA_Point['x'])
                timeArray[idx] = a
            }
        })
        var BA_PointData = {
            x: BA_Point.x,
            y: BA_Point.y,
            type: "scatter",
            name: "Score",
        };
        var dataChart = [BA_PointData];

        var layout = {
            title: "Bid Ask Score",
            height: 300,
            showlegend: true,
            margin: { b: 40, t: 40, left: 1000,  pad: 10 },
            autosize: true,
            hovermode: "x unified",
            hoverdistance: 10,
            barmode: "group",
            font: {
                size: 12,
            },
            xaxis: {
                autorange: true,
                type: "category",
                categoryorder: "category ascending",
                rangeslider: { visible: false },
                showspikes: true,
                spikemode: "across",
                spikesnap: "cursor",
                spikedash: "dot",
                spikethickness: 2,
                tickmode: "array",
                tickvals: timeArray,
                ticktext: timeArrayTran,

                tickangle: "auto",
                showexponent: "all",
            },
            yaxis: {
                autorange: true,
                showspikes: true,
                spikemode: "across",
                spikesnap: "cursor",
                spikedash: "dot",
                spikethickness: 2,
            },
            legend: {
                x: 0,
                y: 1.1,
                "orientation": "h"
            }
        };
        var config = { responsive: true };
        global.Plotly.newPlot(this.chart, dataChart, layout, config);
    }
    loadData(BA_Point){
        this.renderChart(BA_Point) 
    }

    updateXAxis(data){
        let timeArrayTran = ['09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '13:30', '14:00', '14:30', '15:00']
        let timeArray = []
        timeArrayTran.map((item, idx) => {
            let tran = item + ':00'
            if (data.indexOf(tran) > -1) {
                timeArray[idx] =tran
            } else {
                let a = this.findClosestTime(tran, data)
                timeArray[idx] = a
            }
        })
        let layout = this.chart.layout
        layout.xaxis.ticktext =  timeArrayTran
        layout.xaxis.tickvals = timeArray
        global.Plotly.relayout(this.chart, layout);

    }

    componentDidMount() {
    
    }
    render() {
        return (
            <div style={{ width: "100%" , marginLeft : -5}}>
                <div ref={(c) => (this.chart = c)}></div>
            </div>
        );
    }
}

export default BidAskScore;