import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

class TableBidAskDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {


            data: []
        }
    }

    setData(data){
        let out = []
        let col = Object.keys(data)
        let len = data[col[0]].length
        for (let i = 0; i < len; i++) {
            let row = {}
            col.map(k => {
                row[k] = data[k][i]
            })

            out.push(row)
            
        }

        this.setState({
            data : out
        });
    }

    representativeTemplate = (data, props) => {



        let col = ["win_rate" ,"cum_profit","max_drawdown", "profit" ]
   
        if (col.includes(props.field)) {
            if (data[props.field] == null) return 'NULL'

            let result = Math.round(data[props.field] * 100) / 100
            if (result == 0) result = '-'

            return (
                <span>{result}</span>
            )
        }
      
        if (props.field == "index") {

            return (
                <span>{props.rowIndex + 1}</span>
            )
        }
    

    }
    
    render() {
        return (
            <div className='mt-2'>
                <div className="card">

                    <DataTable
                        className="p-datatable-customers"
                        value={this.state.data}
                        stripedRows
                        paginator responsiveLayout="scroll"
                        paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rows={25} rowsPerPageOptions={[ 25, 50]}
                    >
                        <Column field="index" header="Index" body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                        <Column field="trade_type" header="Type" ></Column>
                        <Column field="threshold" header="Threshold" sortable ></Column>
                        <Column field="min_time" header="Min time" sortable ></Column>
                        <Column field="ignore_time" header="Ignore time" sortable ></Column>
                        <Column field="tp_sl" header="TP_SL" sortable ></Column>
                        <Column field="win_rate" header="Win rate" body={(data, props) => this.representativeTemplate(data, props)} sortable></Column>
                        <Column field="cum_profit" header="Cum profit" body={(data, props) => this.representativeTemplate(data, props)} sortable  ></Column>
                        <Column field="max_drawdown" header="Max drawdown" body={(data, props) => this.representativeTemplate(data, props)} sortable ></Column>
                        <Column field="profit" header="Profit" body={(data, props) => this.representativeTemplate(data, props)} sortable ></Column>
                        <Column field="total_trade" header="Total trade" sortable></Column>
                       
                        {/* <Column field="side" header="Side" ></Column> */}
                        
                   
                  
                    </DataTable>
                </div>
            </div>
        );
    }
}

export default TableBidAskDetail;