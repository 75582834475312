import moment from 'moment';
import React, { Component } from 'react';
import ModelDashboard from '../../models/ModelDashboard';
import BUSDChart from './ChartMain/BUSDChart';
import BUSDChart_v1 from './ChartMain/BUSDChart_v1';
import LongShortChart from './ChartMain/LongShortChart';
import LongSumChart from './ChartMain/LongSumChart';
import LongSumChart_v1 from './ChartMain/LongSumChart_v1';
class TnModelView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            time: '',
            colorTime: ''
        }



        this.model = new ModelDashboard()
    }


    componentDidMount() {



        this.getTnModel()
    }
    getTnModel() {
        this.model.get_data_Tn_model().then(res => {
            let data = res['alert']
        
           
            let color
            if (Number(moment().format('X')) - Number(moment(res['day'].replaceAll('_', '/')).format('X')) <= 172800) {
                color = 'limegreen'
            } else {
                color = 'red'
            }
            this.setState({
                time: res['day'],
                colorTime: color
            });

            if (data['T2']) {
                let mess1 = `T2 tín hiệu ${data['T2']['type'].toUpperCase()}, Winrate : ${(data['T2']['win_rate'] * 100).toFixed(2)} % `
                this.chart1.setContent(Number(data['T2']['win_rate'] * 100).toFixed(2), data['T2']['type'], mess1)

            }else{
                let mess1 = `T2 không có tín hiệu `
                this.chart1.setContent(0, '', mess1)
            }

            if(data['T3']){
                let mess2 = `T3 tín hiệu ${data['T3']['type'].toUpperCase()}, Winrate : ${(data['T3']['win_rate'] * 100).toFixed(2)} % `
                this.chart2.setContent(Number(data['T3']['win_rate'] * 100).toFixed(2), data['T3']['type'], mess2)
            }else{
                let mess2 = `T3 không có tín hiệu `
                this.chart2.setContent(0, '', mess2)
            }

            var title
            if(data['summary']){
                title = `Tín hiệu ${data['summary']['type'].toUpperCase()}, Winrate : ${(data['summary']['win_rate'] * 100).toFixed(1)} % `

            }
          
            if(data['summary'] ){
                let value = data['summary']['type'] == 'long'  ?  Number(data['summary']['win_rate'] * 100).toFixed(1) : - Number(data['summary']['win_rate'] * 100).toFixed(1)
                this.LongShortChart.setNum(value, 200 , title)
              
            }else{
                
                this.LongShortChart.setNum(0, 200)
                
            }
           

        })
    }
    render() {
        return (
            <div className='card' style={{ marginBottom: '0px', height: '100%' }} >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div >
                        <b style={{cursor : 'pointer'}} onClick={() => window.open(`${window.location.href}t_n`)}>T+n Model</b>
                    </div>
                    <div >
                        <div style={{ color: this.state.colorTime }}  >
                            <i className="pi pi-clock"></i>
                            <b className='ml-2'  >{this.state.time}</b>
                        </div>
                    </div>
                </div>

                <div>

                    {/* <LongSumChart_v1 ref={c => this.LongShortChart = c}></LongSumChart_v1> */}
                    <LongShortChart ref={c => this.LongShortChart = c}></LongShortChart>

                </div>

                <div className='grid mt-2'>
                    <div className='col-12 md:col-12 ' style={{display : 'flex' }}>

                        <BUSDChart_v1 ref={c => this.chart1 = c} title='T2'></BUSDChart_v1>
                    </div>
                    <div className='col-12 md:col-12'  style={{display : 'flex' }}>

                        <BUSDChart_v1 ref={c => this.chart2 = c} title='T3'></BUSDChart_v1>
                    </div>
                </div>

            </div>
        );
    }
}

export default TnModelView;