import React, { Component } from 'react';
import BUSDChart from './ChartMain/BUSDChart';
import LongShortChart from './ChartMain/LongShortChart';
class Arbit_UnwindView extends Component {

    componentDidMount() {
        this.LongShortChart.setNum(35)
        this.chart1.setContent('Short 100%' , 100)
        this.chart2.setContent('Long 60%', 60)
        this.chart3.setContent('Short 60%' , 60)
        this.chart4.setContent('Long 80%', 80)
    }
    render() {
        return (
            <div className='card' style={{marginBottom : '0px'}}>
                <div style={{display : 'flex' , justifyContent : 'space-between'}}>
                    <div >
                        <b>Arbit/Unwind</b>
                    </div>
                    <div >
                        <div >
                            <i className="pi pi-clock"></i>
                            <b className='ml-2' >9:00</b>
                        </div>
                    </div>
                </div>

                <div>
                    <LongShortChart ref={c => this.LongShortChart = c}></LongShortChart>

                </div>

                <div className='grid mt-2'>
                    <div className='col-6 md:col-6 '>
                        <BUSDChart title='BUSD ST01' ref={c => this.chart1 = c}></BUSDChart>
                    </div>
                    <div className='col-6 md:col-6'>
                        <BUSDChart title='BUSD ST02' ref={c => this.chart2 = c}></BUSDChart>
                    </div>
                    <div className='col-6 md:col-6 '>
                        <BUSDChart title='BUSD ST03' ref={c => this.chart3 = c}></BUSDChart>
                    </div>
                    <div className='col-6 md:col-6'>
                        <BUSDChart title='BUSD ST04' ref={c => this.chart4 = c}></BUSDChart>
                    </div>
                </div>

            </div>
        );
    }
}

export default Arbit_UnwindView;