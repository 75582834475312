import Axios from "axios";
import { lv3_api_gateway } from "../config/config"
import { loading } from "../helpers/default";
import { error_handle } from "../helpers/error";


class Watchlist{
    constructor(){
        this.url = lv3_api_gateway
    }

    get(){
        loading(true, 'Loading...')
        return Axios.request({
            url: `${this.url}/watchlist/get_all`,
            method: "POST",
            withCredentials: true,
        })

            .then(response => {
                loading(false, 'Loading...')
                response = response['data'];
                if(response['result']) return response
                error_handle(response)

            })
            .catch(error => {
                error_handle(error)
            })
    }

    


}


export default Watchlist