import axios from "axios";
import { cmc3_api_gateway } from "../config/config";
import { checkLogin, loading } from "../helpers/default";
import { error_handle } from '../helpers/error'

class ModelDashboardV2 {

    constructor() {
        this.url = cmc3_api_gateway
        
    }

    get_data_close_ps(loadingT,data){
        loading(loadingT);
        return axios.request({
			url: cmc3_api_gateway + '/dashboard_v2/close_ps',
			method: 'POST',
			data: {...data},
            withCredentials: true,
		})
			.then(response => {
				loading(false, 'Loading...');
				response = response['data'];

				if(response['result']){
                   
                    return response['data'];
                }else{
                    error_handle(response);
                    return false;
                }
			})

			.catch((error) => {
				loading(false, 'Loading...');
				error_handle(error)
				return false;
			})
    }
    get_data_bid_ask(loadingT){
        loading(loadingT);
        return axios.request({
			url: cmc3_api_gateway + '/dashboard_v2/Bid_Ask_Data',
			method: 'POST',
			data: {},
            withCredentials: true,
		})
			.then(response => {
				loading(false, 'Loading...');
				response = response['data'];

				if(response['result']){
                   
                    return response['data'];
                }else{
                    error_handle(response);
                    return false;
                }
			})

			.catch((error) => {
				loading(false, 'Loading...');
				error_handle(error)
				return false;
			})
    }

	get_data_bid_ask2(loadingT , data){
        loading(loadingT);
        return axios.request({
			url: cmc3_api_gateway + '/dashboard_v2/Bid_Ask_Data_v2',
			method: 'POST',
			data: {...data},
            withCredentials: true,
		})
			.then(response => {
				loading(false, 'Loading...');
				response = response['data'];

				if(response['result']){
                   
                    return response['data'];
                }else{
                    error_handle(response);
                    return false;
                }
			})

			.catch((error) => {
				loading(false, 'Loading...');
				error_handle(error)
				return false;
			})
    }

	get_data_foreign(loadingT , data){
        loading(loadingT);
        return axios.request({
			url: cmc3_api_gateway + '/dashboard_v2/foregin',
			method: 'POST',
			data: {...data},
            withCredentials: true,
		})
			.then(response => {
				loading(false, 'Loading...');
				response = response['data'];

				if(response['result']){
                   
                    return response['data'];
                }else{
                    error_handle(response);
                    return false;
                }
			})

			.catch((error) => {
				loading(false, 'Loading...');
				error_handle(error)
				return false;
			})
    }
	get_data_foreign_cs(loadingT , data){
        loading(loadingT);
        return axios.request({
			url: cmc3_api_gateway + '/dashboard_v2/foregin_cs',
			method: 'POST',
			data: {...data},
            withCredentials: true,
		})
			.then(response => {
				loading(false, 'Loading...');
				response = response['data'];

				if(response['result']){
                   
                    return response['data'];
                }else{
                    error_handle(response);
                    return false;
                }
			})

			.catch((error) => {
				loading(false, 'Loading...');
				error_handle(error)
				return false;
			})
    }

	get_data_busd(loadingT , data){
        loading(loadingT);
        return axios.request({
			url: cmc3_api_gateway + '/dashboard_v2/busd',
			method: 'POST',
			data: {...data},
            withCredentials: true,
		})
			.then(response => {
				loading(false, 'Loading...');
				response = response['data'];

				if(response['result']){
                   
                    return response['data'];
                }else{
                    error_handle(response);
                    return false;
                }
			})

			.catch((error) => {
				loading(false, 'Loading...');
				error_handle(error)
				return false;
			})
    }


	get_data_score(loadingT , data){
        loading(loadingT);
        return axios.request({
			url: cmc3_api_gateway + '/dashboard_v2/score',
			method: 'POST',
			data: {...data},
            withCredentials: true,
		})
			.then(response => {
				loading(false, 'Loading...');
				response = response['data'];

				if(response['result']){
                   
                    return response['data'];
                }else{
                    // error_handle(response);
                    return false;
                }
			})

			.catch((error) => {
				loading(false, 'Loading...');
				error_handle(error)
				return false;
			})
    }

	get_data_note(loadingT ){
        loading(loadingT);
        return axios.request({
			url: cmc3_api_gateway + '/dashboard_v2/note',
			method: 'POST',

            withCredentials: true,
		})
			.then(response => {
				loading(false, 'Loading...');
				response = response['data'];

				if(response['result']){
                   
                    return response['data'];
                }else{
                    error_handle(response);
                    return false;
                }
			})

			.catch((error) => {
				loading(false, 'Loading...');
				error_handle(error)
				return false;
			})
    }


	get_data_total_score_v2(loadingT , data){
        loading(loadingT);
        return axios.request({
			url: cmc3_api_gateway + '/dashboard_v2/total_score_v2',
			method: 'POST',
			data: {...data},
            withCredentials: true,
		})
			.then(response => {
				loading(false, 'Loading...');
				response = response['data'];

				if(response['result']){
                   
                    return response['data'];
                }else{
                    error_handle(response);
                    return false;
                }
			})

			.catch((error) => {
				loading(false, 'Loading...');
				error_handle(error)
				return false;
			})
    }
   

   
    






}

export default ModelDashboardV2;