import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ModelBusdScore from '../../models/ModelBusdScore';
import { Checkbox } from 'primereact/checkbox';
class BusdScoreTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            tempData: [],
            status: '',
            lastStatus: '',
            checked: false
        }

        this.model = new ModelBusdScore()
    }

    getData() {
        this.model.getWinRate().then(res => {
            let status = res['status'].split('|')
            if (status.length > 0) var lastStatus = 'action' + status[status.length - 1]; else var lastStatus = ''

            let temp = []

            if(!this.state.checked){
                res['score'].map(item => {
                    if (item['strategy'] == lastStatus) {
                        temp.push(item)
                    }
                })
    
            }else{
                temp = this.state.tempData
            }

           
            this.setState({
                data: temp,
                tempData: res['score'],
                status: res['status'],
                lastStatus
            });
        })
    }

    componentDidMount() {
        this.getData()
        this.interval = setInterval(() => {
            this.getData()
        }, 30000);
    }
    componentWillUnmount() {
        if (this.interval) clearInterval(this.interval);
    }

    representativeTemplate = (data, props) => {
        // let hightline = data['strategy'] == this.state.lastStatus
        if (props.field == "index") {

            return (
                <span >{props.rowIndex + 1}</span>
            )
        }
        if (props.field == "strategy") {

            return (
                <b >{data[props.field]}</b>
            )
        }
        if (props.field == "win_rate") {

            return (
                <span >{data[props.field].toFixed(3)} %</span>
            )
        }
        if (props.field == "profit") {

            return (
                <span >{data[props.field].toFixed(3)} </span>
            )
        }

    }
    rowClass(data) {
        return {
            'high-row-cus': data.strategy == this.state.lastStatus
        }
    }

    checkChange(value){
        let temp = []
        if(!value){
            this.state.data.map(item => {
                if(item['strategy'] == this.state.lastStatus ){
                    temp.push(item)
                }
            })

            this.setState({
                data : temp
            });
        }else{
            this.setState({
                data : this.state.tempData
            });
        }

        this.setState({ checked: value })
    }

    render() {
        const header = (
            <div className="table-header" >
                <div style={{ textAlign: 'center' }}>
                    <span>Win Rate</span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <div>
                        Trạng thái hiện tại : <span style={{ color: 'red', marginLeft: '5px' }}>{this.state.status}</span>
                    </div>

                    <div className="field-checkbox" style={{ marginBottom: '0' }}>
                        <Checkbox inputId="binary" checked={this.state.checked} onChange={e => this.checkChange(e.checked)} />
                        <label htmlFor="binary">Check Action</label>
                    </div>
                </div>


            </div>
        );

        return (
            <div className='mt-2'>
                <div className="card">
                    <DataTable
                        className="p-datatable-customers"
                        value={this.state.data}
                        stripedRows
                        paginator responsiveLayout="scroll"
                        paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rows={10} rowsPerPageOptions={[10, 20, 50]}
                        header={header}
                        rowClassName={(data) => this.rowClass(data)}
                    >
                        <Column field="index" header="Index" body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="strategy" header="Strategy" body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="type" header="Type"  ></Column>
                        <Column field="win_rate" header="Win Rate" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="profit" header="Profit" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="trade" header="Trade" sortable ></Column>
                        <Column field="match_rate" header="Match Rate" sortable ></Column>
                        <Column field="suggest_rate" header="Suggest Rate" sortable ></Column>

                    </DataTable>
                </div>
            </div>
        );

    }
}

export default BusdScoreTable;