import React, { Component } from "react";
import ModelDashboardV2 from "../../models/ModelDashboardV2";
import moment from "moment";
class BidAskChart extends Component {

    constructor(props) {
        super(props);
        
        this.model = new ModelDashboardV2() 
    }

    findClosestTime(inputTime, data) {
        const inputMoment = moment(inputTime, "HH:mm:ss");
        if(data.length ==0) {
            return inputTime 
        }
        let closestTime = null;
        for (let i = 0; i < data.length; i++) {
          const currTime = moment(data[i], "HH:mm:ss");
          if (currTime.isAfter(inputMoment)) {
            closestTime = data[i];
            break;
          }
        }
        if (!closestTime) {
          const lastTime = data[data.length - 1];
          const lastMoment = moment(lastTime, "HH:mm:ss");
          if (inputMoment.isAfter(lastMoment)) {
            closestTime = inputTime;
          } else {
            closestTime = data.reduce((prev, curr) => {
              const currMoment = moment(curr, "HH:mm:ss");
              const prevMoment = moment(prev, "HH:mm:ss");
              const currDiff = Math.abs(currMoment.diff(inputMoment));
              const prevDiff = Math.abs(prevMoment.diff(inputMoment));
              return currDiff < prevDiff ? curr : prev;
            });
          }
        }
        return closestTime;
      }
    
 
    renderChart(BidV, AskV, netBA) {

        let timeArrayTran = ['09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '13:30', '14:00', '14:30', '15:00']
        let timeArray = []
        timeArrayTran.map((item, idx) => {
            let tran = item + ':00'
            if (BidV['x'].indexOf(tran) > -1) {
                timeArray[idx] =tran
            } else {
                let a = this.findClosestTime(tran, BidV['x'])
                timeArray[idx] = a
            }
        })
    
        // let tickText = []
        // let tickValue = []

        // BidV['x'].map((t, idx) => {
        //     if (idx % 720 == 0) {
        //         tickText.push(t)
        //         t = t.slice(0, 5)
        //         tickValue.push(t)
        //     }
        // })
        var BidVData = {
            x: BidV.x,
            y: BidV.y,
            type: 'scatter',
            name: 'BidV',
            xaxis: 'x',
            yaxis: 'y2',
            line: {
                color: '#3cdd96',
            }
        };
        var AskVData = {
            x: AskV.x,
            y: AskV.y,
            type: 'scatter',
            name: 'AskV',
            xaxis: 'x',
            yaxis: 'y2',
            line: {
                color: '#ff5858',
            }
        };
        var netBAData = {
            x: netBA.x,
            y: netBA.y,
            type: 'scatter',
            name: 'NetBA',
            xaxis: 'x',
            yaxis: 'y2',
            line: {
                color: '#ffa758',
            }
        };

        var dataChart = [BidVData ,AskVData, netBAData];

        var layout = {
            title: "F1 Bid Ask",
            height: 300,
            // width : '100%',
            showlegend: true,
            margin: { b: 40, t: 40, left: 0, pad: 10 },
            autosize: true,
            hovermode: "x unified",
            hoverdistance: 10,
            barmode: "group",
            font: {
                size: 12,
            },
            xaxis: {
                autorange: true,
                type: "category",
                categoryorder: "category ascending",
                rangeslider: { visible: false },
                showspikes: true,
                spikemode: "across",
                spikesnap: "cursor",
                spikedash: "dot",
                spikethickness: 2,
                tickmode: "array",
                tickvals: timeArray,
                ticktext: timeArrayTran,

                tickangle: "auto",
                showexponent: "all",
            },
            yaxis: {
                autorange: true,
                showspikes: true,
                spikemode: "across",
                spikesnap: "cursor",
                spikedash: "dot",
                spikethickness: 2,
            },
            yaxis2: {
                overlaying: "y",
                side: "right",
            },
            legend: {
                x: 0,
                // xanchor: 'right',
                y: 1.1,
                "orientation": "h"
            }
        };
        var config = { responsive: true };
        global.Plotly.newPlot(this.chart, dataChart, layout, config);
    }


    asynData(loading) {
        this.model.get_data_bid_ask(loading).then(res => {
            res = JSON.parse(res)
         
            let BidV = {
                'x': [],
                'y': [],
            }
            let AskV = {
                'x': [],
                'y': [],
            
            }
            let netBA = {
                'x': [],
                'y': [],
                
            }

            res['time'].map((item, idx) => {
                if (!this.isTimeTrue(item)) {
                    item = item.slice(0, 5)
                    BidV['x'].push(item)
                    BidV['y'].push(res['SMA_BidV'][idx])
                    AskV['x'].push(item)
                    AskV['y'].push(res['SMA_AskV'][idx])
                    netBA['x'].push(item)
                    netBA['y'].push(res['Net_BA'][idx])
                }

            })
            this.renderChart(BidV, AskV , netBA) 
        })
    }


    loadData(BidV, AskV, netBA){
        this.renderChart(BidV, AskV , netBA) 
    }

    updateXAxis(data){
        let timeArrayTran = ['09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '13:30', '14:00', '14:30', '15:00']
        let timeArray = []
        timeArrayTran.map((item, idx) => {
            let tran = item + ':00'
            if (data.indexOf(tran) > -1) {
                timeArray[idx] =tran
            } else {
                let a = this.findClosestTime(tran, data)
                timeArray[idx] = a
            }
        })
        let layout = this.chart.layout
        layout.xaxis.ticktext =  timeArrayTran
        layout.xaxis.tickvals = timeArray
        global.Plotly.relayout(this.chart, layout);

    }



    componentDidMount() {
        // // this.setData();
        // this.asynData(true)
        // this.interval = setInterval(() => {
        //     this.asynData(false)
        // }, 15000);

    
    }
    // componentWillUnmount() {
    //     if (this.interval) clearInterval(this.interval);
    // }
    render() {
        return (
            <div style={{ width: "100%" , marginLeft : -5}}>
                <div ref={(c) => (this.chart = c)}></div>
            </div>
        );
    }
}

export default BidAskChart;
