import axios from "axios";
import { cmc3_api_gateway } from "../config/config";
import { checkLogin } from "../helpers/default";

class ModelBusdScore {

    constructor() {
        this.url = cmc3_api_gateway
    }

    getData() {

        return axios.request({
            url: `${this.url}/busd/get_status_position/`,
            method: "POST",
            withCredentials: true,
        })

            .then(response => {
                response = response['data'];
                return response

            })

            .catch(error => {
                checkLogin(error)
                return false;
            })

    }

    getMapping() {

        return axios.request({
            url: `${this.url}/busd/get_strategies/`,
            method: "GET",
            withCredentials: true,
        })

            .then(response => {
                response = response['data'];
                if (response['result']) {
                    return response['data']
                } else {
                    console.log(response['message']);
                }
                return {}

            })

            .catch((error) => {
                checkLogin(error)
                return false;
            })

    }

    getWinRate() {

        return axios.request({
            url: `${this.url}/busd/get_winrate/`,
            method: "GET",
            withCredentials: true,
        })

            .then(response => {
                response = response['data'];
                if (response['result']) {
                    return response['data']
                } else {
                    console.log(response['message']);
                }
                return {}

            })

            .catch((error) => {
                checkLogin(error)
                return false;
            })

    }










}

export default ModelBusdScore;