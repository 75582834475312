import React, { Component } from 'react';
import Chart3T from '../components/Tn/Chart3T';
import Table3T from '../components/Tn/Table3T';
import Model3T from '../models/Model3T';
class TnView extends Component {
    constructor(props) {
        super(props);
        this.model = new Model3T()
    }

    componentDidMount(){
        // this.model.getData().then(res => console.log(res))
    }
    
    render() {
        return (
            <div>
                
                <Chart3T></Chart3T>
                <Table3T></Table3T>
            </div>
        );
    }
}

export default TnView;