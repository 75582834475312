import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import moment from 'moment'

import './index.scss'
import ModelFAScore from '../../models/ModelFAScore';
class EditFunc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editDialog: false,
            data: {},
            title: ''
        }
        this.model = new ModelFAScore()
    }
    //set data when open dialog edit
    openDialogEdit(data) {
        if (data == null) {
            this.setState({
                editDialog: !this.state.editDialog,
                data: {
                    "time": new Date(),
                    // "weight": moment().format('X'),
                    "weight": 1,
                    "score": 1,
                    "statistical": "",
                    "note": "",
                },
                title: 'Add'
            });
        } else {
            if(data['time'] == ''){
                data['time'] = null

            }else{
                data['time'] = new Date(Number(data['time']) * 1000)

            }
            this.setState({
                editDialog: !this.state.editDialog,
                data: { ...data },
                title: 'Edit'
            });
        }

    }




    //render button Cancle and Save
    footerEditDialog() {
        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { this.setState({ editDialog: false }); }} />
                <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={() => this.onSave()} />
            </div>
        )
    }

    //setstate data when input change
    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let data = { ...this.state.data };
        data[`${name}`] = val;

        this.setState({ data },() => {console.log(this.state.data)});
    }

    //call api save
    onSave() {

        let params = { ...this.state.data }
        if(params['time'] != ''){
            params['time'] = moment(params['time']).format('X')
        }
        params['weight'] = Number(params['weight'])

        if (this.state.title == 'Add') {


            this.model.add({ 'data': params }).then(res => {

                if (res['result']) {
                    this.props.table.getData()
                }
            }
            )


        } else {
            this.model.edit({ 'data': params }).then(res => {

                if (res['result']) {
                    this.props.table.getData()
                }
            }
            )
        }

        this.setState({
            editDialog: !this.state.editDialog,
        });

    }
    render() {
        return (
            <Dialog visible={this.state.editDialog} onHide={() => { this.setState({ editDialog: false }); }} header={this.state.title} style={{ width: '50vw' }} footer={() => this.footerEditDialog()}>
                <div className='grid'>

                    <div className='col-12'>
                        <div className="field_cus">
                            <label htmlFor="time">Name</label>
                            <InputText id="weight" value={this.state.data.name} onChange={(e) => this.onInputChange(e, 'name')} autoFocus />

                        </div>

                    </div>

                    <div className='col-6'>
                        <div className="field_cus">
                            <label htmlFor="time">Time</label>
                            <Calendar id="time" value={this.state.data.time} onChange={(e) => this.onInputChange(e, 'time')} showTime showSeconds />

                        </div>
                    </div>

                    <div className='col-6'>
                        <div className="field_cus">
                            <label htmlFor="weight">Weight</label>
                            <InputText id="weight" value={this.state.data.weight} onChange={(e) => this.onInputChange(e, 'weight')}  />

                        </div>

                    </div>

                    <div className='col-6'>
                        <div className="field_cus">
                            <label htmlFor="score">Score</label>
                            <InputText id="score" value={this.state.data.score} onChange={(e) => this.onInputChange(e, 'score')}  />

                        </div>
                    </div>


                    <div className='col-6'>
                        <div className="field_cus">
                            <label htmlFor="statiscal">Statistical</label>
                            <InputText id="statiscal" value={this.state.data.statistical} onChange={(e) => this.onInputChange(e, 'statistical')} />

                        </div>
                    </div>


                    <div className='col-12'>
                        <div className="field_cus">
                            <label htmlFor="note">Note</label>
                            <InputTextarea id="note" value={this.state.data.note} onChange={(e) => this.onInputChange(e, 'note')} rows={5} cols={20} />

                        </div>

                    </div>
                </div>







            </Dialog>
        );
    }
}

export default EditFunc;