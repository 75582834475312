import React, { Component } from 'react'
import ChartFelxModel from '../../models/ChartFelxModel';
import ChartConfig from './ChartConfig';
import Loading from '../common/Loading'
import ChartClone from './ChartClone';
import Ctrl from '../../models/ModelCtrl';
import { get, makeId, showLog } from '../../helpers/default';
import moment from 'moment';
import { Toast } from 'primereact/toast';
import { parsed } from '../../helpers/params';

class ChartFlexItem extends Component {

    constructor(props) {
        super(props);
        this.params = parsed()
        this.id = 'plot' + makeId()
        this.ctrl = new Ctrl()
        this.parent = this.props.parent
        this.storageId = 'chartflex_' + this.props.id + '_' + this.props.parent.getAccount()
        var chartConfig = localStorage.getItem(this.storageId)
        if (chartConfig == null) {
            chartConfig = []
        } else {
            chartConfig = JSON.parse(chartConfig)
        }
        this.setChartConfig(chartConfig)

        this.config = {
            data: [],
            layout: {
                height: get(this.chartConfig['height'], 300),
                title: get(this.chartConfig['title'], null),
                autosize: true,
                hovermode: "x unified",
                hoverdistance: 10,
                margin: { b: 40, t: 40, l: 40, r: 40 },
                xaxis: {
                    autorange: true,
                    type: "category",
                    categoryorder: 'category ascending',
                    rangeslider: { 'visible': false },
                    showspikes: true,
                    spikemode: 'across',
                    spikesnap: 'cursor',
                    spikedash: 'dot',
                    spikecolor: 'black',
                    spikethickness: 1,
                    // tickangle: 0,
                },
                yaxis: {
                    autorange: true,
                    showspikes: true,
                    spikemode: 'across',
                    spikesnap: 'cursor',
                    spikedash: 'dot',
                    spikecolor: 'black',
                    spikethickness: 1
                },
                barmode: 'group',
                font: {
                    size: 10,
                },

                shapes: [
                    {
                        type: 'line',
                        xref: 'x',
                        yref: 'paper',
                        x0: 0,
                        y0: 0,
                        x1: 0,
                        y1: 0,
                        line: {
                            color: 'black',
                            width: 1,
                            dash: 'dot'
                        }
                    }
                ],

                legend: {
                    yanchor: "top",
                    y: 1,
                    xanchor: "left",
                    x: 0

                }
            }


        }

        this.model = this.props.model



    }

    setChartConfig(chartConfig) {
        this.chartConfig = chartConfig
        var configs = get(this.chartConfig['configs'], [])
        var timeframe = null
        for (let conf of configs) {
            var temp = get(conf['timeframe'], 1)
            if (temp == '') temp = 1
            if (timeframe === null) {
                timeframe = temp
            } else {
                if (timeframe > temp) timeframe = temp
            }
        }
        this.minTimeFrame = timeframe;

        if (this.props.isPosition && this.params.symbol) {
            this.chartConfig['symbol'] = this.params.symbol
            this.chartConfig['title'] = this.params.symbol
        }
    }

    updateLayout() {
        let currentLayout = this.plot.layout
        currentLayout['title'] = { 'text': get(this.chartConfig['title'], null) }
        currentLayout['height'] = get(this.chartConfig['height'], 300)
        global.Plotly.relayout(this.plot, currentLayout)
    }

    getData() {
        const { startTime, stopTime } = this.props.parent.getTimeRange()
        if (startTime == '' || stopTime == '') return;
        if (this.chartConfig.length == 0) return;
        this.loading.loading(true, 'Get Data...')
        this.model.getData(this.chartConfig, startTime, stopTime).then(res => {
            this.loading.loading(false)
            if (res) {
                this.datas = res;
                this.updateDataTime = moment().format('x')
                // console.log(this.datas)
                this.drawChartData(res)

                if (this.updateDataInterval) clearInterval(this.updateDataInterval)
                this.updateDataInterval = setInterval(() => {
                    this.updateData()
                }, 30000);
            }
        })
    }

    getLastChartTime() {
        var lastChartTime = null
        for (let key in this.datas) {
            let data = this.datas[key]
            if (data.length > 0) {
                let lasttime = Number(data[data.length - 1]['timestamp'])
                if (lastChartTime === null) {
                    lastChartTime = lasttime
                } else {
                    if (lastChartTime > lasttime) {
                        lastChartTime = lasttime
                    }
                }
            }

        }
        return lastChartTime
    }

    checkWorkingTime() {
        let date = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Ho_Chi_Minh" }))
        let weekday = date.getDay()
        if (weekday == 0 || weekday == 6) return false
        let hour = date.getHours()
        if (hour < 9 || hour >= 16) return false
        return true;
    }

    updateData() {
        if (!this.checkWorkingTime()) return;
        const { startTime, stopTime } = this.props.parent.getTimeRange()
        const currentTime = Number(moment().format('X'))
        if (stopTime < currentTime) return;
        const updateTime = this.getLastChartTime()
        if (updateTime == null) return;
        if (this.props.isPosition) this.getPosition()
        this.model.getData(this.chartConfig, updateTime, stopTime).then(res => {
            if (res) {
                let currentTime = Number(moment().format('x'))
                if (currentTime < this.updateDataTime) return

                for (let key in this.datas) {
                    let data = this.datas[key]
                    let lasttime = Number(data[data.length - 1]['timestamp'])
                    if (res[key]) {
                        for (let newDt of res[key]) {
                            if (Number(newDt['timestamp']) > lasttime) {
                                data.push(newDt)
                            } else if (Number(newDt['timestamp']) == lasttime) {
                                data.pop()
                                data.push(newDt)
                            }
                        }
                    }
                }

                // console.log(this.datas)

                var chartData = this.createChartData(this.datas)
                this.plot.data = chartData
                global.Plotly.redraw(this.plot)


            }
        })
    }


    async updateAllData() {
        if (this.props.isPosition) {
            await this.getPosition()
        }
        this.getData()
    }

    createChartData(datas) {
        let chartData = []
        let configIndex = {}
        for (let index in this.chartConfig['configs']) {
            let conf = this.chartConfig['configs'][index]
            let type = get(conf['type'], 'line')
            let id = conf['source_data'] + "_" + conf['name'];

            if (!configIndex[id]) configIndex[id] = []
            configIndex[id].push(index)

            if (type == 'line') {
                chartData[index] = { name: conf['name'], mode: 'lines', x: [], y: [], line: { color: conf['color_1'], dash: get(conf['dash'], 'solid') } }
            } else if (type == 'bar') {
                chartData[index] = { name: conf['name'], type: 'bar', x: [], y: [], marker: { color: conf['color_1'] } }
            } else if (type == 'bar_histogram') {
                chartData[index] = { name: conf['name'], type: 'bar', x: [], y: [], marker: { color: [] } }
            } else if (type == 'candlestick') {
                chartData[index] = {
                    name: conf['name'], type: 'candlestick',
                    x: [], close: [], high: [],
                    low: [], open: [],
                    increasing: { line: { color: conf['color_3'], width: 1 }, fillcolor: conf['color_4'] },
                    decreasing: { line: { color: conf['color_1'], width: 1 }, fillcolor: conf['color_2'] },
                }
            }
        }

        let periodRow = null
        for (let coll in datas) {
            let rows = datas[coll]
            for (let row of rows) {
                let timestamp = moment(row['timestamp'], 'X').format()
                for (let field in row) {
                    let id = coll + "_" + field
                    if (configIndex[id]) {
                        for (let index of configIndex[id]) {
                            chartData[index]['x'].push(timestamp)
                            let conf = this.chartConfig['configs'][index]
                            let type = get(conf['type'], 'line')
                            if (type == 'line') {
                                chartData[index]['y'].push(row[field])
                            } else if (type == 'bar') {
                                chartData[index]['y'].push(row[field])
                            } else if (type == 'bar_histogram') {
                                chartData[index]['y'].push(row[field])
                                if (periodRow === null) {
                                    if (row[field] >= 0) chartData[index]['marker']['color'].push(conf['color_3'])
                                    else chartData[index]['marker']['color'].push(conf['color_1'])
                                } else {
                                    if (row[field] >= 0 && row[field] >= periodRow[field]) chartData[index]['marker']['color'].push(conf['color_3'])
                                    else if (row[field] >= 0 && row[field] < periodRow[field]) chartData[index]['marker']['color'].push(conf['color_4'])
                                    else if (row[field] < 0 && row[field] >= periodRow[field]) chartData[index]['marker']['color'].push(conf['color_2'])
                                    else if (row[field] < 0 && row[field] < periodRow[field]) chartData[index]['marker']['color'].push(conf['color_1'])
                                }
                            } else if (type == 'candlestick') {
                                chartData[index]['close'].push(row['close'])
                                chartData[index]['open'].push(row['open'])
                                chartData[index]['low'].push(row['low'])
                                chartData[index]['high'].push(row['high'])
                            }
                        }
                    }
                }

                periodRow = row
            }
        }
        //update to chart
        if (this.orderData) chartData.push(this.orderData)
        if (this.positionOpenData) chartData.push(this.positionOpenData)
        if (this.positionCloseData) chartData.push(this.positionCloseData)


        return chartData
    }


    drawChartData(datas) {
        var chartData = this.createChartData(datas)
        if (chartData[0]['x'].length > 100) {
            
            let tickText = []
            let timeFarme = parseInt(chartData[0]['x'].length / 242) * 30
            chartData[0]['x'].map((item, index) => {
                if (index % timeFarme == 0) {
                    tickText.push(item)
                }
            })

            this.config.layout.xaxis.tickmode = "array"
            this.config.layout.xaxis.tickvals =tickText
            this.config.layout.xaxis.ticktext =tickText

        
        }

        global.Plotly.newPlot(this.plot, chartData, this.config.layout).then(() => { this.syncChart() })

        // for(let id in chartData){
        //     let data = chartData[id]
        //     global.Plotly.restyle(this.plot, data, [id])
        // }

    }


    getPosition() {
        const { startTime, stopTime } = this.props.parent.getTimeRange()
        if (startTime == '' || stopTime == '') return Promise.resolve();
        var account = this.props.parent.getAccount()
        if (account == '') return Promise.resolve();
        var symbol = this.chartConfig['symbol'];
        if (!symbol) return Promise.resolve();
        return this.model.getPosition(account, symbol, startTime * 1000, stopTime * 1000).then(res => {
            if (res) {
                return this.preparePositionChartData(res)
            }
        })

    }

    preparePositionChartData(data) {
        
        const { startTime, stopTime } = this.props.parent.getTimeRange()

        let symbol = {
            'LO': 'star-triangle-up',
            'LC': 'x',
            'SO': 'star-triangle-down',
            'SC': 'diamond',
        }
        this.positionOpenData = {
            x: [],
            y: [],
            visible: 'legendonly',
            name: "Position Open",
            // marker:{color:"blue", size:8}, 
            marker: { color: [], size: 8, symbol: [] },
            mode: "markers+text",
            text: [],
            textposition: "top center",
            textfont: {
                // color:"blue",
                color: [],
            },
            type: 'scatter'
        }

        this.positionCloseData = {
            x: [],
            y: [],
            visible: 'legendonly',
            name: "Position Close",
            // marker:{color:"orangered", size:8}, 
            marker: { color: [], size: 8, symbol: [] },
            mode: "markers+text",
            text: [],
            textposition: "top center",
            textfont: {
                // color:"orangered",
                color: [],
            },
            type: 'scatter'
        }

        this.orderData = {
            x: [],
            y: [],
            name: "Orders",
            marker: { color: [], size: 8, symbol: 'square' },
            mode: "markers+text",
            text: [],
            textposition: "top center",
            textfont: {
                color: "green",
            },
            type: 'scatter'
        }
        for (let position of data['position']) {
            if(!position['sell_time']) continue
            var timestampStart = moment(position['start_time'], 'x').format()
            this.positionOpenData.x.push(timestampStart)
            this.positionOpenData.y.push(position['start_price'])
            this.positionOpenData.text.push(position['type'] == 1 ? 'B' : 'S')

            this.positionOpenData.textfont.color.push(position['type'] == 1 ? 'limegreen' : 'red')
            this.positionOpenData.marker.color.push(position['type'] == 1 ? 'limegreen' : 'red')
            this.positionOpenData.marker.symbol.push(position['type'] == 1 ? symbol['LO'] : symbol['SO'])

            if (position['sell_time'] > 0 && position['sell_time'] <= stopTime * 1000) {
                var timestampStop = moment(position['sell_time'], 'x').format()
                this.positionCloseData.x.push(timestampStop)
                this.positionCloseData.y.push(position['sell_price'])
                this.positionCloseData.text.push(position['pnl'] > 0 ? 'TP' : 'SL')

                this.positionCloseData.textfont.color.push(position['pnl'] > 0 ? 'limegreen' : 'red')
                this.positionCloseData.marker.color.push(position['pnl'] > 0 ? 'limegreen' : 'red')
                this.positionCloseData.marker.symbol.push(position['type'] == 1 ? symbol['SC'] : symbol['LC'])
            }
        }

        for (let order of data['orders']) {
            var timestampStart = moment(order['order_time'], 'x').format()
            this.orderData.x.push(timestampStart)
            this.orderData.y.push(order['order_price'])
            this.orderData.text.push("<b>" + (order['order_type'] == 1 ? 'B' : 'S') + '' + order['order_phase'] + "</b>")
            this.orderData.marker.color.push(order['order_type'] == 1 ? 'green' : 'red')
        }

        // console.log(this.positionOpenData)


    }


    syncChart() {
        if (this.props.sync) {
            this.props.sync.register(this.id, this)
            //sync hover
            this.plot.on('plotly_hover', (data) => {
                // this.props.sync.updateHover(this.id, data.xvals[0])
                this.props.sync.updateHover(this.id, data.points[0].x)
            })

            this.plot.on('plotly_relayout', (data) => {
                this.props.sync.updateScale(this.id, data)
            })
        }


    }

    render() {
        return <div className='box_padding box_shadow' ref={c => this.chartContainer = c} style={{ position: 'relative', margin: 2, minHeight: 50, marginBottom: 10 }}>
            <div className='flex' style={{ position: 'absolute', zIndex: 1 }} >
                <div className='button' onClick={() => {
                    this.chartConfigCom.modal()
                    this.chartConfigCom.setConfig(this.chartConfig)
                }}><i className="pi pi-cog"></i>
                </div>
                &nbsp;
                <div className='button' onClick={() => {
                    this.updateAllData()
                }}><i className="pi pi-refresh"></i>
                </div>
                &nbsp;
                <div className='button' onClick={() => {
                    this.chartCloneCom.modal()
                    this.chartCloneCom.setConfig(this.chartConfig)
                }}><i className="pi pi-clone"></i>
                </div>
                &nbsp;
                <div className='button' onClick={() => {
                    this.chartConfig.hidden = !this.chartConfig.hidden
                    this.forceUpdate()
                }}><i className="pi pi-window-restore"></i>
                </div>
            </div>
            <div style={{ display: (this.chartConfig.hidden ? 'none' : 'block') }} ref={c => this.plot = c} id={this.id} />
            <ChartConfig model={this.model} ref={c => this.chartConfigCom = c} onApply={(config) => {
                this.setChartConfig(config)
                this.updateLayout()
                this.updateAllData()
                localStorage.setItem(this.storageId, JSON.stringify(config))
                this.chartConfigCom.modal('hide')
            }}
                onLoadDefault={() => {
                    this.ctrl.get(this.storageId).then(res => {
                        if (res) {
                            res = JSON.parse(res)
                            if (res) {
                                this.chartConfigCom.setConfig(res)
                                showLog('Load default config successfully', 'success')
                            }
                        }
                    })
                }}
                onSetDefault={(config) => {
                    this.ctrl.set({ [this.storageId]: JSON.stringify(config) }).then(res => {
                        if (res) {
                            showLog('Configuration is set as Default', 'success')
                        }
                    })
                }}
            ></ChartConfig>
            <ChartClone model={this.model} ref={c => this.chartCloneCom = c} onApply={(config) => {
                this.setChartConfig(config)
                this.updateLayout()
                this.updateAllData()
                localStorage.setItem(this.storageId, JSON.stringify(config))
                this.chartCloneCom.modal('hide')
            }} onSetDefault={(config) => {
                this.ctrl.set({ [this.storageId]: JSON.stringify(config) }).then(res => {
                    if (res) {
                        showLog('Configuration is set as Default', 'success')
                    }
                })
            }}></ChartClone>
            <Loading style={{ position: 'absolute' }} ref={c => this.loading = c}></Loading>
        </div>

    }

    async componentDidMount() {
        global.Plotly.newPlot(this.plot, this.config.data, this.config.layout)
        this.resize_ob = new ResizeObserver((entries) => {
            if (this.resizeTimeOut) clearTimeout(this.resizeTimeOut)
            this.resizeTimeOut = setTimeout(() => {
                console.log('Resize')
                if (!this.chartConfig.hidden)
                    global.Plotly.Plots.resize(this.plot)
            })
        });
        this.resize_ob.observe(this.chartContainer);

        if (get(this.chartConfig['configs'], []).length == 0) {

            this.ctrl.get(this.storageId).then(res => {

                if (res) {
                    res = JSON.parse(res)
                    if (res) {
                        this.setChartConfig(res)
                        this.updateAllData()
                    }

                }
            })
        }

        await this.updateAllData()
    }

    componentWillUnmount() {
        if (this.props.sync) this.props.sync.unregister(this.id)
        if (this.resizeTimeOut) clearTimeout(this.resizeTimeOut)
        if (this.resize_ob) this.resize_ob.unobserve(this.chartContainer);
        if (this.updateDataInterval) clearInterval(this.updateDataInterval)
    }

    hover(xval) {

        if (xval === null) {
            global.Plotly.relayout(this.plot, {
                shapes: [
                    { visible: false }
                ]
            })
        } else {
            if (this.plot.data.length == 0) return;
            var poins = []
            var x = Math.floor(moment(xval).valueOf() / (this.minTimeFrame * 60000)) * (this.minTimeFrame * 60000)
            x = moment(x).format()

            var index = this.plot.data[0]['x'].indexOf(x)
            if (index != -1) {
                // if(this.orderData) index = index * this.minTimeFrame
                for (let curve in this.plot.data) {
                    poins.push({ curveNumber: curve, xval: index })
                }
                global.Plotly.Fx.hover(this.id, poins);
                global.Plotly.relayout(this.plot, {
                    shapes: [
                        {
                            type: 'line', xref: 'x', yref: 'paper',
                            x0: index, y0: 0, x1: index, y1: 1,
                            line: {
                                color: 'black',
                                width: 1,
                                dash: 'dot'
                            }
                        }
                    ]
                })
            }

        }

    }
}

export default ChartFlexItem