import React, { Component } from 'react';

import TableWeight from '../components/Weight/TableWeight';

class WeightView extends Component {

    

    render() {
        return(
            <TableWeight></TableWeight>

        )
         
    }
}

export default WeightView;