// import Lab_account from "./Lab_account";
import Watchlist from "./Watchlist";
import Testnet_account from "./Testnet_account"
// import Exchange_account from "./Accounts"

import { get, loading } from "../helpers/default";
import axios from 'axios'
import { lv3_api_gateway } from "../config/config";
import { parsed } from "../helpers/params";
import { error_handle } from "../helpers/error";

class ChartFelxModel{
    constructor(){
        this.getChartFieldResult = {}
        this.params = parsed()
    }

    getDatabase(){
        if(this.getDatabaseResult) return this.getDatabaseResult
        loading(true, 'Loading...');
		this.getDatabaseResult = axios.request({
			url: lv3_api_gateway + '/chartflex/get_database',
			method: 'POST',
            withCredentials: true,
		}) 

			.then(response => {
				loading(false, 'Loading...');
				response = response['data'];
                if(response['result']){
                    response = response['data']
                    var options = [{'value':'', 'label': '-- Select Database --'}]
                    for(let db in response){
                        options.push({
                            'value': db,
                            'label': response[db]
                        })
                    }
                    return options
                }else{
                    error_handle(response)
                }
				
			})

			.catch((error) => {
				console.log(error);
				loading(false, 'Loading...');
				error_handle(error)
				return false;
			})

        return this.getDatabaseResult
    }

    getSourceOptions(database){
        if(this.getSourceOptionsResult) return this.getSourceOptionsResult
        loading(true, 'Loading...');
		this.getSourceOptionsResult = axios.request({
			url: lv3_api_gateway + '/chartflex/get_chart_source',
			method: 'POST',
            data: {
                database
            },
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true,
		}) 

			.then(response => {
				loading(false, 'Loading...');
				response = response['data'];
                if(response['result']){
                    response = response['data']
                    var options = [{'value':'', 'label': '-- Select Source --'}]
                    for(let db of response){
                        options.push({
                            'value': db,
                            'label': db.toUpperCase()
                        })
                    }
                    return options
                }else{
                    error_handle(response)
                }
				
			})

			.catch((error) => {
				console.log(error);
				loading(false, 'Loading...');
				error_handle(error)
				return false;
			})

        return   this.getSourceOptionsResult

    }


    getChartField(database, source){
        if(!source) return Promise.resolve([])
        if(this.getChartFieldResult[source]) return this.getChartFieldResult[source]
        loading(true, 'Loading...');
		this.getChartFieldResult[source] = axios.request({
			url: lv3_api_gateway + '/chartflex/get_chart_field',
			method: 'POST',
            data:{
                database: database,
                source: source
            },
            withCredentials: true,
		}) 

			.then(response => {
				loading(false, 'Loading...');
				response = response['data'];
                if(response['result']){
                    response = response['data']
                    var options = [{'value':'', 'label': '-- Select Field --'}]
                    for(let db of response){
                        options.push({
                            'value': db,
                            'label': db.toUpperCase()
                        })
                    }
                    return options
                }else{
                    error_handle(response)
                }
				
			})

			.catch((error) => {
				console.log(error);
				loading(false, 'Loading...');
				error_handle(error)
				return false;
			})

        return this.getChartFieldResult[source]

    }

    getWatchlist(){
        if(this.getWatchlistResult) return this.getWatchlistResult;
        loading(true);
        this.wlModel = new Watchlist()
       
        this.getWatchlistResult = this.wlModel.get().then(res => {
            if(res){
                if(res['result']){
                    var data = res['data'].sort((a,b)=>a['wl_symbol']>b['wl_symbol']?1:-1);
                    var options = [{'value':'', 'label': '-- Select Symbol --'}]
                    data.map(item => {
                        return options.push({
                            'value': item['wl_symbol'],
                            'label': item['wl_symbol']
                        })
                    })

                    return options;
                }
            }
        })
        
        return this.getWatchlistResult
    }


    getAccounts(){
        loading(true);

        this.model = new Testnet_account()
        let colName = 'testnet_account_name'
        let colID =  'testnet_account_id'
      
        return this.model.get().then(res => {
            if(res){
                if(res['result']){
                    
                    var data = res['data'].sort((a,b)=>a[colName]>b[colName]?1:-1);
                   
                    var options = [{'value':'', 'label': '-- Select Account --'}]
                    data.map(item => {
                        return options.push({
                            'value': item[colID],
                            'label': item[colName]
                        })
                    })
                    return options;
                }
            }
        })
    }


    getData(config, startTime, stopTime){
        
		return axios.request({
			url: lv3_api_gateway + '/chartflex/get_data',
			method: 'POST',
            data:{
                configuration: config,
                start_time: startTime,
                stop_time: stopTime
            },
            withCredentials: true,
		}) 

			.then(response => {
				response = response['data'];
                if(response['result']){
                    response = response['data']
                    return response
                }else{
                    error_handle(response)
                }
				
			})

			.catch((error) => {
				console.log(error);
				error_handle(error)
				return false;
			})
    }


    getPosition(account , symbol, startTime, stopTime , mode){
        
		return axios.request({
			url: lv3_api_gateway + '/chartflex/get_position',
			method: 'POST',
            data:{
                account,
                symbol,
                startTime,
                stopTime,
                mode,
            },
            withCredentials: true,
		}) 

			.then(response => {
				response = response['data'];
                if(response['result']){
                    let postions = get(response['data']['position'], [])
                    let orders = get(response['data']['orders'], [])
                    let data = {'position': [], 'orders': []}
                    for(let dt of postions){
                        data['position'].push({
                            'start_time': dt['testnet_result_chart'],
                            'start_price': dt['testnet_result_chart_price'],
                            'type': dt['testnet_result_type'],
                            'sell_time': dt['testnet_result_sell_time'],
                            'sell_price': dt['testnet_result_sell_price'],
                            'pnl': dt['testnet_result_realpnl'],
                        })
                    }
                    for(let dt of orders){
                        data['orders'].push({
                            'order_time': dt['testnet_order_time'],
                            'order_type': dt['testnet_order_type'],
                            'order_price': dt['testnet_order_price'],
                            'order_phase': dt['testnet_order_phase'],
                        })
                    }
                    return data
                }else{
                    error_handle(response)
                }
				
			})

			.catch((error) => {
				console.log(error);
				error_handle(error)
				return false;
			})
    }



    
}

export default ChartFelxModel;