import React, { Component } from "react";
import BUSDChartLine from "./BUSDChartLine";

import { TabView, TabPanel } from "primereact/tabview";
import ModelDashboardV2 from "../../models/ModelDashboardV2";
import moment from "moment";

import { checkWorkingTime, showLog } from '../../helpers/default';
import ForeignChart from "./ForeignComponent/ForeignChart";
import ForeignScore from "./ForeignComponent/ForeignScore";
import { Dropdown } from 'primereact/dropdown';
class ForeginCSView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 1,
            display: true,
            selCorrection: localStorage.getItem('foregin_cs_Correction') ? JSON.parse(localStorage.getItem('foregin_cs_Correction')) : { 'name': 'Nguyên Bản', 'code': 'Nguyên Bản' },
            optCorrection: [
                { 'name': 'Nguyên Bản', 'code': 'Nguyên Bản' },
                { 'name': 'Threshold', 'code': 'Threshold' },
            ],

        };

        this.model = new ModelDashboardV2()
    }
    async getData(day) {

        this.setState({
            day: day,
            lastTimestamp: moment(day, 'YYYY_MM_DD').startOf('day').add(9, 'hours').valueOf() / 1000
        }, async () => {
            let paramter = {
                "day": this.state.day,
                "lastTimestamp": this.state.lastTimestamp,
                "indicator_correction": {
                    'foregin_cs': this.state.selCorrection['code'],
                }
            }

            let data = await this.model.get_data_foreign_cs(false, paramter)


            let { FB, FS, NetForeign, Foreign_Point, lastPoint } = this.handleData(data)
            if (this.state.activeIndex == 0) {
                this.ForeignChart.loadData(FB, FS, NetForeign)
            }
            if (this.state.activeIndex == 1) {
                this.ForeignScore.loadData(Foreign_Point)
            }

            this.BUSDChartLine.setContent(lastPoint)
        })

    }
    // update realtime data price
    async updateData() {
        if (checkWorkingTime()) {

            if (moment(this.state.lastTimestamp, "X").format('YYYY_MM_DD') != moment().format('YYYY_MM_DD')) {
                return
            }

            let paramter = {
                "day": this.state.day,
                "lastTimestamp": this.state.lastTimestamp,
                "indicator_correction": {
                    'foregin_cs': this.state.selCorrection['code'],
                }
            }

            let data = await this.model.get_data_foreign_cs(false, paramter)

            if (data.length > 0) {
                var { FB, FS, NetForeign, Foreign_Point, lastPoint } = this.handleData(data)
                if (this.state.activeIndex == 0) {

                    let preData = this.ForeignChart.chart.data


                    preData[0]['x'].push(...FB['x'])
                    preData[0]['y'].push(...FB['y'])
                    preData[1]['x'].push(...FS['x'])
                    preData[1]['y'].push(...FS['y'])
                    preData[2]['x'].push(...NetForeign['x'])
                    preData[2]['y'].push(...NetForeign['y'])
                    this.ForeignChart.chart.data = preData
                    global.Plotly.redraw(this.ForeignChart.chart)

                    this.ForeignChart.updateXAxis(preData[0]['x'])
                }

                if (this.state.activeIndex == 1) {
                    let preData = this.ForeignScore.chart.data
                    preData[0]['x'].push(...Foreign_Point['x'])
                    preData[0]['y'].push(...Foreign_Point['y'])
                    this.ForeignScore.chart.data = preData
                    global.Plotly.redraw(this.ForeignScore.chart)

                    this.ForeignScore.updateXAxis(preData[0]['x'])
                }

                this.BUSDChartLine.setContent(lastPoint)


            }




        }


    }

    handleData(res) {
        let FB = {
            'x': [],
            'y': [],
        }
        let FS = {
            'x': [],
            'y': [],

        }
        let NetForeign = {
            'x': [],
            'y': [],

        }
        let Foreign_Point = {
            'x': [],
            'y': [],

        }
        let lastPoint = 0
        res.map((item, idx) => {
            let time = moment.unix(item['timestamp']).format("HH:mm:ss")
            FB['x'].push(time)
            FB['y'].push(item['FB'])
            FS['x'].push(time)
            FS['y'].push(item['FS'])
            NetForeign['x'].push(time)
            NetForeign['y'].push(item['NetForeign'])
            Foreign_Point['x'].push(time)
            Foreign_Point['y'].push(item['Foreign_Point'])
            if (res.length == idx + 1) {
                this.setState({
                    lastTimestamp: item['timestamp']
                });
                lastPoint = item['Foreign_Point']
            }



        })


        return {
            FB, FS, NetForeign, Foreign_Point, lastPoint
        }
    }
    componentDidMount() {

        if (this.props.day != '') {
            this.getData(this.props.day)

            if (Object.keys(this.props.note).length > 0) {
                this.setState({
                    note: this.props.note['Foreign_cs']
                });
            }


            if (checkWorkingTime()) {
                this.interval = setInterval(() => {
                    this.updateData()
                }, 5000);
            }
        }
    }
    componentWillUnmount() {
        if (this.interval) clearInterval(this.interval);
    }
    renderInfo() {
        return (
            <div style={{ whiteSpace: 'pre-wrap', margin: '-20px 0px 20px 20px' }}>{this.state.note}</div>
        );
    }

    render() {
        return (
            <div style={this.state.display ? { marginTop: 20 } : { marginTop: 10 }}>


                <div style={{ marginBottom: 20, cursor: 'pointer', display: 'flex', alignItems: 'center' }} >
                    <div style={{display : 'flex' , alignItems : 'center'}} onClick={() => this.setState({ display: !this.state.display })}> 
                        <span>Foregin CS Scoring</span>

                        <i style={{ marginLeft: 10, marginTop: 3 }} className={this.state.display ? "pi pi-angle-up" : "pi pi-angle-down"}></i>
                    </div>

                    <Dropdown
                        value={this.state.selCorrection}
                        options={this.state.optCorrection}
                        onChange={(e) => { 
                            localStorage.setItem("foregin_cs_Correction", JSON.stringify(e.value));
                            this.setState({
                                selCorrection: e.value,
                                lastTimestamp: moment(this.state.day, 'YYYY_MM_DD').startOf('day').add(9, 'hours').valueOf() / 1000
                            },
                                () => {
                                    if (this.interval) {
                                        clearInterval(this.interval);
                                    }
                                    
                                    this.getData(this.state.day)
                                    let ChartTotalScoreView = this.props.ChartTotalScoreView()
                                    ChartTotalScoreView.changeIndicator_correction({'foregin_cs' : this.state.selCorrection['code']})
                                    if (checkWorkingTime()) {
                                        this.interval = setInterval(() => {
                                            this.updateData()
                                        }, 5000);
                                    }
                                }
                            );
                        }}
                        optionLabel="name"
                        style={{ margin: "0px 10px" }}

                    />
                </div>

                <div
                    className="grid"
                    style={{
                        background: "white",
                        marginBottom: 20,
                        borderRadius: 8,
                        marginLeft: 1,
                        maxHeight: this.state.display ? "500px" : '0',
                        transition: 'all .5s',
                        overflow : 'hidden'
                    }}
                >
                    <div className="col-12 md:col-4">
                        <BUSDChartLine config={{ score: 4 }} ref={c => this.BUSDChartLine = c}></BUSDChartLine>
                    </div>
                    <div className="col-12 md:col-8">
                        <div style={{ marginLeft: 25 }}>
                            <TabView
                                activeIndex={this.state.activeIndex}
                                onTabChange={(e) => {
                                    this.setState({
                                        activeIndex: e.index,

                                        lastTimestamp: moment(this.state.day, 'YYYY_MM_DD').startOf('day').add(9, 'hours').valueOf() / 1000
                                    },
                                        () => {
                                            if (this.interval) {
                                                clearInterval(this.interval);
                                            }
                                            this.getData(this.state.day)
                                            if (checkWorkingTime()) {
                                                this.interval = setInterval(() => {
                                                    this.updateData()
                                                }, 5000);
                                            }
                                        }
                                    );

                                }}
                            >
                                <TabPanel header="Foreign CS"></TabPanel>
                                <TabPanel header="Foreign CS Score"></TabPanel>
                                <TabPanel header="Info"></TabPanel>
                            </TabView>
                        </div>

                        {this.state.activeIndex == 0 ? (
                            <ForeignChart foreign_cs={true} ref={c => this.ForeignChart = c}></ForeignChart>
                        ) : this.state.activeIndex == 1 ? (
                            <ForeignScore foreign_cs={true} ref={c => this.ForeignScore = c}></ForeignScore>
                        ) :
                            this.renderInfo()
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default ForeginCSView;
