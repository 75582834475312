import React, { Component } from 'react';

import LongShortChart from '../Dashboard/ChartMain/LongShortChart';
import BUSDChart_v1 from '../Dashboard/ChartMain/BUSDChart_v1';

class ParagarmBidAskDetail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            time: ''
        }
    }


    setData(data) {
        if (data == null) {
            this.LongShortChart.setNum(0, 200)
            let messShort = `Short không có tín hiệu `
            this.chart1.setContent(0, 'short', messShort)
            let messLong = `Long không có tín hiệu `
            this.chart2.setContent(0, 'long', messLong)
        } else {

            this.LongShortChart.setNum(Number(data['score'].toFixed(1)), 200)
    


            if (data['side'] == 'long') {
                let mess2 = `Tín hiệu ${data['side'].toUpperCase()}, Winrate : ${data['win_rate'].toFixed(2)} % `
                this.chart2.setContent(Number(data['win_rate'].toFixed(2)), 'long', mess2)
                let mess1 = `Short không có tín hiệu `
                this.chart1.setContent(0, 'short', mess1)

            } else {
                let mess1 = `Tín hiệu ${data['side'].toUpperCase()}, Winrate : ${data['win_rate'].toFixed(2)} % `
                this.chart1.setContent(Number(data['win_rate'].toFixed(2)), 'short', mess1)

                let mess2 = `Long không có tín hiệu `
                this.chart2.setContent(0, 'long', mess2)
            }

            let time = `${data['day']} ${data['ctime']}`
            this.setState({
                time,
            });
        }
    }
    render() {
        return (
            <div className='card' style={{ marginBottom: '0px', height: '100%' }} >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div >
                        <b>Bid/Ask</b>
                    </div>
                    <div >
                        <div >
                            <i className="pi pi-clock"></i>
                            <b className='ml-2' >{this.state.time}</b>
                        </div>
                    </div>
                </div>

                <div>
                    <LongShortChart isExtra2={true} ref={c => this.LongShortChart = c}></LongShortChart>

                </div>

                <div className='grid mt-2'>
                    <div className='col-12 ' style={{ display: 'flex' }}>

                        <BUSDChart_v1 ref={c => this.chart1 = c} title='Short'></BUSDChart_v1>
                    </div>
                    <div className='col-12' style={{ display: 'flex' }}>

                        <BUSDChart_v1 ref={c => this.chart2 = c} title='Long'></BUSDChart_v1>
                    </div>

                </div>

            </div>
        );
    }
}

export default ParagarmBidAskDetail;