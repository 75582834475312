import React, { Component } from 'react';
import Input from '../Input_v2/Input';
import { Button } from 'primereact/button';
import moment from 'moment';
import ModelPressure from '../../models/ModelPressure';
import ModelVn30 from '../../models/ModelVn30';
import { checkWorkingTime, showLog } from '../../helpers/default';
import { Dropdown } from 'primereact/dropdown';
class VolumnPS extends Component {
    constructor(props) {
        super(props);
        this.id = 'pressure'

        this.storageTimeLength = `${this.id}_timeLength`
        this.state = {
            timeLeng: localStorage.getItem(this.storageTimeLength) ? localStorage.getItem(this.storageTimeLength) : 10,
            startTime: moment().startOf('day').toDate(),

            lastTime: '',
            lastTimeVn30: '',
            delayTime: 0,
            level: { name: '10', code: '10' },

            dataVolumeTime: [],
            dataVolumeBuy: [],
            dataVolumeSell: [],
        }

        this.model = new ModelPressure()
        this.modelVn30 = new ModelVn30()

        this.levelOpt = [
            { name: '10', code: '10' },
            { name: '9', code: '9' },
            { name: '8', code: '8' },
            { name: '7', code: '7' },
            { name: '6', code: '6' },
            { name: '5', code: '5' },
            { name: '4', code: '4' },
            { name: '3', code: '3' },
            { name: '2', code: '2' },
            { name: '1', code: '1' },
        ];
    }
    componentDidMount() {
        this.getData()

        if (checkWorkingTime()) {
            this.interval = setInterval(() => {
                // this.updateData()
                this.updateDataTemp()
                this.updateDataVn30()
            }, 10000);

            this.interval2 = setInterval(() => {
                this.delayTime()
            }, 1000);



        }


    }


    // cal time delay 
    delayTime() {
        let dayTime = Number(moment().format('X')) - Number(this.state.lastTime)

        this.setState({
            delayTime: dayTime
        });
    }
    componentWillUnmount() {
        if (this.interval) clearInterval(this.interval);
        if (this.interval2) clearInterval(this.interval2);
    }

    async updateData() {


        if (checkWorkingTime()) {

            if (moment(this.state.lastTime, "X").format('YYYY_MM_DD') != moment().format('YYYY_MM_DD')) {
                return
            }

            let startTime = Number(this.state.lastTime) + 1
            let stopTime = moment().endOf('day').format('X')
            let data = await this.model.filter(startTime, stopTime)



            if (data.length > 0) {
                let chart = this.handleData(data)

                let preData = this.chart.data

                preData[0]['x'].push(...chart['buyV']['x'])
                preData[0]['y'].push(...chart['buyV']['y'])
                preData[1]['x'].push(...chart['sellV']['x'])
                preData[1]['y'].push(...chart['sellV']['y'])
                preData[2]['x'].push(...chart['netBSV']['x'])
                preData[2]['y'].push(...chart['netBSV']['y'])

                this.chart.data = preData
                global.Plotly.redraw(this.chart)
            }




        }


    }
    // update data volume10
    async updateDataTemp() {


        if (checkWorkingTime()) {

            if (moment(this.state.lastTime, "X").format('YYYY_MM_DD') != moment().format('YYYY_MM_DD')) {
                return
            }

            let startTime = Number(this.state.lastTime) + 1
            let stopTime = moment().endOf('day').format('X')

            let data = await this.model.step(startTime, stopTime)



            if (data.length > 0) {

                let dataBA = await this.handleDataTemp(data, true)

                let chart = this.calSma()

                let preData = this.chart.data

   

                preData[0]['x'].push(...dataBA['BidV']['x'])
                preData[0]['y'].push(...dataBA['BidV']['y'])
                preData[1]['x'].push(...dataBA['AskV']['x'])
                preData[1]['y'].push(...dataBA['AskV']['y'])
                preData[2]['x'].push(...dataBA['NetBA']['x'])
                preData[2]['y'].push(...dataBA['NetBA']['y'])

                preData[3]['x'] = chart['buyV']['x']
                preData[3]['y'] = chart['buyV']['y']
                preData[4]['x'] = chart['sellV']['x']
                preData[4]['y'] = chart['sellV']['y']
                preData[5]['x'] = chart['netBSV']['x']
                preData[5]['y'] = chart['netBSV']['y']

                this.chart.data = preData
                global.Plotly.redraw(this.chart)



            }




        }


    }


    // update realtime data price
    async updateDataVn30() {
        if (checkWorkingTime()) {

            if (moment(this.state.lastTime, "X").format('YYYY_MM_DD') != moment().format('YYYY_MM_DD')) {
                return
            }

            let startTime = Number(this.state.lastTimeVn30) + 1
            let stopTime = moment().endOf('day').format('X')

            let dataVn30Cs = await this.modelVn30.vn30_cs(startTime, stopTime)
            let dataVn30Ps = await this.modelVn30.vn30_ps(startTime, stopTime)

            if (dataVn30Cs.length > 0 && dataVn30Ps.length > 0) {
                let vn30 = this.handleDataVn30(dataVn30Cs, dataVn30Ps)

                let preData = this.chart.data

                preData[6]['x'].push(...vn30['cs']['x'])
                preData[6]['y'].push(...vn30['cs']['y'])
                preData[7]['x'].push(...vn30['ps']['x'])
                preData[7]['y'].push(...vn30['ps']['y'])
                preData[8]['x'].push(...vn30['net']['x'])
                preData[8]['y'].push(...vn30['net']['y'])

                this.chart.data = preData
                global.Plotly.redraw(this.chart)
            }




        }


    }


    // data volume raw push state 3 params time, totalBuy, totalSell
    async handleDataTemp(res, update = false) {

        if (res.length == 0) {
            showLog('volume 10 muc gia no data', 'error')

            return
        }

        let level = Number(this.state.level.code)
        let resultTime = []
        let resultBuy = []
        let resultSell = []

        let BidV = {
            x: [],
            y: [],
        }
        let AskV = {
            x: [],
            y: [],
        }
        let NetBA = {
            x: [],
            y: [],
        }

        for (let i = 0; i < res.length; i++) {
            const element = res[i];
            let totalBuy = 0
            let totalSell = 0
            let timestamp = element['timestamp']

            for (let y = 1; y <= level; y++) {
                totalBuy += element[`bestBid${y}Volume`]
                totalSell += element[`bestOffer${y}Volume`]

            }
            resultTime.push(timestamp)
            resultBuy.push(totalBuy)
            resultSell.push(totalSell)

            let day = moment(element['timestamp'], 'X').format('YYYY/MM/DD HH:mm:ss')
            BidV['x'].push(day)
            BidV['y'].push(element[`totalBidVolume`])
            AskV['x'].push(day)
            AskV['y'].push( element[`totalAskVolume`])
            NetBA['x'].push(day)
            NetBA['y'].push(element['totalBidVolume'] - element['totalAskVolume'])
        }
        
        if (update == true) {
            resultTime = [...this.state.dataVolumeTime, ...resultTime]
            resultBuy = [...this.state.dataVolumeBuy, ...resultBuy]
            resultSell = [...this.state.dataVolumeSell, ...resultSell]


        }

        this.setState({
            dataVolumeTime: resultTime,
            dataVolumeBuy: resultBuy,
            dataVolumeSell: resultSell,
        });

        return Promise.resolve({
            BidV,AskV,NetBA
        })


    }

    // cal sma buy,sell from state
    calSma() {

        let timeR = this.state.dataVolumeTime.slice(19)
        let smaBuy = this.simpleMovingAverage(this.state.dataVolumeBuy)
        let smaSell = this.simpleMovingAverage(this.state.dataVolumeSell)

        let buyV = {
            x: [],
            y: [],
        }
        let sellV = {
            x: [],
            y: [],
        }
        let netBSV = {
            x: [],
            y: [],
        }
        let lastTime = null
        timeR.map((item, index) => {
            let day = moment(item, 'X').format('YYYY/MM/DD HH:mm:ss')
            buyV['x'].push(day)
            buyV['y'].push(smaBuy[index])
            sellV['x'].push(day)
            sellV['y'].push(smaSell[index])
            netBSV['x'].push(day)
            netBSV['y'].push(smaBuy[index] - smaSell[index])

            lastTime = item
        })

        this.setState({
            lastTime
        });

        return {
            buyV, sellV, netBSV
        }




    }

    simpleMovingAverage(prices, window = 20, n = Infinity) {
        if (!prices || prices.length < window) {
            return [];
        }

        let index = window - 1;
        const length = prices.length + 1;

        const simpleMovingAverages = [];

        let numberOfSMAsCalculated = 0;

        while (++index < length && numberOfSMAsCalculated++ < n) {
            const windowSlice = prices.slice(index - window, index);
            const sum = windowSlice.reduce((prev, curr) => prev + curr, 0);
            simpleMovingAverages.push(sum / window);
        }

        return simpleMovingAverages;
    }



    async getData() {
        let startTime = moment(this.state.startTime).format('X')
        let stopTime = moment(this.state.startTime).add(this.state.timeLeng, 'd').format('X')

        let tepm = await this.model.step(startTime, stopTime, true)

        let dataBA = await this.handleDataTemp(tepm, false)

        let chart = this.calSma()



        // let data = await this.model.filter(startTime, stopTime, true)
        // let chart = this.handleData(data)


        let dataVn30Cs = await this.modelVn30.vn30_cs(startTime, stopTime, true)
        let dataVn30Ps = await this.modelVn30.vn30_ps(startTime, stopTime, true)

        let vn30 = this.handleDataVn30(dataVn30Cs, dataVn30Ps)
        this.renderChart(chart, vn30 , dataBA)


    }

    //handle data price to data chart
    handleDataVn30(dataCs, dataPs) {
        if (dataCs.length == 0 || dataPs.length == 0) return
        let cs = {
            x: [],
            y: [],
        }
        let ps = {
            x: [],
            y: [],
        }
        let net = {
            x: [],
            y: [],
        }
        let lastTime = null

        dataCs.map((item, index) => {
            let day = moment(item['timestamp'], 'X').format('YYYY/MM/DD HH:mm:ss')

            let dataPs1 = dataPs[index] ? dataPs[index]['psPrice'] : dataPs[index-1]['psPrice']
            cs['x'].push(day)
            cs['y'].push(item['csPrice'])
            ps['x'].push(day)
            ps['y'].push(dataPs1)
           

            if (item['csPrice'] > 0 && dataPs1 > 0) {
                net['x'].push(day)
                net['y'].push(dataPs1 - item['csPrice'])
            }

            lastTime = item['timestamp']


        })


        this.setState({
            lastTimeVn30: lastTime
        });

        return {
            cs, ps, net
        }
    }

    handleData(res) {

        if(res.length == 0) {
            showLog('volume 10 muc gia no data' , 'error')

            return
        }
        let buyV = {
            x: [],
            y: [],
        }
        let sellV = {
            x: [],
            y: [],
        }
        let netBSV = {
            x: [],
            y: [],
        }
        let lastTime = null
        res.map(item => {
            let day = moment(item['timestamp'], 'X').format('YYYY/MM/DD HH:mm:ss')
            buyV['x'].push(day)
            buyV['y'].push(item['totalVolBuysMA'])
            sellV['x'].push(day)
            sellV['y'].push(item['totalVolSellsMA'])
            netBSV['x'].push(day)
            netBSV['y'].push(item['totalVolBuysMA'] - item['totalVolSellsMA'])

            lastTime = item['timestamp']
        })

        this.setState({
            lastTime
        });

        return {
            buyV, sellV, netBSV
        }
    }

    //render chart plotlyjs
    renderChart(chart, vn30 , dataBA) {
        if (chart == undefined || vn30 == undefined) {
            showLog('No data price base or future', 'error')
            var data = [];

            global.Plotly.newPlot(this.chart, data, layout);
            return
        }
        let tickText = []
        chart['buyV']['x'].map((item, index) => {
            if (index % 100 == 0) {
                tickText.push(item)
            }
        })

        var BidV = {
            x: dataBA['BidV']['x'],
            y: dataBA['BidV']['y'],
            type: 'scatter',
            name: 'BidV',
            line: {
                color: '#3cdd96'
            }
        }
        var AskV = {
            x: dataBA['AskV']['x'],
            y: dataBA['AskV']['y'],
            type: 'scatter',
            name: 'AskV',
            line: {
                color: '#ff5858'
            }
        }
        var NetBA = {
            x: dataBA['NetBA']['x'],
            y: dataBA['NetBA']['y'],
            type: 'scatter',
            name: 'NetBA',
            line: {
                color: '#ffa758'
            }
        }


        var buyVData = {
            x: chart['buyV']['x'],
            y: chart['buyV']['y'],
            type: 'scatter',
            xaxis: 'x',
            yaxis: 'y2',
            // name: 'Buy Volume',
            name: `BidV${this.state.level['code']}`,
            line: {
                color: '#3cdd96'
            }
        };

        var sellVData = {
            x: chart['sellV']['x'],
            y: chart['sellV']['y'],
            type: 'scatter',
            xaxis: 'x',
            yaxis: 'y2',
            // name: 'Sell Volume',
            name: `AskV${this.state.level['code']}`,
            line: {
                color: '#ff5858'
            }
        };
        var netBSVData = {
            x: chart['netBSV']['x'],
            y: chart['netBSV']['y'],
            type: 'scatter',
            xaxis: 'x',
            yaxis: 'y2',
            // name: 'Net BS  Volume',
            name: `Net BA${this.state.level['code']}`,
            visible: 'legendonly',
            line: {
                color: '#ffa758'
            }
        };
        var cs = {
            x: vn30['cs']['x'],
            y: vn30['cs']['y'],
            type: 'scatter',
            xaxis: 'x',
            yaxis: 'y3',
            name: 'Co so',
            line: {
                color: '#3cdd96'
            }
        };
        var ps = {
            x: vn30['ps']['x'],
            y: vn30['ps']['y'],
            type: 'scatter',
            xaxis: 'x',
            yaxis: 'y3',
            name: 'Phai sinh',
            line: {
                color: '#ff5858'
            }
        };
        var net = {
            x: vn30['net']['x'],
            y: vn30['net']['y'],
            type: 'scatter',
            xaxis: 'x',
            yaxis: 'y4',
            // name: 'Net PS-CS'
            name: 'Premium'
        };

        var layout = {
            title: `Tổng Volume ${this.state.level['code']} mức giá PS`,
            height: 900,
            showlegend: true,
            margin: { b: 100, t: 40 },
            autosize: true,
            hovermode: "x unified",
            hoverdistance: 10,
            barmode: 'group',
            font: {
                size: 12,
            },
            xaxis: {
                autorange: true,
                type: "category",
                categoryorder: 'category ascending',
                rangeslider: { 'visible': false },
                showspikes: true,
                spikemode: 'across',
                spikesnap: 'cursor',
                spikedash: 'dot',
                // spikecolor: 'black',
                spikethickness: 2,
                tickmode: "array",
                tickvals: tickText,
                ticktext: tickText,

                // showgrid: false,
                //   showticklabels: true,
                tickangle: 'auto',
                showexponent: 'all'
                // gridcolor: 'white',
            },
            yaxis: {
                autorange: true,
                showspikes: true,
                spikemode: 'across',
                spikesnap: 'cursor',
                spikedash: 'dot',
                // spikecolor: 'black',
                spikethickness: 2,
                domain: [0.62, 1],
                title: {
                    text: 'Total Volume',
                }
            },

            yaxis2: { 
                domain: [ 0.32, 0.6],
                title: {
                    text: `${this.state.level['code']} Volume`,
                }
            },
            yaxis3: { 
                domain: [0, 0.3],
                title: {
                    text: `Price`,
                }
            },
            yaxis4: {
                overlaying: 'y3',
                side: 'right',
            },



            grid: {
                rows: 3,
                // rows: 2,
                columns: 1,
                subplots: [['xy'], ['xy2'] , ['xy3']],
                roworder: 'top to bottom',
                pattern: 'independent'
            }




        };

        var data = [BidV, AskV,NetBA,  buyVData, sellVData, netBSVData, cs, ps, net];

        global.Plotly.newPlot(this.chart, data, layout);
    }

    render() {
        return (
            <>
                <div className='flex' style={{ marginBottom: '10px' }}>

                    <Input DateFormat={'dd/mm/yyyy'} type='date' Direct={true} value={this.state.startTime} placeholder="Start Time" OnChange={(val) => {
                        val = Number(val)

                        this.setState({ startTime: val })
                    }}></Input>
                    &nbsp;
                    <Input className='input' style={{ width: 50, padding: 6 }} type='number' Direct={true} value={this.state.timeLeng} placeholder="Days" OnChange={(val) => {
                        val = Number(val)
                        localStorage.setItem(this.storageTimeLength, val);
                        this.setState({ timeLeng: val })
                    }}></Input>
                    &nbsp;

                    <Dropdown value={this.state.level} options={this.levelOpt} onChange={(e) => this.setState({ level: e.value })} optionLabel="name" />
                    &nbsp;
                    <Button style={{ padding: '0px 5px' }} onClick={() => {
                        this.getData()
                    }}>Apply</Button>

                </div>
                <div ref={c => this.chart = c} style={{ position: 'relative' }}>

                    <div style={this.state.delayTime < 60 ? { position: 'absolute', top: '3%', right: '10%', color: '#05d47b' } : { position: 'absolute', top: '3%', right: '10%', color: 'red' }}>
                        <i className="pi pi-clock" ></i>
                        <span style={{ marginLeft: '5px' }} >{this.state.delayTime}</span>
                    </div>

                </div>

            </>
        );
    }
}

export default VolumnPS;