import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom'
import AppWrapper from './AppWrapper';
//import * as serviceWorker from './serviceWorker';
import axios from 'axios';

global.User = "Admin"
function getUser() {
	return axios.request({
		url: `https://authen.f5traders.com/authen/login/getUser`,
		method: "POST",
		withCredentials : true
	})

		.then(response => {
			global.User = response['data']['username']
			global.User2 = response['data']
			return response;
		})

		.catch((error) => {
			console.log(error);
			return false;
		})
}

getUser().then(res => {
	ReactDOM.render(
		<HashRouter>
			<AppWrapper></AppWrapper>
		</HashRouter>,
		document.getElementById('root')
	);
})


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
