import React, { Component } from "react";
import BUSDChartLine from "./BUSDChartLine";
import BidAskChart from "./BidAskChart";
import BidAskScore from "./BidAskScore";
import { TabView, TabPanel } from "primereact/tabview";
import ModelDashboardV2 from "../../models/ModelDashboardV2";
import moment from "moment";
import { Dropdown } from 'primereact/dropdown';
import { checkWorkingTime, showLog } from '../../helpers/default';
class BidAsKView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 1,
            note: "sss",
            display: true,
            height: -1,
            selCorrection: localStorage.getItem('bid/ask_Correction') ? JSON.parse(localStorage.getItem('bid/ask_Correction')) : { 'name': 'Nguyên Bản', 'code': 'Nguyên Bản' },
            optCorrection: [
                { 'name': 'Nguyên Bản', 'code': 'Nguyên Bản' },
                { 'name': 'Threshold', 'code': 'Threshold' },
            ],
        };

        this.model = new ModelDashboardV2()
    }
    getData(day) {
        this.setState({
            day: day,
            lastTimestamp: moment(day, 'YYYY_MM_DD').startOf('day').add(9, 'hours').valueOf() / 1000,
        }, async () => {
            let paramter = {
                "day": this.state.day,
                "lastTimestamp": this.state.lastTimestamp,
                "indicator_correction": {
                    'bid/ask': this.state.selCorrection['code'],
                }
            }

            let data = await this.model.get_data_bid_ask2(false, paramter)

            let { BidV, AskV, netBA, BA_Point, lastPoint } = this.handleData(data)

            if (this.state.activeIndex == 0) {
                this.BidAsKView.loadData(BidV, AskV, netBA)
            }
            if (this.state.activeIndex == 1) {
                this.BidAskScore.loadData(BA_Point )
            }

            this.BUSDChartLine.setContent(lastPoint)

        });



    }
    // update realtime data price
    async updateData() {
        if (checkWorkingTime()) {

            if (moment(this.state.lastTimestamp, "X").format('YYYY_MM_DD') != moment().format('YYYY_MM_DD')) {
                return
            }

            let paramter = {
                "day": this.state.day,
                "lastTimestamp": this.state.lastTimestamp,
                "indicator_correction": {
                    'bid/ask': this.state.selCorrection['code'],
                }
            }

            let data = await this.model.get_data_bid_ask2(false, paramter)

            if (data.length > 0) {
                var { BidV, AskV, netBA, BA_Point, lastPoint } = this.handleData(data)
                if (this.state.activeIndex == 0) {

                    let preData = this.BidAsKView.chart.data


                    preData[0]['x'].push(...BidV['x'])
                    preData[0]['y'].push(...BidV['y'])
                    preData[1]['x'].push(...AskV['x'])
                    preData[1]['y'].push(...AskV['y'])
                    preData[2]['x'].push(...netBA['x'])
                    preData[2]['y'].push(...netBA['y'])
                    this.BidAsKView.chart.data = preData
                    global.Plotly.redraw(this.BidAsKView.chart)

                    this.BidAsKView.updateXAxis(preData[0]['x'])
                }

                if (this.state.activeIndex == 1) {
                    let preData = this.BidAskScore.chart.data
                    preData[0]['x'].push(...BA_Point['x'])
                    preData[0]['y'].push(...BA_Point['y'])
                    this.BidAskScore.chart.data = preData
                    global.Plotly.redraw(this.BidAskScore.chart)

                    this.BidAskScore.updateXAxis(preData[0]['x'])
                }

                this.BUSDChartLine.setContent(lastPoint)



            }




        }


    }


    handleData(res) {
        let BidV = {
            'x': [],
            'y': [],
        }
        let AskV = {
            'x': [],
            'y': [],

        }
        let netBA = {
            'x': [],
            'y': [],

        }
        let BA_Point = {
            'x': [],
            'y': [],

        }

        let lastPoint = 0
        res.map((item, idx) => {
            let time = moment.unix(item['timestamp']).format("HH:mm:ss")
            BidV['x'].push(time)
            BidV['y'].push(item['BID'])
            AskV['x'].push(time)
            AskV['y'].push(item['ASK'])
            netBA['x'].push(time)
            netBA['y'].push(item['NetBA'])
            BA_Point['x'].push(time)
            BA_Point['y'].push(item['BA_Point'])
            if (res.length == idx + 1) {
                this.setState({
                    lastTimestamp: item['timestamp']
                });

                lastPoint = item['BA_Point']
            }



        })

        return {
            BidV, AskV, netBA, BA_Point, lastPoint
        }
    }
    componentDidMount() {
        if (this.props.day != '') {
            this.getData(this.props.day)

            if (Object.keys(this.props.note).length > 0) {
                this.setState({
                    note: this.props.note['Bid/Ask'],
                });

            }


            if (checkWorkingTime()) {
                this.interval = setInterval(() => {
                    this.updateData()
                }, 5000);
            }
        }

    }
    componentWillUnmount() {
        if (this.interval) clearInterval(this.interval);
    }

    renderInfo() {
        return (
            <div style={{ whiteSpace: 'pre-wrap', margin: '-20px 0px 20px 20px' }}>{this.state.note}</div>
        );
    }

    render() {
        return (
            <div style={this.state.display ? { marginTop: 20 } : { marginTop: 10 }}>
                <div style={{ marginBottom: 20, cursor: 'pointer', display: 'flex', alignItems: 'center' }} >
                    <div style={{display : 'flex' , alignItems : 'center'}} onClick={() => this.setState({ display: !this.state.display })}> 
                        <span>Bid/Ask Scoring</span>

                        <i style={{ marginLeft: 10, marginTop: 3 }} className={this.state.display ? "pi pi-angle-up" : "pi pi-angle-down"}></i>
                    </div>

                    <Dropdown
                        value={this.state.selCorrection}
                        options={this.state.optCorrection}
                        onChange={(e) => { 
                            localStorage.setItem("bid/ask_Correction", JSON.stringify(e.value));
                            this.setState({
                                selCorrection: e.value,
                                lastTimestamp: moment(this.state.day, 'YYYY_MM_DD').startOf('day').add(9, 'hours').valueOf() / 1000
                            },
                                () => {
                                    if (this.interval) {
                                        clearInterval(this.interval);
                                    }
                                    
                                    this.getData(this.state.day)
                                    let ChartTotalScoreView = this.props.ChartTotalScoreView()
                                    ChartTotalScoreView.changeIndicator_correction({'bid/ask' : this.state.selCorrection['code']})
                                    if (checkWorkingTime()) {
                                        this.interval = setInterval(() => {
                                            this.updateData()
                                        }, 5000);
                                    }
                                }
                            );
                        }}
                        optionLabel="name"
                        style={{ margin: "0px 10px" }}

                    />
                </div>

                <div
                    className="grid"
                    style={{
                        background: "white",
                        marginBottom: 20,
                        borderRadius: 8,
                        marginLeft: 1,
                        maxHeight: this.state.display ? "500px" : '0',
                        transition: 'all .5s',
                        overflow: 'hidden'

                    }}
                    ref={c => this.aa = c}
                >
                    <div className="col-12 md:col-4">
                        <BUSDChartLine config={{ score: 4 }} ref={c => this.BUSDChartLine = c}></BUSDChartLine>
                    </div>
                    <div className="col-12 md:col-8">
                        <div style={{ marginLeft: 25 }}>
                            <TabView
                                activeIndex={this.state.activeIndex}
                                onTabChange={(e) => {
                                    this.setState({
                                        activeIndex: e.index,
                                        lastTimestamp: moment(this.state.day, 'YYYY_MM_DD').startOf('day').add(9, 'hours').valueOf() / 1000
                                    },
                                        () => {
                                            if (this.interval) {
                                                clearInterval(this.interval);
                                            }
                                            this.getData(this.state.day)
                                            if (checkWorkingTime()) {
                                                this.interval = setInterval(() => {
                                                    this.updateData()
                                                }, 5000);
                                            }
                                        }
                                    );

                                }}
                            >
                                <TabPanel header="Bid/Ask"></TabPanel>
                                <TabPanel header="Bid/Ask Score"></TabPanel>
                                <TabPanel header="Info"></TabPanel>
                            </TabView>

                        </div>

                        {this.state.activeIndex == 0 ? (
                            <BidAskChart ref={c => this.BidAsKView = c}></BidAskChart>
                        ) : this.state.activeIndex == 1 ? (
                            <BidAskScore ref={c => this.BidAskScore = c}></BidAskScore>
                        ) :
                            this.renderInfo()
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default BidAsKView;
