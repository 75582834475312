import React, { Component } from 'react';
import ExchangePNLModel from '../../models/ExchangePNLModel';
import moment from 'moment';
import { formatNumber } from '../../helpers/default';
class ChartPNL extends Component {
    constructor(props) {
        super(props);

        this.model = new ExchangePNLModel()
    }

    getDataChart(opt) {
     
        this.model.chart_pnl().then(res => {
            if(res['result']){
                let id_hidden = [5,6,7,11]
                let chartData = []

                res['data'].map(item => {

                    let exchange = {
                        x: [],
                        y: [],
                        text: [],
                        hovertemplate: `<br>%{text}<br>`,
                        type: 'scatter',
                        name: opt[item['name']],
                        mode: 'lines',
                        visible : id_hidden.includes(item['name']) ? 'legendonly' : true
                    }

                    if(!opt[item['name']] ){
                        console.log(item)
                    }
                    // console.log(opt[item['name']])
             
                    

                    item['data'].map(exc => {
                        let time = moment(exc['timestamp'], 'x').format('YYYY_MM_DD')
                        exchange['x'].push(time)
                        exchange['y'].push(exc['pnl_cum'])
                        let text = `<b>${ opt[item['name']]} </b> </br> <b>${time}</b>    </br> <b>${formatNumber( Math.round(exc['pnl_cum']*100)/100 ) } </b> `
                        exchange['text'].push(text)
                    })

                    chartData.push(exchange)
                })
                this.renderChart(chartData)
            }   
        })
    }

    renderChart(data, ticktext) {


        var layout = {
            title: 'PNL Exchange',
            height: 800,
            showlegend: true,
            legend: {
                x: 0,
                y: 1,
            },
            margin: { b: 100, t: 40 },
            autosize: true,
            hoverdistance: 10,
            barmode: 'group',
            font: {
                size: 12,
            },
            xaxis: {
                autorange: true,
                type: "category",
                categoryorder: 'category ascending',
                rangeslider: { 'visible': false },
                showspikes: true,
                spikemode: 'across',
                spikesnap: 'cursor',
                spikedash: 'dot',
                spikecolor: 'black',
                spikethickness: 2,

                tickangle: 'auto',
                showexponent: 'all',
                // tickmode: "array",
                // tickvals: ticktext,
                // ticktext: ticktext,

            },
            yaxis: {
                autorange: true,
                showspikes: true,
                spikemode: 'across',
                spikesnap: 'cursor',
                spikedash: 'dot',
                spikecolor: 'black',
                spikethickness: 2,
            },

        };

        global.Plotly.newPlot(this.chart, data, layout);
    }

    render() {
        return (
            <div ref={c => this.chart = c}>

            </div>
        );
    }
}

export default ChartPNL;