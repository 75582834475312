import React, { Component } from "react";
import BUSDChartLine from "../BUSDChartLine";

import { TabView, TabPanel } from "primereact/tabview";
import ModelDashboardV2 from "../../../models/ModelDashboardV2";
import moment from "moment";

import { checkWorkingTime, showLog } from '../../../helpers/default';
import { Dropdown } from 'primereact/dropdown';
class Score_v2View extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            display: true,
            // selCorrection: localStorage.getItem('total_socre_v2_Correction') ? JSON.parse(localStorage.getItem('total_socre_v2_Correction')) : { 'name': 'BidAsk + NN_CS', 'code': 'BidAsk + NN_CS' },
            selCorrection: localStorage.getItem('total_socre_v2_Correction') ? ['Nguyên bản','Làm tròn','Ranking'].includes( JSON.parse(localStorage.getItem('total_socre_v2_Correction'))['code']) ? { 'name': 'BidAsk + NN_CS', 'code': 'BidAsk + NN_CS' } :JSON.parse(localStorage.getItem('total_socre_v2_Correction')) : { 'name': 'BidAsk + NN_CS', 'code': 'BidAsk + NN_CS' },
            optCorrection: [
                { 'name': 'BidAsk', 'code': 'BidAsk' },
                { 'name': 'NN_CS', 'code': 'NN_CS' },
                { 'name': 'BidAsk + NN_CS', 'code': 'BidAsk + NN_CS' },
            ],

        };

        this.model = new ModelDashboardV2()

      
       
    
   
    }
    isTimeTrue(timeString) {

        const date = new Date();
        const hours = timeString.split(':')[0];
        const minutes = timeString.split(':')[1];
        const seconds = timeString.split(':')[2];
        date.setHours(hours, minutes, seconds);

        const lowerBound = new Date();
        lowerBound.setHours(11, 30, 0);
        const upperBound = new Date();
        upperBound.setHours(13, 0, 0);

        const upperHighBound = new Date();
        upperHighBound.setHours(14, 45, 0);


        if (date >= upperHighBound) {
            return true
        }

        return date >= lowerBound && date <= upperBound;



    }
    async getData(day) {

        this.setState({
            day: day,
            lastTimestamp: moment(day, 'YYYY_MM_DD').startOf('day').add(9, 'hours').valueOf() / 1000,
            ema : this.props.ema,
            sma : this.props.sma,
        }, async () => {
            let paramter = {
                "day": this.state.day,
                "lastTimestamp": this.state.lastTimestamp,
                ema: this.props.ema,
                sma: this.props.sma,
                smooth: this.state.selCorrection['code']
            }
            let dataPs = await this.getDataPS(true, this.props.day)
            let dataResult = await this.model.get_data_total_score_v2(false, paramter)

            let dataScore = this.handleData(dataResult)

       


            if (this.state.activeIndex == 0) {
                this.renderChart(dataPs, dataScore)
            }

            
        })

    }
    getDataPS(loading, day) {
        let params = {
            "day": day
        }
        return this.model.get_data_close_ps(loading, params).then(res => {


            let dataPs = {
                'x': [],
                'y': [],
                'name': 'Phai sinh',
                line: {
                    color: 'red',
                }
            }

            if (res != null) {
                res = JSON.parse(res)
                res['time'].map((item, idx) => {

                    if (!this.isTimeTrue(item)) {
                        dataPs['x'].push(item)
                        dataPs['y'].push(res['price'][idx])
                    }

                })
            }


            return dataPs

        })
    }
    // update realtime data price
    async updateData() {
        if (checkWorkingTime()) {

            if (moment(this.state.lastTimestamp, "X").format('YYYY_MM_DD') != moment().format('YYYY_MM_DD')) {
                return
            }

            let paramter = {
                "day": this.state.day,
                "lastTimestamp": this.state.lastTimestamp,
                ema: this.state.ema,
                sma: this.state.sma,
                smooth: this.state.selCorrection['code']
            }

            let dataPs = await this.getDataPS(false, this.state.day)
            let dataResult = await this.model.get_data_total_score_v2(false, paramter)

            let data = this.handleData(dataResult)

            let dataScore = data['dataScore']
            let dataScoreEMA = data['dataScoreEMA']
            let dataScoreSMA = data['dataScoreSMA']
            let dataScoreMACD = data['dataScoreMACD']

            let layout = this.chart.layout
            layout['annotations'][0]['y'] = dataPs['y'][dataPs['y'].length - 1]
            layout['annotations'][0]['text'] =dataPs['y'][dataPs['y'].length - 1]


            let preData = this.chart.data
            preData[0] = dataPs

            preData[1]['x'].push(...dataScore['x'])
            preData[1]['y'].push(...dataScore['y'])

            preData[2]['x'].push(...dataScoreEMA['x'])
            preData[2]['y'].push(...dataScoreEMA['y'])

            preData[3]['x'].push(...dataScoreSMA['x'])
            preData[3]['y'].push(...dataScoreSMA['y'])

            preData[4]['x'].push(...dataScoreMACD['x'])
            preData[4]['y'].push(...dataScoreMACD['y'])
            preData[4]['marker']['color'].push(...dataScoreMACD['marker']['color'])


            this.chart.data = preData
            global.Plotly.redraw(this.chart)


        }


    }

    handleData(res) {
        let dataScore = {
            'x': [],
            'y': [],
            'name': 'Score',
            xaxis: "x",
            yaxis: "y2",
            line: {
                'color': 'limegreen',
            },
           
        }

        let dataScoreEMA = {
            'x': [],
            'y': [],
            'name': 'Score EMA',
            xaxis: "x",
            yaxis: "y2",
            line: {
                'color': '#856dfc'
            },
            // visible: "legendonly"
        }
        let dataScoreSMA = {
            'x': [],
            'y': [],
            'name': 'Score SMA',
            xaxis: "x",
            yaxis: "y2",
            line: {
                'color': '#ffa758'
            },
            visible: "legendonly"
        }
        let dataScoreMACD = {
            'x': [],
            'y': [],
            'name': 'Score MACD',
            type: 'bar',
            xaxis: "x",
            yaxis: "y3",
            marker: { color: [] }
        }

        if (res) {

            res.map((item, idx) => {

                dataScore['x'].push(item['time'])
                dataScore['y'].push(item['score_round'])

                dataScoreEMA['x'].push(item['time'])
                dataScoreEMA['y'].push(item['score_round_ema'])


                dataScoreSMA['x'].push(item['time'])
                dataScoreSMA['y'].push(item['score_round_sma'])

                dataScoreMACD['x'].push(item['time'])
                dataScoreMACD['y'].push(item['score_round_MACD'])
                dataScoreMACD['marker']['color'].push(item['score_round_colors'] ? item['score_round_colors'] : 'red')


                if (idx == res.length - 1) {
                    this.BUSDChartLine.setContent(item['score_round'])
                    this.setState({
                        lastTimestamp: item['timestamp']
                    });
                }

            })
        }

        return { dataScore, dataScoreEMA, dataScoreSMA, dataScoreMACD }
    }
    findClosestTime(inputTime, data) {
      
        const inputMoment = moment(inputTime, "HH:mm:ss");
        if(data.length ==0) {
            return inputTime 
        }
        let closestTime = null;
        for (let i = 0; i < data.length; i++) {
            const currTime = moment(data[i], "HH:mm:ss");
            if (currTime.isAfter(inputMoment)) {
                closestTime = data[i];
                break;
            }
        }
        if (!closestTime) {
            const lastTime = data[data.length - 1];
            const lastMoment = moment(lastTime, "HH:mm:ss");
            if (inputMoment.isAfter(lastMoment)) {
                closestTime = inputTime;
            } else {
                closestTime = data.reduce((prev, curr) => {
                    const currMoment = moment(curr, "HH:mm:ss");
                    const prevMoment = moment(prev, "HH:mm:ss");
                    const currDiff = Math.abs(currMoment.diff(inputMoment));
                    const prevDiff = Math.abs(prevMoment.diff(inputMoment));
                    return currDiff < prevDiff ? curr : prev;
                });
            }
        }
        return closestTime;
    }
    renderChart(dataPs, data) {

        let dataScore = data['dataScore']
        let dataScoreEMA = data['dataScoreEMA']
        let dataScoreSMA = data['dataScoreSMA']
        let dataScoreMACD = data['dataScoreMACD']
        let timeArrayTran = ['09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '13:30', '14:00', '14:30', '15:00']
        let timeArray = []
        timeArrayTran.map((item, idx) => {
            let tran = item + ':00'
            if (dataScore['x'].indexOf(tran) > -1) {
                timeArray[idx] = tran
            } else {
                let a = this.findClosestTime(tran, dataScore['x'])
                timeArray[idx] = a
            }
        })
        var data = [dataPs, dataScore , dataScoreEMA , dataScoreSMA , dataScoreMACD];
      

        var layout = {
            height: window.screen.width >= 1920 ? '100%' : 400,
            showlegend: true,
            title: "<b> Total score across time </b>",
            autosize: true,
            margin: {
                pad: 10
            },
            hovermode: "x unified",
            hoverdistance: 10,
            barmode: "group",
            font: {
                size: 12,
            },
            xaxis: {
                autorange: true,
                type: "category",
                categoryorder: "category ascending",
                rangeslider: { visible: false },
                showspikes: true,
                spikemode: "across",
                spikesnap: "cursor",
                spikedash: "dot",
                // spikecolor: 'black',
                spikethickness: 2,
                tickmode: "array",
                tickvals: timeArray,
                ticktext: timeArrayTran,

                // showgrid: false,
                //   showticklabels: true,
                tickangle: "auto",
                showexponent: "all",
                // gridcolor: 'white',
            },
            yaxis: {
                autorange: true,
                showspikes: true,
                spikemode: "across",
                spikesnap: "cursor",
                spikedash: "dot",
                // spikecolor: 'black',
                spikethickness: 2,
                side: 'right',

                domain: [0.32, 1]

            },
            yaxis2: {
                overlaying: "y",
                side: "left",
                domain: [0.32, 1]
            },
            yaxis3: { domain: [0, 0.3] },
            legend: {
                x: 0,
                // xanchor: 'right',
                y: 1.1,
                "orientation": "h",
            },

            annotations: [
                {
                  x: 1.06, // tọa độ x của chú thích
                  y: dataPs['y'][dataPs['y'].length - 1], // tọa độ y của chú thích
                  xref: 'paper', // xác định tọa độ x sử dụng trong khoảng 0-1 của trục x
                  yref: 'y', // xác định tọa độ y sử dụng trục y
                  text: dataPs['y'][dataPs['y'].length - 1]? dataPs['y'][dataPs['y'].length - 1] : '', // nội dung chú thích
                  showarrow: false, // ẩn mũi tên chú thích
                  align: 'left', // căn lề của chú thích
                  bgcolor:  dataPs['y'][dataPs['y'].length - 1]? 'red' : 'white', // màu nền của chú thích
                  opacity: 0.8, // độ trong suốt của chú thích
                  ax: 0, // căn chỉnh vị trí x của chú thích
                  ay: 10, // căn chỉnh vị trí y của chú thích,
                  font: {
                    color: 'white',
                  }
                }
              ],

            grid: {
                rows: 2,
                columns: 1,
                subplots: [['xy'], ['xy3']],
                roworder: 'top to bottom',
              }

        };
        var config = { responsive: true };
        global.Plotly.newPlot(this.chart, data, layout,config);
    }
    componentDidMount() {

        if (this.props.day != '') {
            this.getData(this.props.day)

            if (Object.keys(this.props.note).length > 0) {
                this.setState({
                    note: this.props.note['score_v2']
                });
            }


            if (checkWorkingTime()) {
                this.interval = setInterval(() => {
                    this.updateData()
                }, 5000);
            }
        }
    }
    componentWillUnmount() {
        if (this.interval) clearInterval(this.interval);
    }
    renderInfo() {
        return (
            <div style={{ whiteSpace: 'pre-wrap', margin: '-20px 0px 20px 20px' }}>{this.state.note}</div>
        );
    }

    render() {
        return (
            <div style={this.state.display ? { marginTop: 20 } : { marginTop: 10 }}>
                <div style={{ marginBottom: 20, cursor: 'pointer', display: 'flex', alignItems: 'center' }} >
                    <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => this.setState({ display: !this.state.display })}>
                        <span>Total Score FIX</span>

                        <i style={{ marginLeft: 10, marginTop: 3 }} className={this.state.display ? "pi pi-angle-up" : "pi pi-angle-down"}></i>
                    </div>

                    <Dropdown
                        value={this.state.selCorrection}
                        options={this.state.optCorrection}
                        onChange={(e) => {
                            localStorage.setItem("total_socre_v2_Correction", JSON.stringify(e.value));
                            this.setState({
                                selCorrection: e.value,
                                lastTimestamp: moment(this.state.day, 'YYYY_MM_DD').startOf('day').add(9, 'hours').valueOf() / 1000
                            },
                                () => {
                                    if (this.interval) {
                                        clearInterval(this.interval);
                                    }

                                    this.getData(this.state.day)
                                    // let ChartTotalScoreView = this.props.ChartTotalScoreView()
                                    // ChartTotalScoreView.changeIndicator_correction({ 'foregin': this.state.selCorrection['code'] })
                                    if (checkWorkingTime()) {
                                        this.interval = setInterval(() => {
                                            this.updateData()
                                        }, 5000);
                                    }
                                }
                            );
                        }}
                        optionLabel="name"
                        style={{ margin: "0px 10px" }}

                    />
                </div>
                <div
                    className="grid"
                    style={{
                        background: "white",
                        marginBottom: 20,
                        borderRadius: 8,
                        marginLeft: 1,
                        maxHeight: this.state.display ? "500px" : '0',
                        transition: 'all .5s',
                        overflow: 'hidden'
                    }}
                >
                    <div className="col-12 md:col-4">
                        <BUSDChartLine config={{ score: 4 }} ref={c => this.BUSDChartLine = c}></BUSDChartLine>
                    </div>
                    <div className="col-12 md:col-8">
                        <div style={{ marginLeft: 25 }}>
                            <TabView
                                activeIndex={this.state.activeIndex}
                                onTabChange={(e) => {
                                    this.setState({
                                        activeIndex: e.index,

                                        lastTimestamp: moment(this.state.day, 'YYYY_MM_DD').startOf('day').add(9, 'hours').valueOf() / 1000
                                    },
                                        () => {
                                            if (this.interval) {
                                                clearInterval(this.interval);
                                            }
                                            this.getData(this.state.day)
                                            if (checkWorkingTime()) {
                                                this.interval = setInterval(() => {
                                                    this.updateData()
                                                }, 5000);
                                            }
                                        }
                                    );

                                }}
                            >
                                <TabPanel header="Total Score"></TabPanel>
                                <TabPanel header="Info"></TabPanel>
                            </TabView>
                        </div>

                        {this.state.activeIndex == 0 ? (
                            <div>
                                <div ref={(c) => (this.chart = c)}></div>
                            </div>
                        ) :
                            this.renderInfo()
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Score_v2View;
