import React, { Component } from 'react';
import ModelDashboard from '../../models/ModelDashboard';
import BUSDChart from './ChartMain/BUSDChart';
import LongShortChart from './ChartMain/LongShortChart';
import BUSDChart_v1 from './ChartMain/BUSDChart_v1';
import moment from 'moment';
import { checkWorkingTime } from '../../helpers/default';
class BidAskView extends Component {

    constructor(props) {
        super(props);

        this.model = new ModelDashboard()

        this.state = {
            time: '',
            colorTime: ''
        }
    }

    getData(loading){
        this.model.get_data_bid_ask(loading).then(res => {
            let data = JSON.parse(res)
            if (res == null) {
                this.LongShortChart.setNum(0, 200)
                let messShort = `Short không có tín hiệu `
                this.chartShort.setContent(0, 'short', messShort)
                let messLong = `Long không có tín hiệu `
                this.chartLong.setContent(0, 'long', messLong)
            } else {
       
                this.LongShortChart.setNum(Number(data['score'].toFixed(1)), 200)

               


                if (data['side'] == 'long') {
                    let messLong = `Tín hiệu ${data['side'].toUpperCase()}, Winrate : ${data['win_rate'].toFixed(2)} % `
                    this.chartLong.setContent(Number(data['win_rate'].toFixed(2)), 'long', messLong)
                    let messShort = `Short không có tín hiệu `
                    this.chartShort.setContent(0, 'short', messShort)

                } else if (data['side'] == 'short') {
                    let messShort = `Tín hiệu ${data['side'].toUpperCase()}, Winrate : ${data['win_rate'].toFixed(2)} % `
                    this.chartShort.setContent(Number(data['win_rate'].toFixed(2)), 'short', messShort)

                    let messLong = `Long không có tín hiệu `
                    this.chartLong.setContent(0, 'long', messLong)
                }
                else {
                    let messShort = `Short không có tín hiệu `
                    this.chartShort.setContent(0, 'short', messShort)
                    let messLong = `Long không có tín hiệu `
                    this.chartLong.setContent(0, 'long', messLong)
                }

                let time = `${data['day']} ${data['ctime']}`

                let color
                if (Number(moment().format('X')) - Number(moment(time.replaceAll('_', '/')).format('X')) <= 120) {
                    color = 'limegreen'
                } else {
                    color = 'red'
                }
                this.setState({
                    time,
                    colorTime : color
                });
            }

        })
    }
    componentDidMount() {
        this.getData(true)

        if(checkWorkingTime()){
            this.interval = setInterval(() => {
                this.getData(false)
            }, 15000);
        }

        

       
    }

    componentWillUnmount() {
        if (this.interval) clearInterval(this.interval);
    }
    render() {
        return (
            <div className='card' style={{ marginBottom: '0px', height: '100%' }} >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div >
                        <b style={{cursor : 'pointer'}} onClick={() => window.open(`${window.location.href}bid_ask`)} >Bid/Ask</b>
                    </div>
                    <div >
                        <div style={{ color: this.state.colorTime }} >
                            <i className="pi pi-clock"></i>
                            <b className='ml-2' >{this.state.time}</b>
                        </div>
                    </div>
                </div>

                <div>
                    <LongShortChart ref={c => this.LongShortChart = c}></LongShortChart>

                </div>

                <div className='grid mt-2'>
                    <div className='col-12 md:col-12 ' style={{ display: 'flex' }}>

                        <BUSDChart_v1 ref={c => this.chartShort = c} title='Short'></BUSDChart_v1>
                    </div>
                    <div className='col-12 md:col-12' style={{ display: 'flex' }}>

                        <BUSDChart_v1 ref={c => this.chartLong = c} title='Long'></BUSDChart_v1>
                    </div>

                </div>

            </div>
        );
    }
}

export default BidAskView;