import axios from "axios";
import { cmc3_api_gateway } from "../config/config";
import { checkLogin, loading } from "../helpers/default";
import { error_handle } from '../helpers/error'
class ModelCtrl {

    constructor() {
        this.url = cmc3_api_gateway
        

    }
   

    setCtrl(data) {
        return axios.request({
            url: `${this.url}/ctrl/set/`,
            method: "POST",
            data:{...data},
            withCredentials: true,
        })

            .then(response => {
                response = response['data'];
                console.log(response)
                return response

            })
            .catch((error) => {
                checkLogin(error)

                return false;
            })

    }
    
    getCtrl() {

        return axios.request({
            url: `${this.url}/ctrl/get/`,
            method: "get",
            withCredentials: true,
        })

            .then(response => {
                response = response['data'];
         
                return response

            })
            .catch((error) => {
                checkLogin(error)

                return false;
            })

          




    }



    get(key, df = null){
        loading(true);
        return axios.request({
			url: cmc3_api_gateway + '/ctrl/get',
			method: 'POST',
			data: {
                keys: [key]
            },
            withCredentials: true,
		})
			.then(response => {
				loading(false, 'Loading...');
				response = response['data'];
				if(response['result']){
                    if(response['data'][key] === null) return df;
                    return response['data'][key];
                }else{
                    error_handle(response);
                    return df;
                }
			})

			.catch((error) => {
				loading(false, 'Loading...');
				error_handle(error)
				return df;
			})
    }

    set(data){
        loading(true);
        return axios.request({
			url: cmc3_api_gateway + '/ctrl/set',
			method: 'POST',
            data: data,
            withCredentials: true,
			
		})
			.then(response => {
				loading(false, 'Loading...');
				response = response['data'];
                if(response['result']){
                    return response;
                }else{
                    error_handle(response);
                    return response;
                }
				
			})

			.catch((error) => {
				loading(false, 'Loading...');
				error_handle(error)
				return false;
			})
    }






}

export default ModelCtrl;