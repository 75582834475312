import { authen_url } from "../config/config";
import { showLog } from "./default";

export var error_handle = (res)=>{
    console.log(res)

    if (res.response && res.response.status === 401) {
		window.location.href = authen_url + `/#/login?nexLink=${encodeURIComponent(window.location.href)}`
		return;
	}

    if(res.result !== undefined && !res['result']){
        showLog(res.message, 'error')
        return;
    }

    if(res.response){
        showLog(res.response.statusText, 'error')
        return;
    }


}