import axios from "axios";
import { cmc3_api_gateway } from "../config/config";
import { checkLogin, loading } from "../helpers/default";
import { error_handle } from '../helpers/error'

class ModelDashboard {

    constructor() {
        this.url = cmc3_api_gateway
        
    }

    get_data_Tn_model(){
        loading(true);
        return axios.request({
			url: cmc3_api_gateway + '/dashboard/Tn_model',
			method: 'POST',
			data: {},
            withCredentials: true,
		})
			.then(response => {
				loading(false, 'Loading...');
				response = response['data'];

				if(response['result']){
                   
                    return response['data'];
                }else{
                    error_handle(response);
                    return false;
                }
			})

			.catch((error) => {
				loading(false, 'Loading...');
				error_handle(error)
				return false;
			})
    }
    get_data_bid_ask(spinc=true){
		if(spinc){
			loading(true);
		}
        
        return axios.request({
			url: cmc3_api_gateway + '/dashboard/Bid_Ask',
			method: 'POST',
			data: {},
            withCredentials: true,
		})
			.then(response => {
				loading(false, 'Loading...');
				response = response['data'];

				if(response['result']){
                   
                    return response['data'];
                }else{
                    error_handle(response);
                    return false;
                }
			})

			.catch((error) => {
				loading(false, 'Loading...');
				error_handle(error)
				return false;
			})
    }


	get_data_fa_score(data={}){
        loading(true, 'Loading...')
        return axios.request({
            url: this.url + '/dashboard/Fa_score',
            method: "POST",
            data:{...data},
            withCredentials: true, 
        })

            .then(response => {
                loading(false, 'Loading...')
                response = response['data'];
                if(response['result']) return response
                error_handle(response)
                return response

            })
            .catch(error => {
                error_handle(error)
            })
    }
   

   
    






}

export default ModelDashboard;