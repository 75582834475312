import React, { Component } from 'react';
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
class InputAce extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value ? this.props.value : '',
            height: this.props.height ? this.props.height : '250px',
            fontSize: this.props.fontSize ? this.props.fontSize : '14px',
        
        }

    }
    getValue() {
        return this.state.value
    }
    setValue(data) {
        this.setState({
            value: data
        });
    }

    setHeight(height) {
        this.setState({
            height
        });
    }
    componentDidMount() {

        setTimeout(() => {
            this.setState({
                fontSize : '14px'
               });
        } , 100)
       
    }



    render() {
        return (
            <div >
                <AceEditor

                    mode="json"
                    theme="monokai"
                    name="blah2"
                    fontSize={this.state.fontSize}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    width="100%"
                    height={this.state.height}
                    value={this.state.value}
                    onChange={(val) => { this.setState({ 'value': val }) }}
                    // style={{ lineHeight : 1.5}}
                    ref={c => this.aceEditor = c}
                    setOptions={{
                        useWorker: false
                    }}
                

                />
            </div>
        );
    }
}

export default InputAce;