import React, { Component } from 'react';
import ChartPNL from '../components/ExchangePNL/ChartPNL';
import TableWinrate from '../components/ExchangePNL/TableWinrate';
import ExchangePNLModel from '../models/ExchangePNLModel';

class ExchangePnlView extends Component {

    constructor(props) {
        super(props);
        

        this.model = new ExchangePNLModel()
    }
    
    componentDidMount(){
        this.model.campaign_mapping().then(res => {
            if(res['result']){

                let opt = {}
                res['data'].map(item => {
                    opt[item['bot_camp_id']] = item['bot_camp_name']
                })


                this.ChartPNL.getDataChart(opt)
                this.TableWinrate.getDataTable(opt)
            }
        })
    }
 
    render() {
        return (
            <div>
                <ChartPNL ref={c => this.ChartPNL = c}></ChartPNL>

                <TableWinrate ref={c => this.TableWinrate = c}></TableWinrate>
            </div>
        );
    }
}

export default ExchangePnlView;