import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import { copyToClipboard } from '../../helpers/default';
import { Button } from 'primereact/button';
class ChartClone extends Component {

	constructor(props) {
		super(props);
		this.state = {
			config: '',
			visible: false
		}

			
	}


	onClickHandle() {
		if(this.props.onApply){
			this.props.onApply(JSON.parse(this.state.config))
		}
		
	}

	setConfig(config){
		config = JSON.stringify(config, null, 2)
		this.setState({config: config})
	}

	
	modal(cmd = 'show') {
		if (cmd == 'hide') {
			this.setState({visible: false})
		} else {
			this.setState({visible: true})
		}
	}

	setDefault(){
		if(this.props.onSetDefault){
			this.props.onSetDefault(JSON.parse(this.state.config))
		}
	}
	

	render() {

		const footer = <div className="modal-footer">
			<Button type="button" className="p-button-primary" onClick={() => { this.setDefault(this.state.config) }}>{'Set as Default'}</Button>
			<Button type="button" className="p-button-info" onClick={() => { copyToClipboard(this.state.config) }}>{'Copy'}</Button>
			<Button type="button" className="p-button-warning" onClick={() => { this.onClickHandle() }}>{'Apply'}</Button>
			<Button type="button" className="p-button-danger" onClick={()=>this.setState({visible:false})}>{'Close'}</Button>
		</div>
		
		return (
			<Dialog header={this.state.title} footer={footer} visible={this.state.visible} style={{width: '80%'}} modal onHide={()=>this.setState({visible:false})}>
					
						<div className="modal-body" style={{ textAlign: 'initial' }}>

							<textarea style={{width:'100%', minHeight:500}} className='input' value={this.state.config} onChange={(e) => this.setState({config: e.target.value})}></textarea>

						</div>

			</Dialog>
		)
	}
}
export default ChartClone
