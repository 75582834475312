import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import InputAce from '../Input/InputAce';
import moment from 'moment'

import './index.scss'
import ModelWeight from '../../models/ModelWeight';
class EditFunc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editDialog: false,
            data: {},
            title: ''
        }
        this.model = new ModelWeight()
    }
    //set data when open dialog edit
    openDialogEdit(data) {
        if (data == null) {
            this.setState({
                editDialog: !this.state.editDialog,
                data: {
                    "account" : global.User,
                    // "date": new Date(),
                    "weight": '',
                    "note": "",
                },
                title: 'Add'
            });
        } else {
            // if(data['date'] !== ''){
            //     data['date'] = moment(data['date'], 'YYYY_MM_DD')._d    
            // }
      
            
            this.setState({
                editDialog: !this.state.editDialog,
                data: { ...data },
                title: 'Edit'
            });
        }

    }




    //render button Cancle and Save
    footerEditDialog() {
        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { this.setState({ editDialog: false }); }} />
                <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={() => this.onSave()} />
            </div>
        )
    }

    //setstate data when input change
    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let data = { ...this.state.data };
        data[`${name}`] = val;

        this.setState({ data });
    }

    //call api save
    onSave() {

        let params = { ...this.state.data }
        
        // if(params['date'] != ''){
        //     params['date'] = moment(params['date']).format('YYYY_MM_DD')
        // }
        params['weight'] = this.inputAce.getValue()
 

        if (this.state.title == 'Add') {


            this.model.add({ 'data': params }).then(res => {

                if (res['result']) {
                    this.props.table.getData()
                }
            }
            )


        }
         else {
            this.model.edit({ 'data': params }).then(res => {

                if (res['result']) {
                    this.props.table.getData()
                }
            }
            )
        }

        this.setState({
            editDialog: !this.state.editDialog,
        });

    }
    render() {
        return (
            <Dialog visible={this.state.editDialog} onHide={() => { this.setState({ editDialog: false }); }} header={this.state.title} style={{ width: '50vw' }} footer={() => this.footerEditDialog()}>
                <div className='grid'>

                    <div className='col-6'>
                        <div className="field_cus">
                            <label >Account</label>
                            <InputText disabled  value={this.state.data.account} onChange={(e) => this.onInputChange(e, 'account')} autoFocus />

                        </div>

                    </div>

                    {/* <div className='col-6'>
                        <div className="field_cus">
                            <label >Date</label>
                            <Calendar  value={this.state.data.date} onChange={(e) => this.onInputChange(e, 'date')} dateFormat="dd/mm/yy" />

                        </div>
                    </div> */}

                    <div className='col-12'>
                        <div className="field_cus">
                            <label >Weight</label>
                            <InputAce height={'150px'} ref={c => this.inputAce = c} value={this.state.data.weight}></InputAce>

                        </div>

                    </div>

                


                    <div className='col-12'>
                        <div className="field_cus">
                            <label htmlFor="note">Note</label>
                            <InputTextarea id="note" value={this.state.data.note} onChange={(e) => this.onInputChange(e, 'note')} rows={5} cols={20} />

                        </div>

                    </div>
                </div>







            </Dialog>
        );
    }
}

export default EditFunc;