import Axios from "axios";
import { cmc3_api_gateway } from "../config/config"
import { loading } from "../helpers/default";
import { error_handle } from "../helpers/error";


class Testnet_backtest_pnlModel{
    constructor(){
        this.url = cmc3_api_gateway
    }

    testnet_pnl(data={}){
        loading(true, 'Loading...')
        return Axios.request({
            url: this.url + '/testnet_pnl/read',
            method: "POST",
            data:{...data},
            withCredentials: true, 
        })

            .then(response => {
                loading(false, 'Loading...')
                response = response['data'];
                if(response['result']) return response
                error_handle(response)

            })
            .catch(error => {
                error_handle(error)
            })
    }

    
    backtest_pnl(data={}){
        loading(true, 'Loading...')
        return Axios.request({
            url: this.url + '/backtest_pnl/read',
            method: "POST",
            data:{...data},
            withCredentials: true, 
        })

            .then(response => {
                loading(false, 'Loading...')
                response = response['data'];
                if(response['result']) return response
                error_handle(response)

            })
            .catch(error => {
                error_handle(error)
            })
    }

    backtest_testnet(data={}){
        loading(true, 'Loading...')
        return Axios.request({
            url: this.url + '/backtest_testnet/getChartData',
            method: "POST",
            data:{...data},
            withCredentials: true, 
        })

            .then(response => {
                loading(false, 'Loading...')
                response = response['data'];
                if(response['result']) return response
                error_handle(response)

            })
            .catch(error => {
                error_handle(error)
            })
    }

    getWinate(data={}){
        loading(true, 'Loading...')
        return Axios.request({
            url: this.url + '/backtest_testnet/getWinate',
            method: "POST",
            data:{...data},
            withCredentials: true, 
        })

            .then(response => {
                loading(false, 'Loading...')
                response = response['data'];
                if(response['result']) return response
                error_handle(response)

            })
            .catch(error => {
                error_handle(error)
            })
    }

    getTestnetWinate(data={}){
        loading(true, 'Loading...')
        return Axios.request({
            url: this.url + '/backtest_testnet/testnet_getWinate',
            method: "POST",
            data:{...data},
            withCredentials: true, 
        })

            .then(response => {
                loading(false, 'Loading...')
                response = response['data'];
                if(response['result']) return response
                error_handle(response)

            })
            .catch(error => {
                error_handle(error)
            })
    }

    getForwardtesttWinate(data={}){
        loading(true, 'Loading...')
        return Axios.request({
            url: this.url + '/backtest_testnet/forwardtest_getWinate',
            method: "POST",
            data:{...data},
            withCredentials: true, 
        })

            .then(response => {
                loading(false, 'Loading...')
                response = response['data'];
                if(response['result']) return response
                error_handle(response)

            })
            .catch(error => {
                error_handle(error)
            })
    }

    getTestnetStrategy(data={}){
        loading(true, 'Loading...')
        return Axios.request({
            url: this.url + '/backtest_testnet/testnet_strategy/get',
            method: "POST",
            data:{...data},
            withCredentials: true, 
        })

            .then(response => {
                loading(false, 'Loading...')
                response = response['data'];
                if(response['result']) return response
                error_handle(response)

            })
            .catch(error => {
                error_handle(error)
            })
    }

    getTestnetResult(data={}){
        loading(true, 'Loading...')
        return Axios.request({
            url: this.url + '/backtest_testnet/testnet_result/get',
            method: "POST",
            data:{...data},
            withCredentials: true, 
        })

            .then(response => {
                loading(false, 'Loading...')
                response = response['data'];
                if(response['result']) return response
                error_handle(response)

            })
            .catch(error => {
                error_handle(error)
            })
    }

    


}


export default Testnet_backtest_pnlModel