import React, { Component } from 'react';
import ChartBidAskDetail from '../components/BidAskDetail/ChartBidAskDetail';
import ParagarmBidAskDetail from '../components/BidAskDetail/ParagarmBidAskDetail';
import TableBidAskDetail from '../components/BidAskDetail/TableBidAskDetail';
import ModelDashboard from '../models/ModelDashboard';

class BidAskDetailView extends Component {
    constructor(props) {
        super(props);

        this.model = new ModelDashboard()
    }

    getData(loading){
        this.model.get_data_bid_ask(loading).then(res => {
            let data = JSON.parse(res)
            if(data == null){
                this.paragarm.setData(data)

                return
            }

            if(Object.keys(data['patterns']).length > 0){
                this.table.setData(data['patterns'])
            }

            if(Object.keys(data['data']).length > 0){
                this.chart.setData(data['data'])
            }


         
           
            this.paragarm.setData(data)
        })
    }

    componentDidMount() {
        this.getData(true)

        this.interval = setInterval(() => {
            this.getData(false)
        }, 10000);
        
    }

    componentWillUnmount() {
        if (this.interval) clearInterval(this.interval);
    }

    render() {
        return (
            <div>
                <div className='grid'>

                    <div className='col-9 md:col-9'>
                       <ChartBidAskDetail  ref={c => this.chart = c} ></ChartBidAskDetail>
                    </div>
                    <div className='col-3 md:col-3'>
                      <ParagarmBidAskDetail  ref={c => this.paragarm = c}></ParagarmBidAskDetail>
                    </div>


                </div>
                <TableBidAskDetail ref={c => this.table = c}></TableBidAskDetail>
            </div>
        );
    }
}

export default BidAskDetailView;