import React, { Component } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Ripple } from 'primereact/ripple';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import Model3T from '../../models/Model3T';


class Table3T extends Component {
    constructor(props) {
        super(props);
        this.state = {


            data: []
        }

        this.model = new Model3T()




    }

    componentDidMount(){
        this.getData()
    }
    getData(){
        this.model.getData().then(res=> {
            this.setState({
                data : res.reverse()
            });
        })
    }




    render() {
        return (
            <div className='mt-2'>
                <div className="card">
                    <DataTable
                        className="p-datatable-customers"
                        value={this.state.data}
                        stripedRows
                        paginator responsiveLayout="scroll"
                        paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rows={10} rowsPerPageOptions={[10, 20, 50]}
                    >
                        <Column field="day" header="Day" sortable ></Column>
                        <Column field="VN30" header="Vn30" ></Column>
                        <Column field="VN30_return" header="VN30_return" ></Column>
                        <Column field="returnT1>0" header="Số mã tăng T1" ></Column>
                        <Column field="returnT2>0" header="Số mã tăng T2" ></Column>
                        <Column field="returnT3>0" header="Số mã tăng T3" ></Column>
                        <Column field="returnT4>0" header="Số mã tăng T4" ></Column>
                        <Column field="returnT5>0" header="Số mã tăng T5" ></Column>
                  
                    </DataTable>
                </div>
            </div>
        );
    }
}

export default Table3T;