import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import moment from 'moment';
import ModelFAScore from '../../models/ModelFAScore';
class DelFunc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            delDialog: false,
            data: {}
        }

        this.model = new ModelFAScore()
    }

    //open confirm before delete
    openDialogDel(data) {
        this.setState({
            delDialog: !this.state.delDialog,
            data: { ...data }
        });
    }

    //render button Cancle and Save
    footerDelDialog() {
        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button label="No" icon="pi pi-times" className="p-button-text"  onClick={() => { this.setState({ delDialog: false }); }}  />
                <Button label="Yes" icon="pi pi-check" className="p-button-text"  onClick={() => this.onDel()}  />
           
            </div>
        )
    }

    //call api del
    onDel(){
     
        this.model.del({'id' : this.state.data._id}).then(res => {
            if (res['result']) {
                this.props.table.getData()
            }
        })
        this.setState({
            delDialog: !this.state.delDialog,
        });
    }

    render() {
        return (
            <Dialog visible={this.state.delDialog} style={{ width: '550px' }} header="Confirm" modal onHide={() => { this.setState({ delDialog: false }); }} footer={() => this.footerDelDialog()}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {this.state.data && <span>Are you sure you want to delete with time <b>{moment(this.state.data.time , 'X' ).format('DD-MM-YYYY HH:mm:ss') }</b>?</span>}
                </div>
            </Dialog>
        );
    }
}

export default DelFunc;