import React, { Component } from 'react';
import ExchangePNLModel from '../../models/ExchangePNLModel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { formatNumber } from '../../helpers/default';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { MultiSelect } from 'primereact/multiselect';
class TableWinrate extends Component {
    constructor(props) {
        super(props);

        this.state = {


            data: [],
            dataTemp : [],
            mapCam: {},
            totalPNL : 0,
            optFilterCam : []
        }
        

        this.model = new ExchangePNLModel()

      
    }

    getDataTable(opt){
        this.setState({
            mapCam : opt
        }, () => {
            this.model.table_winrate().then(res => {
                if(res['result']){
                    let totalPNL = 0
                    let optCam = []
                    res['data'].map(item =>{
                        item['campaign'] = this.state.mapCam[ item['campaign']]
                        totalPNL += Number(item['pnl'])
                        optCam.push( { 
                            name: item['campaign'], value: item['campaign']
                         })
                    })
                    this.setState({
                        data : res['data'],
                        dataTemp : res['data'],
                        totalPNL,
                        optFilterCam : optCam
                      
                    });
                }
            })
        });
       
    }

    representativeTemplate = (data, props) => {
      
        if (props.field == "index") {

            return (
                <span>{props.rowIndex + 1}</span>
            )
        }


        let col = ['wprofit' , 'lprofit'  , 'pnl']
        let col1 = ['avg_w' , 'avg_l' , 'max_drawdown']

        if(props.field == 'winrate'){
            let result = (Number(data[props.field]) * 100 ).toFixed(2) + '%'

            return (
                <span>{result}</span>
            )
        }


        if(col.includes(props.field)){
            let result = formatNumber( Math.round(data[props.field]*100)/100 )

            return (
                <span>{result}</span>
            )
        }
    
        if (col1.includes(props.field)) {
    
            let result = (Number(data[props.field]) ).toFixed(2)

            

            return (
                <span>{result}</span>
            )

        }
        if (props.field == "timeStart" ) {
            if(!data[props.field]) return
            let result = moment(data[props.field] , 'x').format('YYYY_MM_DD')
            return (
                <span>{result}</span>
            )
        }
        if (props.field == "campaign" ) {
            let result = this.state.mapCam[data[props.field]]
            return (
                <span>{result}</span>
            )
        }
  


    }


    filterData(data){
        let result
        if(data.length == 0){
            result =  this.state.dataTemp
        }else{
            result = this.state.dataTemp.filter(item => data.includes(item['campaign']));
           
        }

        let totalPNL = 0
        result.map(item =>{
            totalPNL += Number(item['pnl'])
        })
        this.setState({
            data : result,
            totalPNL
        });
      

    }
    representativeRowFilterTemplate(options) {
        return <MultiSelect 
                value={options.value} 
                options={this.state.optFilterCam} 
                onChange={(e) =>  {options.filterCallback(e.value) ; this.filterData(e.value)}} 
                optionLabel="name" 
                placeholder="Any" 
                className="p-column-filter" 
                 />;
    }


    render() {
        let footerGroup = (
            <ColumnGroup>
                <Row >
                    <Column colSpan={8} />
                    <Column footer={formatNumber( Math.round(this.state.totalPNL*100)/100 )} footerStyle={this.state.totalPNL > 0 ? {color : 'limegreen'} : { color : 'red'}} />
                </Row>
            </ColumnGroup>
        );
        return (
            <div className='mt-2'>
                
                <div className="card">
                    <DataTable
                        className="p-datatable-customers"
                        value={this.state.data}
                        stripedRows
                        paginator responsiveLayout="scroll"
                        paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rows={10} rowsPerPageOptions={[10, 20, 50]}
                        // sortField="campaign" sortOrder={-1}
                        header={<div>Exchange</div>}
                        filterDisplay="row"
                        footerColumnGroup={footerGroup}
                        // onValueChange={filteredData => this.cal_pnl_after_filter(filteredData)}
                    >
                        <Column field="index" header="Index" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>
          
                        <Column field="campaign" header="Strategy Name" sortable showFilterMenu={false}  filter filterElement={(options) =>  this.representativeRowFilterTemplate(options)}  ></Column>
                        <Column field="timeStart" header="Start Time" sortable  body={(data, props) => this.representativeTemplate(data, props)}></Column>
                
                        <Column field="wcount" header="Win Trade" sortable ></Column>
                        <Column field="lcount" header="Loss Trade" sortable ></Column>
                        <Column field="total" header="Total Trade" sortable ></Column>
                        <Column field="wprofit" header="Win PNL" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="lprofit" header="Loss PNL" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="pnl" header="PNL" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>

                        <Column field="winrate" header="Winrate" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        {/* <Column field="avg_w" header="Average Win" sortable  body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="avg_l" header="Average Loss" sortable body={(data, props) => this.representativeTemplate(data, props)}  ></Column> */}
                        
          
                    </DataTable>
                </div>
            </div>
        );
    }
}

export default TableWinrate;