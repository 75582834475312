import { authen_url } from "../config/config";

export var isset = (obj)=>{
    if(typeof(obj) == 'undefined' || obj == null ){
        return false;
    }
    return true;
}

export var get = (obj, def) => {
	if(isset(obj)){
		return obj;
	}else{
		return def;
	}
}


export var checkLogin = (error) => {
	if (error.response.status === 401) {
		window.location.href = authen_url + `/#/login?nexLink=${encodeURIComponent(window.location.href)}`
		return false;
	}
}


export var makeId = (min=100, max=10000) => {
	if(typeof max == 'undefined') max = 1000;
	if(typeof min == 'undefined') min = 100;
	return Date.now()+''+Math.floor(Math.random()*max + min);  
}

export var copyToClipboard = (text) => {
    const textarea = document.createElement('textarea');
    document.body.appendChild(textarea);
    textarea.value = text;
    textarea.select();
    textarea.setSelectionRange(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };


export var interval2second = (str)=>{
    const s = 1;
    const m = s * 60;
    const h = m * 60;
    const d = h * 24;
    const w = d * 7;
    const y = d * 365.25;
    if (str.length > 100) {
        throw new Error('Value exceeds the maximum length of 100 characters.');
      }
      const match =
        /^(?<value>-?(?:\d+)?\.?\d+) *(?<type>milliseconds?|msecs?|ms|seconds?|secs?|s|minutes?|mins?|m|hours?|hrs?|h|days?|d|weeks?|w|years?|yrs?|y)?$/i.exec(
          str,
        );
      
      const groups = match?.groups
      if (!groups) {
        return NaN;
      }
      const n = parseFloat(groups.value);
      const type = (groups.type || 'ms').toLowerCase() ;
      switch (type) {
        case 'years':
        case 'year':
        case 'yrs':
        case 'yr':
        case 'y':
          return n * y;
        case 'weeks':
        case 'week':
        case 'w':
          return n * w;
        case 'days':
        case 'day':
        case 'd':
          return n * d;
        case 'hours':
        case 'hour':
        case 'hrs':
        case 'hr':
        case 'h':
          return n * h;
        case 'minutes':
        case 'minute':
        case 'mins':
        case 'min':
        case 'm':
          return n * m;
        case 'seconds':
        case 'second':
        case 'secs':
        case 'sec':
        case 's':
          return n * s;
        case 'milliseconds':
        case 'millisecond':
        case 'msecs':
        case 'msec':
        case 'ms':
          return n;
        default:
          // This should never occur.
          throw new Error(
            `The unit was matched, but no matching case exists.`,
          );
      }
}


export var showLog = (mss, type) => {
	global.toast.show({summary: mss, severity: type})
}


export var loading = (flag, text, update='', process=null) => {
	global.loading.loading(flag, text, update='', process=null)
}

export var checkWorkingTime = () => {
  let date = new Date(new Date().toLocaleString("en-US", {timeZone: "Asia/Ho_Chi_Minh"}))
  let weekday = date.getDay()
  if(weekday == 0 || weekday == 6) return false
  let hour = date.getHours()
  // if(hour < 9 || hour >= 16) return false
  if(hour < 9 || hour >= 15) return false
  return true;
}


function clearNumber(string) {
	if (!string) return 0;
	return string.toString().replace(/[^\d\-\.]/g, '');
}

export var formatNumber = (num) => {
	if (!num) return 0;
	num = clearNumber(num);
	return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


