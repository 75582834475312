import React, { Component } from 'react';
import Arbit_UnwindView from '../components/Dashboard/Arbit_UnwindView';
import BidAskView from '../components/Dashboard/BidAskView';
import BUSD_LongTermView from '../components/Dashboard/BUSD_LongTermView';
import Buy_Sell_PressureView from '../components/Dashboard/Buy_Sell_PressureView';
import FA_indicatorView from '../components/Dashboard/FA_indicatorView';
import IntraDayView from '../components/Dashboard/IntraDayView';
import LongTermView from '../components/Dashboard/LongTermView';
import NetForeignView from '../components/Dashboard/NetForeignView';
import Technical_AnalysisView from '../components/Dashboard/Technical_AnalysisView';
import TnModelView from '../components/Dashboard/TnModelView';


class Dashboard extends Component {

    render() {
        return (
            <div>
                {/* <div className='grid'>

                    <div className='col-12 md:col-6'>
                        <IntraDayView></IntraDayView>
                    </div>
                    <div className='col-12 md:col-6'>
                        <LongTermView></LongTermView>
                    </div>


                </div> */}
                <div className='grid'>

                    <div className='col-12 md:col-3'>
                        <BidAskView></BidAskView>
                    </div>

                    
                    <div className='col-12 md:col-3'>
                        <TnModelView></TnModelView>
                    </div>

                    <div className='col-12 md:col-3'>
                        <FA_indicatorView></FA_indicatorView>
                    </div>


                    {/* <div className='col-12 md:col-3'>
                        <BUSD_LongTermView></BUSD_LongTermView>
                    </div> */}

                </div>
                {/* <div className='grid'>

                    <div className='col-12 md:col-3'>
                        <Arbit_UnwindView></Arbit_UnwindView>
                    </div>
                    <div className='col-12 md:col-3'>
                        <Buy_Sell_PressureView></Buy_Sell_PressureView>
                    </div>
                    <div className='col-12 md:col-3'>
                        <Technical_AnalysisView></Technical_AnalysisView>
                    </div>
                        <div className='col-12 md:col-3'>
                        <NetForeignView></NetForeignView>
                    </div> 
                   
                    
                </div> */}

            </div>
        );
    }
}

export default Dashboard;