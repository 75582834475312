import React, { Component } from 'react';
import ModelBusdScore from '../../models/ModelBusdScore';
import moment from 'moment';
import { get } from '../../helpers/default';
import ModelCtrl from '../../models/ModelCtrl';
class BusdScoreChart extends Component {
    constructor(props) {
        super(props);

        this.model = new ModelBusdScore()
        this.modelCtrl = new ModelCtrl()
        this.mappingData = {}
        this.ctrlData = {}
    }


    async getData() {
        let res = await this.model.getData()
        if (res['result']) {
            let strategyMapping = get(this.mappingData['strategy'], {})
            let strategyIndexMapping = get(this.mappingData['strategy_index'], {})
            let trades = res['data'].sort((a, b) => { return Number(b['testnet_result_chart']) - Number(a['testnet_result_chart']) })
            let chart = {};
            trades.map((item, index) => {
                let startegyName = `<b style="color:red;">[${strategyIndexMapping[item['testnet_result_strategy']]}]</b>  ${get(strategyMapping[item['testnet_result_strategy']], 'Undefined')}`
                let closeTime = item['testnet_result_close_time'] ? item['testnet_result_close_time'] : moment().format('x')
                let score
                if (item['testnet_result_sell_price']) {
                    if (item['testnet_result_type'] == 1) {

                        score = Number(item['testnet_result_sell_price']) - Number(item['testnet_result_chart_price'])
                    } else {
                        score = Number(item['testnet_result_chart_price']) - Number(item['testnet_result_sell_price'])
                    }

                } else {
                    if (item['testnet_result_type'] == 1) {

                        let sellPrice = ((Number(item['testnet_result_profit']) * Number(item['testnet_result_chart_price'])) / 100) + Number(item['testnet_result_chart_price'])
                        score = sellPrice - Number(item['testnet_result_chart_price'])
                    } else {
                        let sellPrice = Number(item['testnet_result_chart_price']) / (Number(item['testnet_result_profit']) / 100 + 1)
                        score = Number(item['testnet_result_chart_price']) - sellPrice
                    }
                }
                let colorTextChange
                if (score.toFixed(1) > 0) {
                    colorTextChange = '#008000c7'
                } else {
                    colorTextChange = '#ff0000ad'
                }

                let name = `_${strategyIndexMapping[item['testnet_result_strategy']]}_${item['testnet_result_flow'].toLowerCase()}`
                let winRate = this.ctrlData[name][`action${name}`]['win_rate'].toFixed(2) + '%'
                chart[index] = {
                    x: [item['testnet_result_chart'], closeTime],
                    y: [startegyName, startegyName],
                    color: item['testnet_result_type'] == 2 ? '#ff0000ad' : '#008000c7',
                    symbol: item['testnet_result_close_time'] ? ['circle', 'square',] : ['circle', 'triangle-right'],
                    text: [winRate, score.toFixed(1)],
                    colorText: ['black', colorTextChange]
                }


            })

            this.renderChart(Object.values(chart))

        }
    }
    async componentDidMount() {

        this.mappingData = await this.model.getMapping()
        let ctrlData = await this.modelCtrl.get('busd_score')

        this.ctrlData = JSON.parse(ctrlData)
        this.getData()
        this.interval = setInterval(() => {
            this.getData()
        }, 30000);
    }
    componentWillUnmount() {
        if (this.interval) clearInterval(this.interval);
    }

    renderChart(chart) {
        var data = [];
        chart.map(item => {
            data.push({
                x: item['x'],
                y: item['y'],
                type: 'scatter',
                line: {
                    color: item['color'],
                    width: 3
                },
                marker: {
                    symbol: item['symbol'],
                    size: 10
                },
                mode: 'markers+lines+text',
                text: item['text'],
                textposition: ['top right', 'top left'],
                textfont: {
                    size: 8,
                    color: item['colorText']
                },
            })
        })

        var layout = {

            height: 350,
            showlegend: false,
            margin: { l: 230, r: 10, t: 50, b: 50 },
            autosize: true,
            hovermode: "x unified",
            hoverdistance: 10,
            hoverlabel: { bgcolor: 'white' },
            barmode: 'group',
            paper_bgcolor: 'rgba(0,0,0,0)',
            // plot_bgcolor : 'rgba(0,0,0,0)',
            title: 'Trạng thái lệnh',
            font: {
                size: 12,
            },
            xaxis: {
                autorange: true,
                type: "date",
                // categoryorder: 'category ascending',
                rangeslider: { 'visible': false },
                showspikes: true,
                spikemode: 'across',
                spikesnap: 'cursor',
                spikedash: 'dot',
                // spikecolor: 'black',
                spikethickness: 2,
                //   tickmode: "array",
                //   tickvals: tickvals,
                //   ticktext: ticktext,

                // showgrid: false,
                //   showticklabels: true,
                tickangle: 'auto',
                showexponent: 'all',
                gridcolor: '#ddd',
                mirror: true,
                ticks: 'outside',
                showline: true,
                linecolor: '#969696',

            },
            yaxis: {
                autorange: true,
                showspikes: true,
                spikemode: 'across',
                spikesnap: 'cursor',
                spikedash: 'dot',
                // spikecolor: 'black',
                spikethickness: 2,
                dtick: 1,
                mirror: true,
                ticks: 'outside',
                showline: true,
                gridcolor: '#ddd',
                linecolor: '#969696',
            },








        };



        global.Plotly.newPlot(this.chart, data, layout);
    }
    render() {
        return (
            <div>
                <div ref={c => this.chart = c}>

                </div>
            </div>
        );
    }
}

export default BusdScoreChart;