import axios from "axios";
import { cmc3_api_gateway } from "../config/config";
import { checkLogin, loading } from "../helpers/default";
import { error_handle } from '../helpers/error'
class ModelPressure {

    constructor() {
        this.url = cmc3_api_gateway
        
    }
   

    filter(startTime , stopTime , aloading=false){
        if(aloading){
            loading(true);
        }
        
        return axios.request({
			url: cmc3_api_gateway + '/bid_ask/filter',
			method: 'POST',
			data: {
                startTime : startTime,
                stopTime : stopTime,
            },
            withCredentials: true,
		})
			.then(response => {
				loading(false, 'Loading...');
				response = response['data'];

				if(response['result']){
                   
                    return response['data'];
                }else{
                    error_handle(response);
                    return false;
                }
			})

			.catch((error) => {
				loading(false, 'Loading...');
				error_handle(error)
				return false;
			})
    }

    filterVn30(startTime , stopTime){
        loading(true , 'Loading...');
        return axios.request({
			url: cmc3_api_gateway + '/pressure/filterVn30',
			method: 'POST',
			data: {
                startTime : startTime,
                stopTime : stopTime,
            },
            withCredentials: true,
            headers: {
                'Accept-Encoding': 'gzip',
            },
		})
			.then(response => {
				loading(false, 'Loading...');
				response = response['data'];

				if(response['result']){
                   
                    return response['data'];
                }else{
                    error_handle(response);
                    return false;
                }
			})

			.catch((error) => {
				loading(false, 'Loading...');
				error_handle(error)
				return false;
			})
    }

    step(startTime , stopTime , aloading=false){
        if(aloading){
            loading(true);
        }
        
        return axios.request({
			url: cmc3_api_gateway + '/bid_ask/step',
			method: 'POST',
			data: {
                startTime : startTime,
                stopTime : stopTime,
            },
            withCredentials: true,
		})
			.then(response => {
				loading(false, 'Loading...');
				response = response['data'];

				if(response['result']){
                   
                    return response['data'];
                }else{
                    error_handle(response);
                    return false;
                }
			})

			.catch((error) => {
				loading(false, 'Loading...');
				error_handle(error)
				return false;
			})
    }


    






}

export default ModelPressure;