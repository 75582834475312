import React, { Component } from 'react';
import BusdScoreChart from '../components/BusdScore/BusdScoreChart';
import BusdScoreTable from '../components/BusdScore/BusdScoreTable';

class BusdScoreView extends Component {
    render() {
        return (
            <div>
                <BusdScoreChart></BusdScoreChart>
                <BusdScoreTable></BusdScoreTable>
            </div>
        );
    }
}

export default BusdScoreView; 