import Axios from "axios";
import { cmc3_api_gateway } from "../config/config"
import { loading } from "../helpers/default";
import { error_handle } from "../helpers/error";


class ModelWeight{
    constructor(){
        this.url = cmc3_api_gateway
    }

    filter(data={}){
        loading(true, 'Loading...')
        return Axios.request({
            url: this.url + '/weight/filter',
            method: "POST",
            data:{...data},
            withCredentials: true, 
        })

            .then(response => {
                loading(false, 'Loading...')
                response = response['data'];
                if(response['result']) return response
                error_handle(response)
                return response

            })
            .catch(error => {
                error_handle(error)
            })
    }
    read(data){
        loading(true, 'Loading...')
        return Axios.request({
            url: this.url + '/weight/read',
            method: "POST",
            data:{...data},
            withCredentials: true, 
        })

            .then(response => {
                loading(false, 'Loading...')
                response = response['data'];
                if(response['result']) return response
                error_handle(response)
                return response

            })
            .catch(error => {
                error_handle(error)
            })
    }
    add(data={}){
        loading(true, 'Loading...')
        return Axios.request({
            url: this.url + '/weight/add',
            method: "POST",
            data:{...data},
            withCredentials: true, 
        })

            .then(response => {
                loading(false, 'Loading...')
                response = response['data'];
                if(response['result']) return response
                error_handle(response)
                return response

            })
            .catch(error => {
                error_handle(error)
            })
    }

    edit(data={}){
        loading(true, 'Loading...')
        return Axios.request({
            url: this.url + '/weight/edit',
            method: "POST",
            data:{...data},
            withCredentials: true, 
        })

            .then(response => {
                loading(false, 'Loading...')
                response = response['data'];
                if(response['result']) return response
                error_handle(response)
                return response

            })
            .catch(error => {
                error_handle(error)
            })
    }

    del(data={}){
        loading(true, 'Loading...')
        return Axios.request({
            url: this.url + '/weight/delete',
            method: "POST",
            data:{...data},
            withCredentials: true, 
        })

            .then(response => {
                loading(false, 'Loading...')
                response = response['data'];
                if(response['result']) return response
                error_handle(response)
                return response

            })
            .catch(error => {
                error_handle(error)
            })
    }


    


}


export default ModelWeight