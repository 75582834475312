import React, { Component } from 'react';
import ChartPNL from '../components/Testnet_backtest_pnl/ChartPNL';
import { showLog } from '../helpers/default';
import { error_handle } from '../helpers/error';
import Testnet_backtest_pnlModel from '../models/Testnet_backtest_pnlModel';
import Testnet_account from '../models/Testnet_account';
import moment from 'moment'
import TableWinrate from '../components/Testnet_backtest_pnl/TableWinrate';
import TableTestnetWinrate from '../components/Testnet_backtest_pnl/TableTestnetWinrate';
import ModelCtrl from '../models/ModelCtrl';
import TableForwardtestWinrate from '../components/Testnet_backtest_pnl/TableForwardtestWinrate';
class TestnetBactestPNLView extends Component {
    constructor(props) {
        super(props);

        this.model = new Testnet_backtest_pnlModel()
        this.modelAccount = new Testnet_account()

        
    }

    getCtrl() {
        let model = new ModelCtrl()

        return model.get('testnet_backtest_mapping').then(res => {
            return res
        })
    }

 
    getMappingAccount(){
        let mapAccount = {}
        return this.modelAccount.get().then(res => {
            if(res['result']){
                // console.log(res)
                res['data'].map(item => {
                    mapAccount[item['testnet_account_id']] = {
                        'name' : item['testnet_account_name'],
                        'note' : item['testnet_account_note']
                    }
                })

                return mapAccount
            }else{
                error_handle(res)
            }
        })
    }

  
    async componentDidMount(){
        let mapAccount = await this.getMappingAccount()

        let data = await this.model.backtest_testnet()
        if(data['result']){
            this.ChartPNL.setData(data['data'] , mapAccount)
        }else {
            error_handle(data)
        }

        let ctrl = await this.getCtrl()

        ctrl = JSON.parse(ctrl)

        let dataWinrate = await this.model.getWinate()
        let dataTestnetWinrate = await this.model.getTestnetWinate()

        let dataForwardtesttWinate = await this.model.getForwardtesttWinate()


        if(dataWinrate['result']){
            this.TableWinrate.setData(dataWinrate['data'] , mapAccount , ctrl )
        }else {
            error_handle(dataWinrate)
        }

        if(dataForwardtesttWinate['result']){
            this.TableForwardtestWinrate.setData(dataForwardtesttWinate['data'] , mapAccount , ctrl )
        }else {
            error_handle(dataForwardtesttWinate)
        }
        if(dataTestnetWinrate['result']){
            this.TableTestnetWinrate.setData(dataTestnetWinrate['data'] , mapAccount)
        }else {
            error_handle(dataTestnetWinrate)
        }
  


      
    }
    render() {
        return (
            <div>
                <ChartPNL ref={c => this.ChartPNL = c}></ChartPNL>
                <TableTestnetWinrate ref={c => this.TableTestnetWinrate = c} ></TableTestnetWinrate>
                <TableForwardtestWinrate ref={c => this.TableForwardtestWinrate = c} ></TableForwardtestWinrate>
                <TableWinrate ref={c => this.TableWinrate = c} ></TableWinrate>
            </div>
        );
    }
}

export default TestnetBactestPNLView;