import React, { Component } from 'react';
import { Checkbox } from 'primereact/checkbox';
import Input from '../Input_v2/Input';
import { Button } from 'primereact/button';
import ModelPressure from '../../models/ModelPressure';
import ModelVn30 from '../../models/ModelVn30';
import moment from 'moment';
import { get, loading } from '../../helpers/default';

/**
 * defaultTimeLength: 1
 * defaultStartTime: Date()
 * struct : [
 *  {
 *      x: 'timestamp',
 *      xDecorator: moment(, 'x'),
 *      y1: [] or all(default),
 *      y2: [] or all or false(default),
 *      yExcept: []
 *      yDecorator: (datas, rowID) => {},
 *      datas: [{}, {}]
 *      dataSource: (startTime, stopTime) => {return [{}, {}]}
 *      domain: [0,1]
 *  }
 * ]
 * 
 */
class ChartStruct extends Component {
    
    constructor(props) {
        super(props);
        this.id = this.props.id
        this.storageStartTime = `${this.id}_startTime`
        this.storageTimeLength = `${this.id}_timeLength`
        let defaultTimeLength = this.props.defaultTimeLength ? this.props.defaultTimeLength : 1
        let defaultStartTime = this.props.defaultStartTime ? new Date(this.props.defaultStartTime) : new Date()
        this.state = {
            timeLeng: localStorage.getItem(this.storageTimeLength) ? localStorage.getItem(this.storageTimeLength) : defaultTimeLength,
            startTime: localStorage.getItem(this.storageStartTime) ? moment(localStorage.getItem(this.storageStartTime), 'x').toDate() : defaultStartTime,
            startTime: defaultStartTime,
        }

        this.struct = [
            {
                x: 'timestamp',
                datas: [
                    {timestamp: 1663553085, totalVolBuys: 575.45, totalVolSells: 706},
                    {timestamp: 1663553100, totalVolBuys: 607.75, totalVolSells: 709.1},
                    {timestamp: 1663553115, totalVolBuys: 631.85, totalVolSells: 717.65},
                    {timestamp: 1663553130, totalVolBuys: 651.55, totalVolSells: 729.05},
                    {timestamp: 1663553145, totalVolBuys: 666.05, totalVolSells: 733.7},
                    {timestamp: 1663553160, totalVolBuys: 674.45, totalVolSells: 739.95},
                    {timestamp: 1663553175, totalVolBuys: 678.1, totalVolSells: 743.7},
                    {timestamp: 1663553190, totalVolBuys: 665.85, totalVolSells: 745.1},
                ],
                domain: [0.5, 1]
            },

            {
                x: 'timestamp',
                y1:['totalVolBuys'],
                datas: [
                    {timestamp: 1663553085, totalVolBuys: 575.45, totalVolSells: 706},
                    {timestamp: 1663553100, totalVolBuys: 607.75, totalVolSells: 709.1},
                    {timestamp: 1663553115, totalVolBuys: 631.85, totalVolSells: 717.65},
                    {timestamp: 1663553130, totalVolBuys: 651.55, totalVolSells: 729.05},
                    {timestamp: 1663553145, totalVolBuys: 666.05, totalVolSells: 733.7},
                    {timestamp: 1663553160, totalVolBuys: 674.45, totalVolSells: 739.95},
                    {timestamp: 1663553175, totalVolBuys: 678.1, totalVolSells: 743.7},
                    {timestamp: 1663553190, totalVolBuys: 665.85, totalVolSells: 745.1},
                ],
                domain: [0, 0.5]
            }
        ]

        this.createChartLayout()
    }


    createChartLayout(){
        var layout = {
            title: get(this.props.title, ''),
            height: get(this.props.height, 1000),
            showlegend: true,
            margin: get(this.props.margin, { b: 100, t: 40 }),
            autosize: true,
            hovermode: "x unified",
            hoverdistance: 10,
            barmode: 'group',
            font: {
                size: 12,
            },
            xaxis: {
                autorange: true,
                type: "category",
                categoryorder: 'category ascending',
                rangeslider: { 'visible': false },
                showspikes: true,
                spikemode: 'across',
                spikesnap: 'cursor',
                spikedash: 'dot',
                // spikecolor: 'black',
                spikethickness: 2,
                //   tickmode: "array",
                //   tickvals: tickText,
                //   ticktext: tickText,

                // showgrid: false,
                //   showticklabels: true,
                tickangle: 'auto',
                showexponent: 'all'
                // gridcolor: 'white',
            },

        
            // yaxis: {
            //     autorange: true,
            //     showspikes: true,
            //     spikemode: 'across',
            //     spikesnap: 'cursor',
            //     spikedash: 'dot',
            //     // spikecolor: 'black',
            //     spikethickness: 2,
            //     domain: [0.42, 1]
            // },

            // yaxis2: { domain: [0, 0.4] },
            // yaxis3: {
            //     overlaying: 'y2',
            //     side: 'right',
            //   },



            grid: {
                rows: this.struct.length,
                columns: 1,
                subplots: [],
                roworder: 'top to bottom',
                pattern: 'independent'
            }




        };


        //Add y axis
        for(let index in this.struct){
            let str = this.struct[index]
            let yindex = (Number(index) * 2 + 0)
            layout['yaxis'+ yindex] = {
                    autorange: true,
                    showspikes: true,
                    spikemode: 'across',
                    spikesnap: 'cursor',
                    spikedash: 'dot',
                    // spikecolor: 'black',
                    spikethickness: 2,
                    domain: get(str['domain'], [0,1])
                }

            if(str['y2'] !== false && str['y2'] !== undefined){
                layout['yaxis'+(yindex + 1)] = {
                    overlaying: 'y' + yindex,
                    side: 'right',
                }
            }
            layout['grid']['subplots'].push('xy' + yindex)


        }

        console.log(layout)

    }


    componentDidMount() {
        
    }
    handleDataVn30(res) {
        let cs = {
            x: [],
            y: [],
        }
        let ps = {
            x: [],
            y: [],
        }

        res.map(item => {
            let day = moment(item['timestamp'], 'X').format('YYYY/MM/DD HH:mm:ss')
            cs['x'].push(day)
            cs['y'].push(item['price'])
            ps['x'].push(day)
            ps['y'].push(item['ps'])
        })

        return {
            cs, ps
        }
    }

    renderChart(chart, vn30) {
        

        var data = []
        Object.keys(chart).map(stock => {
            if (stock == 'total') {
                data.push({
                    x: chart[stock]['day'],
                    y: chart[stock]['buyPressure'],
                    name: 'Total BuyPressure'
                })
                data.push({
                    x: chart[stock]['day'],
                    y: chart[stock]['sellPressure'],
                    name: 'Total SellPressure'
                })
            }


        })
        var cs = {
            x: vn30['cs']['x'],
            y: vn30['cs']['y'],
            type: 'scatter',
            xaxis: 'x',
            yaxis: 'y2',
            name: 'VnIndex Cs'
        };
        var ps = {
            x: vn30['ps']['x'],
            y: vn30['ps']['y'],
            type: 'scatter',
            xaxis: 'x',
            yaxis: 'y2',
            name: 'VnIndex Ps'
        };

        data.push(cs, ps)

        var layout = {
            title: 'Pressure Vn30',
            height: 800,
            showlegend: true,
            margin: { b: 100, t: 40 },
            autosize: true,
            hovermode: "x unified",
            hoverdistance: 10,
            barmode: 'group',
            font: {
                size: 12,
            },
            xaxis: {
                autorange: true,
                type: "category",
                categoryorder: 'category ascending',
                rangeslider: { 'visible': false },
                showspikes: true,
                spikemode: 'across',
                spikesnap: 'cursor',
                spikedash: 'dot',
                // spikecolor: 'black',
                spikethickness: 2,
                //   tickmode: "array",
                //   tickvals: tickText,
                //   ticktext: tickText,

                // showgrid: false,
                //   showticklabels: true,
                tickangle: 'auto',
                showexponent: 'all'
                // gridcolor: 'white',
            },
            yaxis: {
                autorange: true,
                showspikes: true,
                spikemode: 'across',
                spikesnap: 'cursor',
                spikedash: 'dot',
                // spikecolor: 'black',
                spikethickness: 2,
                domain: [0.42, 1]
            },

            yaxis2: { domain: [0, 0.4] },
            // yaxis3: {
            //     overlaying: 'y2',
            //     side: 'right',
            //   },



            grid: {
                rows: 2,
                columns: 1,
                subplots: [['xy'], ['xy2']],
                roworder: 'top to bottom',
                pattern: 'independent'
            }




        };

        

        return global.Plotly.newPlot(this.chart, data, layout);
        
    }
    changeStock(e) {
       
            let selectedStock = [...this.state.stockSelect];

            if (e.checked)
                selectedStock.push(e.value);
            else
                selectedStock.splice(selectedStock.indexOf(e.value), 1);

            this.setState({ stockSelect: selectedStock }, () => {
                this.filterData()
            });
       
        


    }
    changeAll(e) {
        console.log(e.checked)
        if (e.checked) {
            this.setState({
                checkAll: e.checked,
                stockSelect: this.state.stock
            }, () => this.filterData());

        } else {
            this.setState({
                checkAll: e.checked,
                stockSelect: []
            }, () => this.filterData());

        }

    }
    async getData() {
        let startTime = moment(this.state.startTime).format('X')
        let stopTime = moment(this.state.startTime).add(this.state.timeLeng, 'd').format('X')

        let data = await this.model.filterVn30(startTime, stopTime)

        let chart = this.handleData(data , false)


        let dataVn30 = await this.modelVn30.filter(startTime, stopTime)
        let vn30 = this.handleDataVn30(dataVn30)
        this.setState({
            data, vn30
        });
        this.renderChart(chart, vn30)

    }

    filterData() {
        loading(true, 'Process data...')
        setTimeout(()=>{
            let chart = this.handleData(this.state.data , true)
            return this.renderChart(chart, this.state.vn30).then(()=>{
                loading(false)
            })
        }, 100)
        
      
        
    }

    handleData(data , isFilterLocal) {
   
        
        let chart = {
            'total': {
                'buyPressure': [],
                'sellPressure': [],
                'day': []
            }
        }

        let totalStock = []
        
        for (let i = 0; i < data.length ; i++) {

            const item = data[i];
            let stock = item['stock']
            if(!totalStock.includes(stock)) totalStock.push(stock)

            var isCheck = true

            if(!this.state.stockSelect.includes(stock)){
                isCheck = false
            }

            if(!isFilterLocal && this.state.stockSelect.length == 0){
                isCheck = true
            }

            if(isCheck){
            
                let day = moment(item['timestamp'], 'X').format('YYYY/MM/DD HH:mm:ss')
               
                let buyPressure = Number(item['buyPressure'])
                let sellPressure = Number(item['sellPressure'])
                if (!chart[stock]) {
                    chart[stock] = {
                        'buyPressure': [],
                        'sellPressure': [],
                        'day': []
                    }
                }
                chart[stock]['day'].push(day)
                chart[stock]['buyPressure'].push(buyPressure)
                chart[stock]['sellPressure'].push(sellPressure)
                let index = chart['total']['day'].indexOf(day)
                if (index > -1) {
                    chart['total']['buyPressure'][index] += buyPressure
                    chart['total']['sellPressure'][index] += sellPressure
                } else {
    
                    chart['total']['day'].push(day)
                    chart['total']['buyPressure'].push(buyPressure)
                    chart['total']['sellPressure'].push(sellPressure)
                }
            }else{
                continue;
            }

           

        }
     
        if(!isFilterLocal){
            let state = {
                stock: totalStock.sort(),
                checkAll: true
                
            }
            if(this.state.stockSelect.length == 0){
                state['stockSelect'] = totalStock.sort()
            }

            this.setState(state);
        }
        return chart
    }
    render() {
        return <></>
        return (
            <>
                <div className='flex' style={{ marginBottom: '10px' }}>

                    <Input DateFormat={'dd/mm/yyyy'} type='date' Direct={true} value={this.state.startTime} placeholder="Start Time" OnChange={(val) => {
                        val = Number(val)
                        localStorage.setItem(this.storageStartTime, val);
                        this.setState({ startTime: val })
                    }}></Input>
                    &nbsp;
                    <Input className='input' style={{ width: 50, padding: 6 }} type='number' Direct={true} value={this.state.timeLeng} placeholder="Days" OnChange={(val) => {
                        val = Number(val)
                        localStorage.setItem(this.storageTimeLength, val);
                        this.setState({ timeLeng: val })
                    }}></Input>
                    &nbsp;

                    <Button style={{ padding: '0px 5px' }} onClick={() => {
                        this.getData()
                    }}>Apply</Button>
                </div>
                <div className='card' style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div className="field-checkbox" style={{ marginRight: '20px', width: '50px' }}>
                        <Checkbox inputId="All" value="All" onChange={(e) => this.changeAll(e)} checked={this.state.checkAll} />
                        <label htmlFor="All">ALL</label>
                    </div>
                    {
                        this.state.stock.map(item => {
                            return (
                                <div key={item} className="field-checkbox" style={{ marginRight: '20px', width: '50px' }}>
                                    {/* checked={this.state.cities.indexOf('Chicago') !== -1} */}
                                    <Checkbox inputId={item} value={item} onChange={(e) => this.changeStock(e)} checked={this.state.stockSelect.indexOf(item) !== -1} />
                                    <label htmlFor={item}>{item}</label>
                                </div>
                            )
                        })
                    }
                </div>

                <div ref={c => this.chart = c}>
                </div>
            </>

        );
    }
}

export default ChartStruct;