import React, { Component } from 'react';

class ChartBidAskDetail extends Component {

    setData(data) {

        let price = {
            x: data['time'],
            y: data['last'],
        }
        let BidV = {
            x: data['time'],
            y: data['totalBidVolume'],
        }
        let AskV = {
            x: data['time'],
            y: data['totalOfferVolume'],
        }
        let netBA = {
            x: data['time'],
            y: data['NetBA'],
        }

        this.renderChart(price , BidV , AskV , netBA)

    }

    renderChart(price , BidV,AskV , netBA) {

        let tickText = []

        price.x.map((item, index) => {
            if (index % 75 == 0) {
                tickText.push(item)
            }
        })
        var priceData = {
            x: price.x,
            y: price.y,
            type: 'scatter',
            name: 'Price',
        };
        var BidVData = {
            x: BidV.x,
            y: BidV.y,
            type: 'scatter',
            name: 'BidV',
            xaxis: 'x',
            yaxis: 'y2',
            line: {
                color: '#3cdd96',
            }
        };
        var AskVData = {
            x: AskV.x,
            y: AskV.y,
            type: 'scatter',
            name: 'AskV',
            xaxis: 'x',
            yaxis: 'y2',
            line: {
                color: '#ff5858',
            }
        };
        var netBAData = {
            x: netBA.x,
            y: netBA.y,
            type: 'scatter',
            name: 'NetBA',
            xaxis: 'x',
            yaxis: 'y2',
            line: {
                color: '#ffa758',
            }
        };



        var dataChart = [priceData , BidVData ,AskVData , netBAData];

        var layout = {
            title: 'F1 Bid Ask',
            height: 400,
            // width : '100%',
            showlegend: true,
            margin: { b: 40, t: 40 },
            autosize: true,
            hovermode: "x unified",
            hoverdistance: 10,
            barmode: 'group',
            font: {
                size: 12,
            },
            xaxis: {
                autorange: true,
                type: "category",
                categoryorder: 'category ascending',
                rangeslider: { 'visible': false },
                showspikes: true,
                spikemode: 'across',
                spikesnap: 'cursor',
                spikedash: 'dot',
                spikethickness: 2,
                tickmode: "array",
                tickvals: tickText,
                ticktext: tickText,

                tickangle: 'auto',
                showexponent: 'all'
            },
            yaxis: {
                autorange: true,
                showspikes: true,
                spikemode: 'across',
                spikesnap: 'cursor',
                spikedash: 'dot',
                spikethickness: 2,

            },
            yaxis2: {
                overlaying: 'y',
                side: 'right',
              },




        };
        var config = {responsive: true}
        global.Plotly.newPlot(this.chart, dataChart, layout , config);
    }
    render() {
        return (
            <div ref={c => this.chart = c}>

            </div>
        );
    }
}

export default ChartBidAskDetail;