import React, { Component } from "react";
import ModelDashboardV2 from "../../../models/ModelDashboardV2";
import moment from "moment";
class BusdChart extends Component {

    constructor(props) {
        super(props);
        
        this.model = new ModelDashboardV2() 
    }

    findClosestTime(inputTime, data) {
        const inputMoment = moment(inputTime, "HH:mm:ss");
        if(data.length ==0) {
            return inputTime 
        }
        let closestTime = null;
        for (let i = 0; i < data.length; i++) {
            const currTime = moment(data[i], "HH:mm:ss");
            if (currTime.isAfter(inputMoment)) {
                closestTime = data[i];
                break;
            }
        }
        if (!closestTime) {
            const lastTime = data[data.length - 1];
            const lastMoment = moment(lastTime, "HH:mm:ss");
            if (inputMoment.isAfter(lastMoment)) {
                closestTime = inputTime;
            } else {
                closestTime = data.reduce((prev, curr) => {
                    const currMoment = moment(curr, "HH:mm:ss");
                    const prevMoment = moment(prev, "HH:mm:ss");
                    const currDiff = Math.abs(currMoment.diff(inputMoment));
                    const prevDiff = Math.abs(prevMoment.diff(inputMoment));
                    return currDiff < prevDiff ? curr : prev;
                });
            }
        }
        return closestTime;
    }
    
 
    renderChart(BU_5min, SD_5min, BUSD_Point_5min) {

        const startTime = moment('09:00:00', 'HH:mm:ss');
        const endTime = moment('09:15:00', 'HH:mm:ss');

        const timeArrayX = [];
        const timeArrayY = [];
        for (let m = moment(startTime); m.isBefore(endTime); m.add(Math.floor(Math.random() * 3) + 1, 's')) {
            timeArrayX.push(m.format('HH:mm:ss'));
            timeArrayY.push(0)
        }

        BU_5min['x'] = [...timeArrayX , ...BU_5min['x']]
        BU_5min['y'] = [...timeArrayY , ...BU_5min['y']]
        SD_5min['x'] = [...timeArrayX , ...SD_5min['x']]
        SD_5min['y'] = [...timeArrayY , ...SD_5min['y']]
        BUSD_Point_5min['x'] = [...timeArrayX , ...BUSD_Point_5min['x']]
        BUSD_Point_5min['y'] = [...timeArrayY , ...BUSD_Point_5min['y']]

        let timeArrayTran = ['09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '13:30', '14:00', '14:30', '15:00']
        let timeArray = []
        timeArrayTran.map((item, idx) => {
            let tran = item + ':00'
            if (BU_5min['x'].indexOf(tran) > -1) {
                timeArray[idx] = tran
            } else {
                let a = this.findClosestTime(tran, BU_5min['x'])
                timeArray[idx] = a
            }
        })

        var BidVData = {
            x: BU_5min.x,
            y: BU_5min.y,
            type: 'scatter',
            name: 'BU_5min',
            xaxis: 'x',
            yaxis: 'y2',
            line: {
                color: '#3cdd96',
            }
        };
        var AskVData = {
            x: SD_5min.x,
            y: SD_5min.y,
            type: 'scatter',
            name: 'SD_5min',
            xaxis: 'x',
            yaxis: 'y2',
            line: {
                color: '#ff5858',
            }
        };
        var netBAData = {
            x: BUSD_Point_5min.x,
            y: BUSD_Point_5min.y,
            type: 'scatter',
            name: 'NetBUSD_5min',
            xaxis: 'x',
            yaxis: 'y2',
            line: {
                color: '#856dfc',
            }
        };

        var dataChart = [BidVData ,AskVData, netBAData];

        var layout = {
            title: "BUSD",
            height: 300,
            // width : '100%',
            showlegend: true,
            margin: { b: 40, t: 40, left: 0,  pad: 10 },
            autosize: true,
            hovermode: "x unified",
            hoverdistance: 10,
            barmode: "group",
            font: {
                size: 12,
            },
            xaxis: {
                autorange: true,
                type: "category",
                categoryorder: "category ascending",
                rangeslider: { visible: false },
                showspikes: true,
                spikemode: "across",
                spikesnap: "cursor",
                spikedash: "dot",
                spikethickness: 2,
                tickmode: "array",
                tickvals: timeArray,
                ticktext: timeArrayTran,

                tickangle: "auto",
                showexponent: "all",
            },
            yaxis: {
                autorange: true,
                showspikes: true,
                spikemode: "across",
                spikesnap: "cursor",
                spikedash: "dot",
                spikethickness: 2,
            },
            yaxis2: {
                overlaying: "y",
                side: "right",
            },
            legend: {
                x: 0,
                // xanchor: 'right',
                y: 1.1,
                "orientation": "h"
            }
        };
        var config = { responsive: true };
        global.Plotly.newPlot(this.chart, dataChart, layout, config);
    }




    loadData(BU_5min, SD_5min, BUSD_Point_5min){
        this.renderChart(BU_5min, SD_5min , BUSD_Point_5min) 
    }

    updateXAxis(data){
        let timeArrayTran = ['09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '13:30', '14:00', '14:30', '15:00']
        let timeArray = []
        timeArrayTran.map((item, idx) => {
            let tran = item + ':00'
            if (data.indexOf(tran) > -1) {
                timeArray[idx] =tran
            } else {
                let a = this.findClosestTime(tran, data)
                timeArray[idx] = a
            }
        })
        let layout = this.chart.layout
        layout.xaxis.ticktext =  timeArrayTran
        layout.xaxis.tickvals = timeArray
        global.Plotly.relayout(this.chart, layout);

    }



    componentDidMount() {
  
    }

    render() {
        return (
            <div style={{ width: "100%", marginLeft : -5 }}>
                <div ref={(c) => (this.chart = c)}></div>
            </div>
        );
    }
}

export default BusdChart;
