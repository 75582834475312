import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


import moment from 'moment';
class TableForwardtestWinrate extends Component {
    constructor(props) {
        super(props);
        this.state = {


            data: [],
            ctrl : [],
            mapAccount: {},
        }


      


    }

    componentDidMount() {
       
    }
    setData(data , mapAccount , ctrl) {
        
        this.setState({
            ctrl,
            data,
            mapAccount,
        });
    }

    getTestnetname(name){
        
        for (let i = 0; i < this.state.ctrl.length; i++) {
            
            const element = this.state.ctrl[i];

            if(element['backtest'] == name){
                return this.state.mapAccount[element['testnet']]['name']
            }
            
        }
       
    }

    representativeTemplate = (data, props) => {
      
        if (props.field == "index") {

            return (
                <span>{props.rowIndex + 1}</span>
            )
        }


        let col = ['wprofit' , 'lprofit'  , 'profit']
        let col1 = ['avg_w' , 'avg_l' , 'max_drawdown']

        if(props.field == 'winrate'){
            let result = (Number(data[props.field]) * 100 ).toFixed(2) + '%'

            return (
                <span>{result}</span>
            )
        }


        if(col.includes(props.field)){
            let result = (Number(data[props.field]) ).toFixed(2) + '%'

            return (
                <span>{result}</span>
            )
        }
    
        if (col1.includes(props.field)) {
    
            let result = (Number(data[props.field]) ).toFixed(2)

            

            return (
                <span>{result}</span>
            )

        }
        if (props.field == "timeStart" || props.field == 'timeEnd') {
            let result = moment(data[props.field] , 'X').format('YYYY_MM_DD')
            return (
                <span>{result}</span>
            )
        }
        if (props.field == "strategy_name" ) {
            let result =this.getTestnetname(data[props.field])
            return (
                <span>{result}</span>
            )
        }
  


    }




    render() {
        return (
            <div className='mt-2'>
                
                <div className="card">
                    <DataTable
                        className="p-datatable-customers"
                        value={this.state.data}
                        stripedRows
                        paginator responsiveLayout="scroll"
                        paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rows={10} rowsPerPageOptions={[10, 20, 50]}
                        sortField="strategy_name" sortOrder={-1}
                        header={<div>Forwardtest</div>}
                    >
                        <Column field="index" header="Index" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="strategy_name" header="Strategy Name" sortable  body={(data, props) => this.representativeTemplate(data, props)}></Column>
                        <Column field="timeStart" header="Start Time" sortable  body={(data, props) => this.representativeTemplate(data, props)}></Column>
                        <Column field="timeEnd" header="End Time" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="wcount" header="Win Trade" sortable ></Column>
                        <Column field="lcount" header="Loss Trade" sortable ></Column>
                        <Column field="total" header="Total Trade" sortable ></Column>
                        <Column field="wprofit" header="Win profit" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="lprofit" header="Loss Profit" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="profit" header="Profit" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>

                        <Column field="winrate" header="Winrate" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="max_drawdown" header="Max Drawdown" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="avg_w" header="Average Win" sortable  body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="avg_l" header="Average Loss" sortable body={(data, props) => this.representativeTemplate(data, props)}  ></Column>
                        
          
                    </DataTable>
                </div>
            </div>
        );
    }
}

export default TableForwardtestWinrate;