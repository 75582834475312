import React, { Component } from 'react';
import './index.scss'
class BUSDChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: '',
            arrowX : -1008.734,
            arrowL : -595.734,
            textS : 10.667,
            textL : 365.667,

           
        }
    }

    setContent(content , value) {
        // debugger
        let arrowX
        let textS
        if(content.includes('Short')){
            arrowX = Number(this.state.arrowX) + 4.16 * (100 -Number(value))
            textS = Number(this.state.textS) + 4 * (100 -Number(value))
        }else{
            arrowX = Number(this.state.arrowL) - 3.7 * (100 -Number(value))
            textS = Number(this.state.textL) - 2.7 * (100 -Number(value))

        } 
        
        this.setState({
            content: content,
            arrowX,
            textS
        });
    }

    renderClass(){

        if( this.state.content.includes('Long')){
            if(this.props.oneline){
                return 'cls-cus-online'
            }
            return 'cls-cus'
        }
        return 'cls-6'
    }

    render() {
        return (
            <div className='grid chart_box'>
                <div className='col-4' style={{ display: 'flex', alignItems: 'center' , fontSize : '10px' }}>
                    <b>{this.props.title}</b>
                </div>
                <div className='col-8 '>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 470.54 126.639" style={{overflow : 'unset'}}>
                        <defs>
                            <style dangerouslySetInnerHTML={{ __html: "\n      .cls-1 {\n        fill: url(#linear-gradient);\n      }\n\n      .cls-2 {\n        fill: red;\n      }\n\n      .cls-3 {\n        fill: #fff;\n      }\n\n      .cls-4 {\n        fill: #00a364;\n      }\n\n      .cls-5, .cls-6 {\n        fill: #e35332;\n      }\n\n      .cls-6   {\n        font-size: 45px;\n        font-family: SFPro-Medium, SF Pro;\n        font-weight: 500;\n      }\n  .cls-cus   {\n        font-size: 45px;\n        font-family: SFPro-Medium, SF Pro;\n        font-weight: 500;\n        fill: #00a364;\n      }\n  .cls-green {\n        fill: #00a364;\n      }\n\n  " }} />

                            <linearGradient id="linear-gradient" y1="0.5" x2={1} y2="0.5" gradientUnits="objectBoundingBox">
                                <stop offset="0.1" stopColor="#c5262d" />
                                <stop offset="0.3" stopColor="#e74e2b" />
                                <stop offset="0.5" stopColor="#a2a4a0" />
                                <stop offset="0.7" stopColor="#77bc41" />
                                <stop offset="0.9" stopColor="#349f46" />
                            </linearGradient>
                        </defs>
                        <g id="Group_21" data-name="Group 21" transform="translate(-83.176 -3830.588)">
                            <rect id="Rectangle_9" data-name="Rectangle 9" className="cls-1" width="448.589" height="37.483" transform="translate(96.81 3908.88)" />
                            <g id="Group_17" data-name="Group 17" transform="translate(83.176 3887.99)">
                                <path id="Path_27" data-name="Path 27" className="cls-2" d="M110.463,4196.945s21.8,2.908,23.869,12.634c1.378,6.47,11.562,6.434,17.343,9.65,0,0-1.119,12.028-2.937,13.286,0,0-17.3,7.374-22.838,7.235a42.323,42.323,0,0,1-9.094-1.227l-4.851,6.3H98.388l-5.454-10.07v-36.5s12.17-12.494,15.524-12.867c3.074-.341,3.377,7.682,1.961,14.772" transform="translate(-91.655 -4184.096)" />
                                <path id="Path_28" data-name="Path 28" className="cls-3" d="M104.108,4237.644H89.146l-5.97-11.349v-37.024l.363-.374c2.93-3.006,12.762-12.853,16.3-13.246a2.911,2.911,0,0,1,2.46.957c1.837,2,1.953,6.74,1.553,10.879,5.34.933,21.358,4.453,23.255,13.366.76,3.566,5.1,4.843,9.693,6.193a35.317,35.317,0,0,1,7.019,2.6l.727.406-.077.83c-.437,4.717-1.47,12.833-3.48,14.226-.937.427-17.515,7.46-23.425,7.463l-.177,0a43.523,43.523,0,0,1-8.549-1.07Zm-13.436-2.56h12.176l4.976-6.463.81.2a40.9,40.9,0,0,0,8.816,1.19c.047,0,.09,0,.137,0,4.45,0,17.479-5.133,22.168-7.133.55-.67,1.56-6.293,2.08-11.373a40.022,40.022,0,0,0-5.76-2.006c-5.11-1.5-10.4-3.057-11.476-8.116-1.41-6.623-14.4-10.2-21.065-11.362-.107.693-.223,1.343-.337,1.923l-2.513-.5c1.3-6.5.87-11.862-.273-13.109-.147-.156-.227-.156-.29-.14-1.753.193-8.746,6.366-14.386,12.116v35.658Z" transform="translate(-83.176 -4175.637)" />
                            </g>
                            <g id="Group_20" data-name="Group 20" transform="translate(495.85 3892.66)">
                                <g id="Group_18" data-name="Group 18" transform="translate(0 0)">
                                    <path id="Path_29" data-name="Path 29" className="cls-4" d="M3296.805,4233.155c1.152.368,2.121.62,2.784.78q.047,20.932.093,41.864l-6.061-4.382c-10.187,3.753-36.69,3.869-36.69,3.869-6.806-2.983-10.769-13.1-10.769-13.1l15.2-15.571c-.606-10.385,9.918-10.84,8.729-14.371-1.364-2.925-23.391-4.37-25.14-9.091,15.909.245,16.2-2.57,25.292-2.072a22.329,22.329,0,0,1,15.088,7.241" transform="translate(-3243.102 -4219.743)" />
                                    <path id="Path_30" data-name="Path 30" className="cls-3" d="M3288.718,4269.829l-7.553-5.463c-10.676,3.563-35.411,3.716-36.477,3.72l-.52-.106c-7.209-3.16-11.279-13.373-11.449-13.806l-.3-.77,15.392-15.769c-.157-6.653,3.966-9.522,6.723-11.436,1.357-.947,2.237-1.6,2.093-2.023-.6-.646-5.623-1.747-9.29-2.549-8.826-1.93-14.7-3.427-15.835-6.51l-.65-1.753,1.87.03a66.123,66.123,0,0,0,15.839-1.283,33.389,33.389,0,0,1,9.5-.784,23.894,23.894,0,0,1,15.946,7.633l-1.854,1.767a21.378,21.378,0,0,0-14.232-6.846,31.057,31.057,0,0,0-8.866.743,63.649,63.649,0,0,1-12.762,1.333c3,1.29,8.116,2.41,11.592,3.169,6.736,1.473,10.29,2.327,11.116,4.1.823,2.414-1.123,3.767-3.006,5.073-2.643,1.84-5.933,4.126-5.606,9.763l.033.563-15,15.365c1.026,2.283,4.456,9.146,9.529,11.533,2.846-.027,26.668-.356,35.974-3.787l.64-.236,4.576,3.31-.083-38.36c-.547-.147-1.187-.33-1.893-.553l.773-2.44c1.12.356,2.06.6,2.7.756l.977.237Z" transform="translate(-3230.852 -4211.263)" />
                                </g>
                                <g id="Group_19" data-name="Group 19" transform="translate(37.911 0.481)">
                                    <path id="Path_31" data-name="Path 31" className="cls-4" d="M3529.782,4240.269c0-10.07,10.806-15.906,13.3-15.593,3.153.4,3.479,14.1-3.654,18.344" transform="translate(-3528.502 -4223.388)" />
                                    <path id="Path_32" data-name="Path 32" className="cls-3" d="M3531.6,4235.664l-1.307-2.2c3.76-2.237,5.306-7.823,5.153-12.069-.1-2.636-.8-3.79-1.04-3.913-.543-.09-3.493.906-6.493,3.553-2.433,2.15-5.336,5.763-5.336,10.779h-2.56c0-4.66,2.2-9.166,6.2-12.7,2.963-2.617,6.6-4.39,8.543-4.167.82.1,2.3.74,2.953,3.867C3538.745,4223.745,3537.2,4232.337,3531.6,4235.664Z" transform="translate(-3520.016 -4214.933)" />
                                </g>
                            </g>
                            <path id="Path_33" data-name="Path 33" className={this.state.content.includes('Long') ? 'cls-green' : 'cls-5'} d="M1110.729,4102.432H1099.4l11.329,28.9,11.329-28.9Z" style={{ transform : `translate(${this.state.arrowX}px ,-224.04px)`}}  />
                            <rect id="Rectangle_10" data-name="Rectangle 10" className="cls-3" width="6.756" height="2.562" transform="translate(520.628 3917.395) rotate(-39.405)" />
                            <rect id="Rectangle_11" data-name="Rectangle 11" className="cls-3" width="2.557" height="7.939" transform="matrix(0.373, -0.928, 0.928, 0.373, 110.744, 3913.696)" />
                            <text id="Short_70_" data-name="Short 70%" className={this.renderClass()} style={{ transform : `translate(${this.state.textS}px ,3865.588px)`}}  >
                                <tspan x={0} y={0}>{this.state.content}</tspan>
                            </text>
                        </g>
                    </svg>

                </div>
            </div>
        );
    }
}

export default BUSDChart;