import axios from "axios";
import { cmc3_api_gateway } from "../config/config";
import { checkLogin, loading } from "../helpers/default";
import { error_handle } from '../helpers/error'
class ModelVn30 {

    constructor() {
        this.url = cmc3_api_gateway
        
    }
   

    filter(startTime , stopTime, aloading=false){
        if(aloading){
            loading(true);
        }
        
        return axios.request({
			url: cmc3_api_gateway + '/vn30/filter',
			method: 'POST',
			data: {
                startTime : startTime,
                stopTime : stopTime,
            },
            withCredentials: true,
		})
			.then(response => {
				loading(false, 'Loading...');
				response = response['data'];

				if(response['result']){
                   
                    return response['data'];
                }else{
                    error_handle(response);
                    return false;
                }
			})

			.catch((error) => {
				loading(false, 'Loading...');
				error_handle(error)
				return false;
			})
    }
    vn30_cs(startTime , stopTime, aloading=false){
        if(aloading){
            loading(true);
        }
        
        return axios.request({
			url: cmc3_api_gateway + '/vn30/vn30_cs_filter',
			method: 'POST',
			data: {
                startTime : startTime,
                stopTime : stopTime,
            },
            withCredentials: true,
		})
			.then(response => {
				loading(false, 'Loading...');
				response = response['data'];

				if(response['result']){
                   
                    return response['data'];
                }else{
                    error_handle(response);
                    return false;
                }
			})

			.catch((error) => {
				loading(false, 'Loading...');
				error_handle(error)
				return false;
			})
    }
    vn30_ps(startTime , stopTime, aloading=false){
        if(aloading){
            loading(true);
        }
        
        return axios.request({
			url: cmc3_api_gateway + '/vn30/vn30_ps_filter',
			method: 'POST',
			data: {
                startTime : startTime,
                stopTime : stopTime,
            },
            withCredentials: true,
		})
			.then(response => {
				loading(false, 'Loading...');
				response = response['data'];

				if(response['result']){
                   
                    return response['data'];
                }else{
                    error_handle(response);
                    return false;
                }
			})

			.catch((error) => {
				loading(false, 'Loading...');
				error_handle(error)
				return false;
			})
    }

    






}

export default ModelVn30;