import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import InputAce from '../Input/InputAce';
import moment  from 'moment'

import Testnet_backtest_pnlModel from '../../models/Testnet_backtest_pnlModel';
import { error_handle } from '../../helpers/error';
class TableTestnetWinrate extends Component {
    constructor(props) {
        super(props);
        this.state = {



            data: [],
            mapAccount: {},

            displayDialog: false,
            displayDialogTrade: false,
            dataTestnetResult : [],
            header : ''
        }

        this.model = new Testnet_backtest_pnlModel()

    }

    componentDidMount() {

    }
    setData(data, mapAccount) {
        this.setState({
            data,
            mapAccount
        });
    }

    representativeTemplate = (data, props) => {

        if (props.field == "index") {

            return (
                <span>{props.rowIndex + 1}</span>
            )
        }
        // if (props.field == "testnet_result_close_time") {

        //     return (
        //         <span>{moment(data[props.field] , 'x').format('DD-MM-YYYY HH:mm:ss')}</span>
        //     )
        // }


        let col = ['wprofit' , 'lprofit'  , 'pnl' ]
        let col1 = ['avg_w' , 'avg_l']

        if( props.field == 'minMaxDrawdown'){
            let result = (Number(data[props.field]) * 100 ).toFixed(2) 

            return (
                <span>{result}</span>
            )
        }

        if(props.field == 'winrate' ){
            let result = (Number(data[props.field]) * 100 ).toFixed(2) + '%'

            return (
                <span>{result}</span>
            )
        }


        if(col.includes(props.field)){
            let result = (Number(data[props.field])).toFixed(2) + '%'

            return (
                <span>{result}</span>
            )
        }
    
        if (col1.includes(props.field)) {
    
            let result = (Number(data[props.field])).toFixed(2)

            

            return (
                <span>{result}</span>
            )

        }
        if (props.field == "timeStart" ) {
            let result = moment(data[props.field] , 'x').format('YYYY_MM_DD')
            return (
                <span>{result}</span>
            )
        }
  

        if (props.field == 'acoount') {

            let result = ''

            if (Object.keys(this.state.mapAccount).length > 0) {

                result = this.state.mapAccount[data[props.field]]['name']
            }



            return (
                <span title={ this.state.mapAccount[data[props.field]]['note']}>{result}</span>
            )

        }

        if (props.field == "campaign") {

            return (
                <span>
                    <Button label="Info" onClick={() => this.showDialog('displayDialog' , true, data['acoount'])} />
                </span>
            )
        }
        if (props.field == "trade") {

            return (
                <span>
                    <Button label="Info" onClick={() => this.showDialog('displayDialogTrade', true, data['acoount'])} />
                </span>
            )
        }



    }

    showDialog(name, cmd = true , account_id='') {
        if (cmd == true) {

            if(name == 'displayDialog'){
                this.model.getTestnetStrategy({'account_id' : account_id}).then(res => {

                    if(res['result']){
                        this.strategyTestnet.setValue(res['data'])
                        this.strategyTestnet.setHeight('500px')
                    }
                })
               
            }else{
                this.model.getTestnetResult({'account_id' : account_id}).then(res => {
                
                    if(res['result']){
                      this.setState({
                        dataTestnetResult : res['data']
                      });
                    }
                })
            }

            this.setState({
                [name]: true,
                header : this.state.mapAccount[account_id]['name']
            });

           
        } else {
            this.setState({
                [name]: false
            });
        }

    }
    renderFooter(name) {
        return (
            <div>
                <Button label="Close" className="p-button-danger" onClick={() => this.showDialog([name], false)} />
            </div>
        );
    }




    render() {
        
        return (
            <div className='mt-2'>

                <div className="card">
                    <DataTable
                        className="p-datatable-customers"
                        value={this.state.data}
                        stripedRows
                        paginator responsiveLayout="scroll"
                        paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rows={10} rowsPerPageOptions={[10, 20, 50]}
                        sortField="acoount" sortOrder={-1}
                        header={<div>Testnet</div>}
                    >
                        <Column field="index" header="Index" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="acoount" header="Account" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                      
                        <Column field="timeStart" header="Start Time" sortable  body={(data, props) => this.representativeTemplate(data, props)}></Column>
                        <Column field="wcount" header="Win Trade" sortable ></Column>
                        <Column field="lcount" header="Loss Trade" sortable ></Column>
                        <Column field="total" header="Total Trade" sortable ></Column>
                        <Column field="wprofit" header="Win profit" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="lprofit" header="Loss Profit" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="pnl" header="Profit" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>

                        <Column field="winrate" header="Winrate" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="minMaxDrawdown" header="Max Drawdown" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="avg_w" header="Average Win" sortable  body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="avg_l" header="Average Loss" sortable body={(data, props) => this.representativeTemplate(data, props)}  ></Column>



                        <Column field="campaign" header="Strategy" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                        <Column field="trade" header="Trade" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>

                    </DataTable>

                    <Dialog header={this.state.header} visible={this.state.displayDialog} style={{ width: '80vw' }} footer={() => this.renderFooter('displayDialog')} onHide={() => { this.showDialog('displayDialog', false) }} >
                        <InputAce ref={c => this.strategyTestnet = c} ></InputAce>
                    </Dialog>
                    <Dialog header={this.state.header} visible={this.state.displayDialogTrade} style={{ width: '80vw' }} footer={() => this.renderFooter('displayDialogTrade')} onHide={() => { this.showDialog('displayDialogTrade', false) }} >
                        <div className="">
                            <DataTable
                                className="p-datatable-customers"
                                value={this.state.dataTestnetResult}
                                stripedRows
                                showGridlines 
                                paginator responsiveLayout="scroll"
                                paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rows={10} rowsPerPageOptions={[10, 20, 50]}
                            >
                                <Column field="index" header="Index" sortable body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                                <Column field="testnet_result_symbol" header="Symbol"   ></Column>
                                <Column field="testnet_result_close_time" header="Close Time" sortable  body={(data, props) => this.representativeTemplate(data, props)} ></Column>
                                <Column field="testnet_result_enter_price" header="Enter Price" sortable  ></Column>
                                <Column field="testnet_result_sell_price" header="Sell Price" sortable  ></Column>
                                <Column field="testnet_result_flow" header="Flow" sortable  ></Column>
                                <Column field="testnet_result_start_reason" header="Enter Reason" sortable  ></Column>
                                <Column field="testnet_result_params" header="Exit Reason" sortable  ></Column>
                 

                            </DataTable>

                        </div>

                    </Dialog>
                </div>
            </div>
        );
    }
}

export default TableTestnetWinrate;