import moment from 'moment';
import React, { Component } from 'react';
import ModelCtrl from '../../models/ModelCtrl';

class ChartPNL extends Component {

    constructor(props) {
        super(props);

    }


    getCtrl() {
        let model = new ModelCtrl()

        return model.get('testnet_backtest_mapping').then(res => {
            return res
        })
    }

    async setData(data, mapAccount) {


        let ctrl = await this.getCtrl()

        ctrl = JSON.parse(ctrl)




        let chartData = []
        data.map(item => {

            var randomColor = Math.floor(Math.random() * 16777215).toString(16);

            let backtest = {
                x: [],
                y: [],
                text: [],
                hovertemplate: `<br>%{text}<br>`,
                type: 'scatter',
                name: mapAccount[item['name']]['name'],
                mode: 'lines',
                legendgroup: 'group' + item['name'],
       
                line: {
                    color: randomColor,
                    dash: 'dot',
                }

            }

            item['backtest'].map(back => {
                let time = moment(back['timestamp'], 'x').format('YYYY_MM_DD')
                backtest['x'].push(time)
                backtest['y'].push(back['pnl_cum'])
                let text = `<b>${mapAccount[item['name']]['name']} </b> </br> <b>${time}</b>    </br> <b>${back['pnl_cum'].toFixed(2)} </b> `
                backtest['text'].push(text)
            })



            let testnet = {
                x: [],
                y: [],
                text: [],
                hovertemplate: `<br>%{text}<br>`,
                type: 'scatter',
                showlegend: false,
                mode: 'lines',
                legendgroup: 'group' + item['name'],
                line: {
                    color: randomColor,

                },
                // name: mapAccount[item['name']]['name'],
            }

            item['testnet'].map((back, index) => {

                let time = moment(back['timestamp'], 'x').format('YYYY_MM_DD')
                testnet['x'].push(time)
                testnet['y'].push(back['pnl_cum'])
                let text = `<b>${mapAccount[item['name']]['name']} </b> </br> <b>${time}</b>    </br> <b>${back['pnl_cum'].toFixed(2)} </b> `
                testnet['text'].push(text)

                if(index == 0){
                    backtest['x'].push(time)
                    backtest['y'].push(back['pnl_cum'])
                }
            })

            chartData.push(backtest)
            chartData.push(testnet)
        })




        let ticktext = []


        data[0]['backtest'].map((item, index) => {
            if (index % 25 == 0) {
                ticktext.push(moment(item['timestamp'], 'x').format('YYYY_MM_DD'))
            }
        })


        this.renderChart(chartData, ticktext)

    }


    funcCumSum(data) {
        const cumulativeSum = (sum => value => sum += value)(0);

        data = data.map(cumulativeSum)

        return data


    }


    checkValue(tranCtrl, value) {

        let result = ''
        Object.keys(tranCtrl).map(item => {
            if (tranCtrl[item] == value) {
                result = item
            }
        })

        return result
    }


    renderChart(data, ticktext) {


        var layout = {
            title: 'PNL Backtest Testnet',
            height: 800,
            showlegend: true,
            legend: {
                x: 0,
                y: 1,
            },
            margin: { b: 100, t: 40 },
            autosize: true,
            // hovermode: "x unified",
            hoverdistance: 10,
            barmode: 'group',
            font: {
                size: 12,
            },
            xaxis: {
                autorange: true,
                type: "category",
                categoryorder: 'category ascending',
                rangeslider: { 'visible': false },
                showspikes: true,
                spikemode: 'across',
                spikesnap: 'cursor',
                spikedash: 'dot',
                spikecolor: 'black',
                spikethickness: 2,

                tickangle: 'auto',
                showexponent: 'all',
                tickmode: "array",
                tickvals: ticktext,
                ticktext: ticktext,

            },
            yaxis: {
                autorange: true,
                showspikes: true,
                spikemode: 'across',
                spikesnap: 'cursor',
                spikedash: 'dot',
                spikecolor: 'black',
                spikethickness: 2,
            },
            // shapes: [
            //     {
            //         type: 'line',
            //         // x-reference is assigned to the x-values
            //         xref: 'x',
            //         // y-reference is assigned to the plot paper [0,1]
            //         yref: 'paper',
            //         x0: '2022_10_14',
            //         y0: 0,
            //         x1: '2022_10_14',
            //         y1: 1,
            //         line: {
            //             color: '#444444',
            //             // width: 3,
            //             dash: 'dot'
            //         }
            //     },


            // ]









        };

        global.Plotly.newPlot(this.chart, data, layout);
    }

    render() {
        return (
            <div ref={c => this.chart = c}>

            </div>
        );
    }
}

export default ChartPNL;