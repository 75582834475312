import React, { Component } from "react";
import { loading } from "../../helpers/default";
import ModelDashboardV2 from "../../models/ModelDashboardV2";
import { checkWorkingTime, showLog } from '../../helpers/default';
import moment from "moment";
class ChartTotalScoreView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lastTimestampSocre: 0,
            weight: {},
            rollingSecond: 0,
            ema: 0,
            sma: 0,
            smooth: '',
            indicator_correction: {}
        };

        this.model = new ModelDashboardV2()
    }

    findClosestTime(inputTime, data) {
      
        const inputMoment = moment(inputTime, "HH:mm:ss");
        if(data.length ==0) {
            return inputTime 
        }
        let closestTime = null;
        for (let i = 0; i < data.length; i++) {
            const currTime = moment(data[i], "HH:mm:ss");
            if (currTime.isAfter(inputMoment)) {
                closestTime = data[i];
                break;
            }
        }
        if (!closestTime) {
            const lastTime = data[data.length - 1];
            const lastMoment = moment(lastTime, "HH:mm:ss");
            if (inputMoment.isAfter(lastMoment)) {
                closestTime = inputTime;
            } else {
                closestTime = data.reduce((prev, curr) => {
                    const currMoment = moment(curr, "HH:mm:ss");
                    const prevMoment = moment(prev, "HH:mm:ss");
                    const currDiff = Math.abs(currMoment.diff(inputMoment));
                    const prevDiff = Math.abs(prevMoment.diff(inputMoment));
                    return currDiff < prevDiff ? curr : prev;
                });
            }
        }
        return closestTime;
    }


    renderChart(dataPs, data) {

        let dataScore = data['dataScore']
        let dataScoreEMA = data['dataScoreEMA']
        let dataScoreSMA = data['dataScoreSMA']
        let dataScoreMACD = data['dataScoreMACD']
        let timeArrayTran = ['09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '13:30', '14:00', '14:30', '15:00']
        let timeArray = []
        timeArrayTran.map((item, idx) => {
            let tran = item + ':00'
            if (dataScore['x'].indexOf(tran) > -1) {
                timeArray[idx] = tran
            } else {
                let a = this.findClosestTime(tran, dataScore['x'])
                timeArray[idx] = a
            }
        })
        var data = [dataPs, dataScore , dataScoreEMA , dataScoreSMA , dataScoreMACD];
        // for (var i = 0; i < this.state.dataScore.length - 1; i++) {

        //     if (i == 0) {
        //         let color = this.state.dataScore[i + 1]["y"] > 0 ? 'limegreen' : 'red'
        //         var trace = {
        //             x: [
        //                 this.state.dataScore[i]["x"],
        //                 this.state.dataScore[i + 1]["x"],
        //             ],
        //             y: [
        //                 this.state.dataScore[i]["y"],
        //                 this.state.dataScore[i + 1]["y"],
        //             ],
        //             type: "scatter",
        //             mode: "lines",
        //             line: {
        //                 color: color,
        //                 // width: 2
        //             },
        //             'name': 'score',
        //             xaxis: "x",
        //             yaxis: "y2",
        //             legendgroup: 'abc',
        //         };
        //     } else {
        //         let color = this.state.dataScore[i + 1]["y"] > 0 ? 'limegreen' : 'red'
        //         var trace = {
        //             x: [
        //                 this.state.dataScore[i]["x"],
        //                 this.state.dataScore[i + 1]["x"],
        //             ],
        //             y: [
        //                 this.state.dataScore[i]["y"],
        //                 this.state.dataScore[i + 1]["y"],
        //             ],
        //             type: "scatter",
        //             mode: "lines",
        //             line: {
        //                 color: color,
        //                 // width: 2
        //             },
        //             showlegend: false,
        //             xaxis: "x",
        //             yaxis: "y2",
        //             legendgroup: 'abc',
        //         };
        //     }

        //     data.push(trace);
        // }

        var layout = {
            height: window.screen.width >= 1920 ? '100%' : 400,
            showlegend: true,
            title: "<b> Total score across time </b>",
            autosize: true,
            margin: {
                pad: 10
            },
            hovermode: "x unified",
            hoverdistance: 10,
            barmode: "group",
            font: {
                size: 12,
            },
            xaxis: {
                autorange: true,
                type: "category",
                categoryorder: "category ascending",
                rangeslider: { visible: false },
                showspikes: true,
                spikemode: "across",
                spikesnap: "cursor",
                spikedash: "dot",
                // spikecolor: 'black',
                spikethickness: 2,
                tickmode: "array",
                tickvals: timeArray,
                ticktext: timeArrayTran,

                // showgrid: false,
                //   showticklabels: true,
                tickangle: "auto",
                showexponent: "all",
                // gridcolor: 'white',
            },
            yaxis: {
                autorange: true,
                showspikes: true,
                spikemode: "across",
                spikesnap: "cursor",
                spikedash: "dot",
                // spikecolor: 'black',
                spikethickness: 2,
                side: 'right',

                domain: [0.32, 1]

            },
            yaxis2: {
                overlaying: "y",
                side: "left",
                domain: [0.32, 1]
            },
            yaxis3: { domain: [0, 0.3] },
            legend: {
                x: 0,
                // xanchor: 'right',
                y: 1.1,
                "orientation": "h",
            },

            annotations: [
                {
                  x: 1.06, // tọa độ x của chú thích
                  y: dataPs['y'][dataPs['y'].length - 1], // tọa độ y của chú thích
                  xref: 'paper', // xác định tọa độ x sử dụng trong khoảng 0-1 của trục x
                  yref: 'y', // xác định tọa độ y sử dụng trục y
                  text: dataPs['y'][dataPs['y'].length - 1]? dataPs['y'][dataPs['y'].length - 1] : '', // nội dung chú thích
                  showarrow: false, // ẩn mũi tên chú thích
                  align: 'left', // căn lề của chú thích
                  bgcolor:  dataPs['y'][dataPs['y'].length - 1]? 'red' : 'white', // màu nền của chú thích
                  opacity: 0.8, // độ trong suốt của chú thích
                  ax: 0, // căn chỉnh vị trí x của chú thích
                  ay: 10, // căn chỉnh vị trí y của chú thích,
                  font: {
                    color: 'white',
                  }
                }
              ],

            grid: {
                rows: 2,
                columns: 1,
                subplots: [['xy'], ['xy3']],
                roworder: 'top to bottom',
              }

        };
        var config = { responsive: true };
        global.Plotly.newPlot(this.chart, data, layout,config);
    }
    async updateData() {

        if (checkWorkingTime()) {

            // if (moment(this.state.lastTimestamp, "X").format('YYYY_MM_DD') != moment().format('YYYY_MM_DD')) {
            //     return
            // }

            let paramter = {
                "day": this.state.day,
                "lastTimestamp": this.state.lastTimestampSocre,
                'weight': this.state.weight,
                rollingSecond: this.state.rollingSecond,
                ema : this.state.ema,
                sma : this.state.sma,
                smooth: this.state.smooth,
                indicator_correction: this.state.indicator_correction
            }

            let dataPs = await this.getDataPS(false, this.state.day)
            let data = await this.getDataScore(false, paramter)

            let dataScore = data['dataScore']
            let dataScoreEMA = data['dataScoreEMA']
            let dataScoreSMA = data['dataScoreSMA']
            let dataScoreMACD = data['dataScoreMACD']

            let layout = this.chart.layout
            layout['annotations'][0]['y'] = dataPs['y'][dataPs['y'].length - 1]
            layout['annotations'][0]['text'] =dataPs['y'][dataPs['y'].length - 1]

            let preData = this.chart.data
            preData[0] = dataPs

            preData[1]['x'].push(...dataScore['x'])
            preData[1]['y'].push(...dataScore['y'])

            preData[2]['x'].push(...dataScoreEMA['x'])
            preData[2]['y'].push(...dataScoreEMA['y'])

            preData[3]['x'].push(...dataScoreSMA['x'])
            preData[3]['y'].push(...dataScoreSMA['y'])

            preData[4]['x'].push(...dataScoreMACD['x'])
            preData[4]['y'].push(...dataScoreMACD['y'])
            preData[4]['marker']['color'].push(...dataScoreMACD['marker']['color'])


            this.chart.data = preData
            global.Plotly.redraw(this.chart)


        }


    }

    isTimeTrue(timeString) {

        const date = new Date();
        const hours = timeString.split(':')[0];
        const minutes = timeString.split(':')[1];
        const seconds = timeString.split(':')[2];
        date.setHours(hours, minutes, seconds);

        const lowerBound = new Date();
        lowerBound.setHours(11, 30, 0);
        const upperBound = new Date();
        upperBound.setHours(13, 0, 0);

        const upperHighBound = new Date();
        upperHighBound.setHours(14, 45, 0);


        if (date >= upperHighBound) {
            return true
        }

        return date >= lowerBound && date <= upperBound;



    }

    getDataPS(loading, day) {
        let params = {
            "day": day
        }
        return this.model.get_data_close_ps(loading, params).then(res => {


            let dataPs = {
                'x': [],
                'y': [],
                'name': 'Phai sinh',
                line: {
                    color: 'red',
                }
            }

            if (res != null) {
                res = JSON.parse(res)
                res['time'].map((item, idx) => {

                    if (!this.isTimeTrue(item)) {
                        dataPs['x'].push(item)
                        dataPs['y'].push(res['price'][idx])
                    }

                })
            }


            return dataPs

        })
    }

    getDataScore(loading, params) {


        return this.model.get_data_score(loading, params).then(res => {

            let dataScore = {
                'x': [],
                'y': [],
                'name': 'Score',
                xaxis: "x",
                yaxis: "y2",
                line : {
                    'color' : 'limegreen',
                },
            }

            let dataScoreEMA = {
                'x': [],
                'y': [],
                'name': 'Score EMA',
                xaxis: "x",
                yaxis: "y2",
                line : {
                    'color' : '#856dfc'
                }
            }
            let dataScoreSMA = {
                'x': [],
                'y': [],
                'name': 'Score SMA',
                xaxis: "x",
                yaxis: "y2",
                line : {
                    'color' : '#ffa758'
                }
            }
            let dataScoreMACD = {
                'x': [],
                'y': [],
                'name': 'Score MACD',
                type: 'bar',
                xaxis: "x",
                yaxis: "y3",
                marker: { color: [] }
            }

            if (res) {

                res.map((item, idx) => {

                    dataScore['x'].push(item['time'])
                    dataScore['y'].push(item['score_round'])

                    dataScoreEMA['x'].push(item['time'])
                    dataScoreEMA['y'].push(item['score_round_ema'])


                    dataScoreSMA['x'].push(item['time'])
                    dataScoreSMA['y'].push(item['score_round_sma'])

                    dataScoreMACD['x'].push(item['time'])
                    dataScoreMACD['y'].push(item['score_round_MACD'])
                    dataScoreMACD['marker']['color'].push(item['score_round_colors'] ? item['score_round_colors'] : 'red')


                    if (idx == res.length - 1) {
                        let a = this.props.TotalScoreView()
                        a.setData(item['score_round'])
                        this.setState({
                            lastTimestampSocre: item['timestamp']
                        });
                    }

                })
            }

            return {dataScore ,dataScoreEMA , dataScoreSMA , dataScoreMACD}

        })
    }

    async componentDidMount() {

        if (this.props.day != '') {

            let dataPs = await this.getDataPS(true, this.props.day)
            let weight = JSON.parse(this.props.weight['weight'])
            let params = {
                'day': this.props.day,
                'lastTimestamp': moment(this.props.day, 'YYYY_MM_DD').startOf('day').add(9, 'hours').valueOf() / 1000,
                'weight': weight,
                rollingSecond: this.props.rolling_second,
                ema : this.props.ema,
                sma : this.props.sma,
                smooth: this.props.smooth,
                "indicator_correction": this.props.indicator_correction
            }
            let dataScore = await this.getDataScore(true, params)

            this.renderChart(dataPs, dataScore)
            this.setState({
                day: this.props.day,
                weight,
                rollingSecond: this.props.rolling_second,
                ema : this.props.ema,
                sma : this.props.sma,
                smooth: this.props.smooth,
                indicator_correction: this.props.indicator_correction

            });


            if (checkWorkingTime()) {
                this.interval = setInterval(() => {
                    this.updateData()
                }, 5000);
            }


        }






    }

    applyWeight(weight) {
        this.setState({
            lastTimestampSocre: moment(this.state.day, 'YYYY_MM_DD').startOf('day').add(9, 'hours').valueOf() / 1000,
            weight: JSON.parse(weight)
        }, async () => {
            let dataPs = await this.getDataPS(true, this.state.day)

            let params = {
                'day': this.state.day,
                'lastTimestamp': this.state.lastTimestampSocre,
                'weight': this.state.weight,
                rollingSecond: this.state.rollingSecond,
                ema : this.state.ema,
                sma : this.state.sma,
                smooth: this.state.smooth,
                "indicator_correction": this.state.indicator_correction
            }
            let dataScore = await this.getDataScore(true, params)

            this.renderChart(dataPs, dataScore)

        });



    }
    changeIndicator_correction(data) {
        let indicator_correction = { ...this.state.indicator_correction, ...data }

        this.setState({
            lastTimestampSocre: moment(this.state.day, 'YYYY_MM_DD').startOf('day').add(9, 'hours').valueOf() / 1000,
            indicator_correction
        }, async () => {
            let dataPs = await this.getDataPS(true, this.state.day)

            let params = {
                'day': this.state.day,
                'lastTimestamp': this.state.lastTimestampSocre,
                'weight': this.state.weight,
                rollingSecond: this.state.rollingSecond,
                ema : this.state.ema,
                sma : this.state.sma,
                smooth: this.state.smooth,
                "indicator_correction": this.state.indicator_correction
            }
            let dataScore = await this.getDataScore(true, params)

            this.renderChart(dataPs, dataScore)

        });
    }

    componentWillUnmount() {
        if (this.interval) clearInterval(this.interval);
    }

    render() {
        return (
            <div>
                <div ref={(c) => (this.chart = c)}></div>
            </div>
        );
    }
}

export default ChartTotalScoreView;
