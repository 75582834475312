import React, { Component } from 'react';

import TableFAScore from '../components/FAScore/TableFAScore';

class FAScoringView extends Component {

    

    render() {
        return(
            <TableFAScore></TableFAScore>

        )
         
    }
}

export default FAScoringView;